import { useCallback, useMemo, useState } from 'react';
import { Box, HStack, List, ListItem, useTheme } from '@chakra-ui/react';
import DayPicker from 'react-day-picker';
import { sub } from 'date-fns';
import { rgba } from 'polished';

import { Button } from 'components/Button';
import { Popover } from 'components/Popover';

import {
  DATE_OPTIONS,
  LABELS,
  MONTHS,
  WEEKDAYS_LONG,
  WEEKDAYS_SHORT,
  isSelectingFirstDay,
} from './helpers';

export type Props = {
  initialFrom?: Date;
  initialTo?: Date;
  onChange?: (from?: Date, to?: Date) => void;
  onClose?: (from?: Date, to?: Date) => void;
  popoverTitle?: string;
};

export const DatePicker = ({
  initialFrom,
  initialTo,
  onChange,
  onClose,
  popoverTitle = LABELS.selectDate,
}: Props) => {
  const { colors } = useTheme();
  const [from, setFrom] = useState<Date | undefined>(initialFrom); // Date range "from"
  const [to, setTo] = useState<Date | undefined>(initialTo); // Date range "to"
  const [enteredTo, setEnteredTo] = useState<Date | undefined>(initialTo); // Current selection / hover

  const setDates = useCallback(
    (from?: Date, to?: Date) => {
      setFrom(from);
      setTo(to);
      setEnteredTo(to);
      onChange?.(from, to);
    },
    [onChange],
  );

  const handleDayClick = useCallback(
    (day: Date) => {
      console.log(day);
      // Reset dates
      if (from && to && day >= from && day <= to) {
        return setDates();
      }

      // Selecting the first day of the range
      if (isSelectingFirstDay(day, from, to)) {
        setFrom(day);
        setTo(undefined);
        setEnteredTo(undefined);
        return;
      }

      setTo(day);
      setEnteredTo(day);
      onChange?.(from, day);
    },
    [from, to, setDates, onChange],
  );

  const handleDayMouseEnter = useCallback(
    (day: Date) => {
      if (!isSelectingFirstDay(day, from, to)) {
        setEnteredTo(day);
      }
    },
    [from, to],
  );

  const onSelect1Months = useCallback(() => {
    const today = new Date();
    setDates(sub(today, { months: 1 }), today);
  }, [setDates]);

  const onSelect2Months = useCallback(() => {
    const today = new Date();
    setDates(sub(today, { months: 2 }), today);
  }, [setDates]);

  const onSelect6Months = useCallback(() => {
    const today = new Date();
    setDates(sub(today, { months: 6 }), today);
  }, [setDates]);

  const onSelectThisYear = useCallback(() => {
    const today = new Date();
    setDates(new Date(today.getFullYear(), 0, 1), today);
  }, [setDates]);

  const onSelectLastYear = useCallback(() => {
    const today = new Date();
    setDates(
      new Date(today.getFullYear() - 1, 0, 1, 0, 0, 0),
      new Date(today.getFullYear() - 1, 11, 31, 23, 59, 59),
    );
  }, [setDates]);

  const onSelectAll = useCallback(() => {
    setDates(undefined, undefined);
  }, [setDates]);

  const datesButtons = useMemo(
    () => [
      { id: 'all', label: LABELS.all, onClick: onSelectAll },
      { id: '1-months', label: LABELS.lastOneMonths, onClick: onSelect1Months },
      { id: '2-months', label: LABELS.lastTwoMonths, onClick: onSelect2Months },
      { id: '6-months', label: LABELS.lastSixMonths, onClick: onSelect6Months },
      { id: 'this-year', label: LABELS.thisYear, onClick: onSelectThisYear },
      { id: 'last-year', label: LABELS.lastYear, onClick: onSelectLastYear },
    ],
    [onSelect2Months, onSelect6Months, onSelectThisYear, onSelectLastYear],
  );

  const title = useMemo(() => {
    const displayedFrom = from ? `${from.toLocaleString('fr-FR', DATE_OPTIONS)}` : '';
    const displayedTo = to ? ` - ${to.toLocaleString('fr-FR', DATE_OPTIONS)}` : '';
    return from ? `${displayedFrom}${displayedTo}` : popoverTitle;
  }, [from, to, popoverTitle]);

  return (
    <Popover
      arrowProps={{ backgroundColor: 'teal.900' }}
      title={title}
      onClose={() => onClose?.(from, to)}
    >
      <HStack
        backgroundColor="teal.900"
        borderRadius="base"
        color="white"
        display={{ base: 'inline-block', lg: 'flex' }}
        maxW={500}
        pb={5}
        pt={6}
        px={8}
        spacing={8}
      >
        <Box as="section" position="relative" pt={3}>
          <DayPicker
            firstDayOfWeek={1}
            //fromMonth={from}
            labels={LABELS}
            locale="fr"
            modifiers={{ start: from, end: enteredTo }}
            months={MONTHS}
            onDayClick={handleDayClick}
            onDayMouseEnter={handleDayMouseEnter}
            selectedDays={[from, { from, to: enteredTo }]}
            renderDay={(day: Date) => (
              <span className="DayPicker-Day__content">{day.getDate()}</span>
            )}
            showOutsideDays
            weekdaysLong={WEEKDAYS_LONG}
            weekdaysShort={WEEKDAYS_SHORT}
          />
        </Box>
        <Box
          as="aside"
          alignSelf="stretch"
          borderLeftColor={rgba(colors.white, 0.4)}
          borderLeftWidth={1}
          display={{ base: 'none', lg: 'flex' }}
          pl={8}
        >
          <List alignSelf="center" textAlign="right">
            {datesButtons.map((date) => (
              <ListItem key={date.id} lineHeight={1} _notLast={{ mb: 6 }}>
                <Button
                  color="white"
                  fontSize="sm"
                  fontWeight="semibold"
                  onClick={date.onClick}
                  variant="link"
                >
                  {date.label}
                </Button>
              </ListItem>
            ))}
          </List>
        </Box>
      </HStack>
    </Popover>
  );
};

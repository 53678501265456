import { Center, Text, Spinner } from '@chakra-ui/react';

export const OverlayLoading = () => {
  return (
    <>
      <Center w={'100%'} height={'100%'} m={0} p={0}>
        <Center pb={7}>
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
          <Text ml={5}> Chargement de votre interface ...</Text>
        </Center>
      </Center>
    </>
  );
};

import { createIcon } from '@chakra-ui/react';

export const BinIcon = createIcon({
  displayName: 'BinIcon',
  viewBox: '0 0 16 17',
  path: (
    <path
      fill="currentColor"
      d="M15 4a.5.5 0 000-1h-4V1a.5.5 0 00-.5-.5h-5A.5.5 0 005 1v2H1a.5.5 0 000 1h14zM6 1.5h4V3H6V1.5zM2 5v11a.5.5 0 00.5.5h11a.5.5 0 00.5-.5V5H2zm4 9H5V7.5h1V14zm2.5 0h-1V7.5h1V14zm2.5 0h-1V7.5h1V14z"
    />
  ),
});

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Center,
  RadioGroup,
  Stack,
  TextProps,
  forwardRef,
  Radio,
  Text,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import { Button, ColorScheme } from 'components/Button';
import { AuthContext } from 'auth/AuthContextProvider';
import { useContext } from 'react';
import { Input } from 'components/Input';
import { useState } from 'react';

export type Props = {
  titre: string;
  description: string;
  avertissement: string;
  textButtonValider: string;
  minimumValue?: number;
  onValidate: (value: any) => void;
  colorButtonValider: string;
  idDossier: number;
  type: string;
} & Omit<ModalProps, 'children'>;

export const ConfirmationModalWithInput = ({
  titre,
  description,
  avertissement,
  textButtonValider,
  minimumValue,
  onValidate,
  idDossier,
  type,
  colorButtonValider = 'teal',
  ...props
}: Props) => {
  const { token } = useContext(AuthContext);
  const [value, setValue] = useState(0);
  const [projet_contrat_signe, setProjetContratSigne] = useState('');

  const ValueCorrect =
    !Number.isNaN(value) &&
    (minimumValue ? value >= minimumValue : true) &&
    projet_contrat_signe !== '';

  const closeModal = () => {
    setProjetContratSigne('');
    props.onClose();
  };

  const setValueMaturite = (e: any) => {
    setProjetContratSigne(e);
    fetch('/API/dossiers/enr/' + idDossier, {
      method: 'POST',
      body: JSON.stringify({ contrat_signe: e }),
      headers: new Headers({
        Authorization: token,
        'Content-Type': 'application/json',
      }),
    }).then((res) => res.json());
  };

  return (
    <Modal {...props} scrollBehavior="inside" size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center>
            <Text my={2} color="teal.900" fontSize="xl" fontWeight="extrabold">
              {titre}
            </Text>
          </Center>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign="center" pb={10}>
          <Box>
            <FormatText text={description}></FormatText>
          </Box>
          <Box mt={3}>
            <Input onChange={(e) => setValue(parseInt(e.currentTarget.value))} info={'€'} />
          </Box>
          <Box mt={7}>
            {type == 'LOGEMENT_ANCIEN' && 'Offre ou compromis de vente signé'}
            {type == 'LOGEMENT_NEUF' && 'Contrat de réservation signé'}
            {type !== 'LOGEMENT_ANCIEN' && type !== 'LOGEMENT_NEUF' && 'Contrat ou devis signé'}
            <RadioGroup onChange={setValueMaturite} value={projet_contrat_signe}>
              <Center direction="row">
                <Radio value="true">Oui</Radio>
                <Radio ml={5} value="false">
                  Non
                </Radio>
              </Center>
            </RadioGroup>
          </Box>
          <Box fontWeight="bold" color="red.500" mt={7}>
            <FormatText text={avertissement}></FormatText>
          </Box>
          <Flex mt={7}>
            <Box>
              <Button float={'left'} onClick={closeModal}>
                Fermer
              </Button>
            </Box>
            <Spacer />
            <Box>
              <Button
                disabled={!ValueCorrect}
                colorScheme={colorButtonValider as ColorScheme}
                onClick={() => onValidate(value)}
              >
                {textButtonValider}
              </Button>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

function FormatText(props: { text: string }): JSX.Element {
  const textToFormat = props.text;
  return (
    <>
      {textToFormat.split('\\n').map((str, i) => (
        <p key={i}>{str}</p>
      ))}
    </>
  );
}

import { Layout } from 'components/Layout';
import { Courtier, Utilisateur } from 'types';
import { Flex, Center, Text, Image } from '@chakra-ui/react';
import { Panel } from 'components/Panel';
import { Button } from 'components/Button';
import { useHistory } from 'react-router-dom';

export type Props = {
  courtier: Courtier;
  utilisateur: Utilisateur;
  hasStickyFooter?: boolean;
  sidebar?: React.ReactNode;
};

export const ErrorPage = ({ courtier, utilisateur, hasStickyFooter, sidebar, ...props }: Props) => {
  const history = useHistory();

  return (
    <Layout courtier={courtier} utilisateur={utilisateur} sidebar={sidebar} {...props}>
      <Flex as="section" flexDirection={{ base: 'column', lg: 'row' }} {...props}>
        <Panel
          w={{ base: '80%', lg: '50%' }}
          mx={{ base: '10%', lg: '25%' }}
          my={{ base: 6, lg: 0 }}
          px={{ base: 6, xxl: 10 }}
          py={{ base: 6, xxl: 8 }}
        >
          <Center>
            <Text as="h3" fontSize={{ base: 'xl', xxl: '2xl' }} fontWeight="semibold" mb={6}>
              Erreur de chargement des données
            </Text>
          </Center>
          <Center>
            <Image
              boxSize="75px"
              objectFit="cover"
              src="/file_missing.svg"
              alt="information non trouvée"
            />
          </Center>
          <Center>
            <Text
              as="h2"
              fontSize={{ base: 'md', xxl: 'xl' }}
              color={'blackAlpha.500'}
              fontWeight="semibold"
              mt={6}
            >
              La ressource que vous souhaiter afficher n'est pas disponible pour le moment.
              <br /> Il est possible qu'elle n'existe plus ou que vous n'ayez pas le droit d'y
              accéder.
            </Text>
          </Center>

          <Center>
            <Button mt={6} onClick={() => history.push('/')}>
              Retour au menu
            </Button>
          </Center>
        </Panel>
      </Flex>
    </Layout>
  );
};

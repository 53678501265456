import { useState } from 'react';
import { Text, Stack, Box, Center } from '@chakra-ui/react';
import { Button } from 'components/Button';
import { SelectFieldNumber } from 'utils/form';
import { DatePicker } from 'components/DatePicker';
import { FormikProvider } from 'formik';

import { Utilisateur } from 'types';

import { PerimetreFiltter } from './PerimetreFilter';

export type FilterDefinition = {
  startDate?: string;
  endDate?: string;
  direction?: number;
  agence?: number;
};

export const GenerateFilterQuery = (filter: FilterDefinition | undefined): string => {
  let query = '?';

  if (filter) {
    if (filter.startDate) {
      query += '&startDate=' + filter.startDate;
    }
    if (filter.endDate) {
      query += '&endDate=' + filter.endDate;
    }
    if (filter.direction) {
      query += '&direction=' + filter.direction;
    }
    if (filter.agence) {
      query += '&agence=' + filter.agence;
    }
  }
  return query;
};

export type Props = {
  action: (x: FilterDefinition) => void;
  utilisateur: Utilisateur;
};

const INITIAL_VALUES = {
  direction: null,
  agence: null,
};

export type Values = typeof INITIAL_VALUES;

export const StatFilter = ({ action, utilisateur }: Props) => {
  const [sdate, setSDate] = useState<string | undefined>(undefined);
  const [edate, setEDate] = useState<string | undefined>(undefined);
  const [entiteParentIdCible, setEntiteParentIdCible] = useState<string>('-1');
  const [entiteEnfantIdCible, setEntiteEnfantIdCible] = useState<string>('-1');

  const handleCalendarChange = (from?: Date, to?: Date) => {
    if (from) {
      setSDate(from!.getFullYear() + '-' + (from!.getMonth() + 1) + '-' + from!.getDate());
    } else {
      setSDate(undefined);
    }

    if (to) {
      setEDate(to!.getFullYear() + '-' + (to!.getMonth() + 1) + '-' + to!.getDate());
    } else {
      setEDate(undefined);
    }
  };

  return (
    <>
      <Stack spacing={8} direction={{ base: 'column', md: 'row' }}>
        <Box
          p={5}
          w={{ base: '100%', md: '30%' }}
          shadow="md"
          borderWidth="1px"
          borderRadius={'20px'}
          borderColor={'#31A0A1'}
        >
          <Text color={'teal'}>
            Date
            <span>
              <DatePicker onChange={handleCalendarChange} onClose={handleCalendarChange} />
            </span>
          </Text>
        </Box>
        <PerimetreFiltter
          onChangeParent={setEntiteParentIdCible}
          onChangeEnfant={setEntiteEnfantIdCible}
          utilisateur={utilisateur}
        />

        <Box p={5} w={{ base: '100%', md: '30%' }}>
          <Center>
            <Button
              onClick={(e) => {
                const filter: FilterDefinition = {
                  startDate: sdate,
                  endDate: edate,
                  direction: entiteParentIdCible === '-1' ? undefined : Number(entiteParentIdCible),
                  agence: entiteEnfantIdCible === '-1' ? undefined : Number(entiteEnfantIdCible),
                };
                action(filter);
              }}
            >
              Appliquer
            </Button>
          </Center>
        </Box>
      </Stack>
    </>
  );
};

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

export type Props = {
  defaultIndex?: number;
  onChange?: (index: number) => void;
  tabs: {
    title: string;
    content: React.ReactNode;
    isDisabled?: boolean;
  }[];
  variant?: 'enclosed-colored' | 'line';
};

export const TabsPanel = ({
  defaultIndex,
  onChange,
  tabs,
  variant = 'enclosed-colored',
}: Props) => {
  return (
    <Tabs colorScheme="teal" defaultIndex={defaultIndex} onChange={onChange} variant={variant}>
      <TabList>
        {tabs.map((tab, i) => (
          <Tab key={i} isDisabled={tab.isDisabled}>
            {tab.title}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map((tab, j) => (
          <TabPanel key={j}>{tab.content}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

import { createIcon } from '@chakra-ui/react';

export const OpenFolderIcon = createIcon({
  displayName: 'OpenFolderIcon',
  viewBox: '0 0 33 30',
  path: (
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M24.5 8V5h-12l-3-4h-8v28m0 0h24l6-18h-24l-6 18z"
    />
  ),
});

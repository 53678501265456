import { useContext, useEffect, useRef, useState } from 'react';

import { MeContext } from 'auth/AuthContextProvider';
import { HomeSidebar } from 'components/HomeSidebar';
import { Layout } from 'components/Layout';

import {
  Center,
  Text,
  Stack,
  VStack,
  RadioGroup,
  Radio,
  Spinner,
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Box,
  InputGroup,
  InputRightAddon,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { Input } from 'components/Input';
import { Panel } from 'components/Panel';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { CitySearch } from 'components/CitySearch';
import { CustomLayoutBouygue } from 'components/CustomLayoutBouygue';

type taux = {
  duree: number;
  interet: number;
  assurance: number;
};
export const CapacitePage = () => {
  const [counter, setCounter] = useState<number>(0);
  const counterRef = useRef<number>(0);

  const [resultatMeteo, setResultatMeteo] = useState<taux[] | undefined>(undefined);

  const [usage, setUsage] = useState<string>('RES_PRINCIPALE');
  const [typeLogement, setTypeLogement] = useState<string>('LOGEMENT_NEUF');
  const [logementNeufSousType, setLogementNeufSousType] = useState<string>('COLLECTIF');
  const [logementAncienSousType, setLogementAncienSousType] = useState<string>('TRAVAUX');

  const [apport, setApport] = useState<string>('20000');
  const [mensualite, setMensualite] = useState<string>('600');
  const [loyer, setLoyer] = useState<string>('400');
  const [duree, setDuree] = useState<number>(25);
  const [tx, setTx] = useState<string>('3.77');

  const [primoAccedant, setPrimoAccedant] = useState<number>(0);
  const [zone, setZone] = useState<string>('');
  const [cc, setCC] = useState<string>('');
  const [cp, setCP] = useState<string>('');
  const [resetZone, SetResetZone] = useState<number>(0);
  const [nbOccupant, setNbOccupant] = useState<number>(2);
  const [rfr, setRfr] = useState<string>('20000');

  const [resultLoading, setResultLoading] = useState<boolean>(false);
  const [resultat, setResultat] = useState<any>(undefined);

  async function Callcalculatrice() {
    let immeuble = '';
    let type_log = '';

    if (primoAccedant && cc === '' && cp === '') {
      setResultat(undefined);
      setResultLoading(false);
      return;
    }
    if (typeLogement === 'LOGEMENT_NEUF') {
      immeuble = 'LOGEMENT_NEUF';
      if (logementNeufSousType === 'INDIVIDUEL') {
        type_log = 'INDIVIDUEL';
      }
      if (logementNeufSousType === 'COLLECTIF') {
        type_log = 'COLLECTIF';
      }
      if (logementNeufSousType === 'PSLA') {
        type_log = 'PSLA';
      }
    }
    if (typeLogement === 'LOGEMENT_ANCIEN') {
      if (logementAncienSousType === 'NOTRAVAUX') {
        immeuble = 'LOGEMENT_ANCIEN_NON_PTZ';
      }
      if (logementAncienSousType === 'TRAVAUX') {
        immeuble = 'LOGEMENT_ANCIEN_PTZ';
      }
      if (logementAncienSousType === 'SOCIAL') {
        immeuble = 'RACHAT_LOGEMENT_SOCIAL';
      }
    }

    //await fetch('https://www.monemprunt.com/app/me2/simulateur/calcul', {
    await fetch('/app/me2/simulateur/calcul', {
      method: 'POST',
      headers: new Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify({
        type_calcul: 'CALCUL_CAPACITE',
        usage: usage,
        immeuble: immeuble,
        type_log: type_log,
        mensualite_totale:
          usage === 'LOCATIF'
            ? Math.round(Number(mensualite) + Number(loyer) * 0.7)
            : Number(mensualite),
        revenu_mensuel: Number(mensualite) * 3,
        primo_accedant: primoAccedant,
        loyer: loyer,
        apport: apport,
        duree_pp: duree * 12,
        taux_pp: tx,
        nb_occupant: nbOccupant,
        revenu_fiscal: rfr,
        cp: cp,
        cc: cc,
        calc_autres_durees: 1,
      }),
    })
      .then((res) =>
        res.json().then((result) => {
          console.log(result);
          if (res.status === 200) {
            setResultat(result);
          } else {
            setResultat(undefined);
          }
        }),
      )
      .catch((err) => console.log(err))
      .finally(() => setResultLoading(false));
  }

  async function launcherCallCalculatrice(actualCounter: number) {
    await new Promise((f) => setTimeout(f, 400));
    if (counterRef.current === actualCounter) Callcalculatrice();
  }

  useEffect(() => {
    if (resultatMeteo) {
      setResultLoading(true);
      setResultat(undefined);
      launcherCallCalculatrice(counter);
      counterRef.current = counter;
      setCounter(counter + 1);
    }
  }, [
    usage,
    typeLogement,
    logementNeufSousType,
    logementAncienSousType,
    apport,
    mensualite,
    loyer,
    primoAccedant,
    zone,
    nbOccupant,
    rfr,
    duree,
    tx,
    resultatMeteo,
  ]);

  async function getMeteo() {
    await fetch('https://www.monemprunt.com/api/calculette/taux', {
      method: 'GET',
    })
      .then((res) =>
        res.json().then((result) => {
          setResultatMeteo(result);
          setTx(result[duree - 1].interet / 100 + '');
        }),
      )
      .catch((err) => setResultatMeteo(undefined));
  }

  useEffect(() => {
    getMeteo();
  }, []);

  if (resultatMeteo === undefined) {
    return (
      <>
        <Center width={'100%'}>
          <Text pl={'20px'} width={'100%'} as="h3" fontSize={'25px'} fontWeight="semibold" my={4}>
            Calculatrice de capacité d'achat
          </Text>
        </Center>
        <Box mt={'50px'}>
          <Center>
            <Spinner size="xl" mb="5px" color="teal" thickness="4px" speed="0.65s"></Spinner>
          </Center>
          <Center>
            <Text mt={'10px'}>Chargement de la météo des taux</Text>
          </Center>
        </Box>
      </>
    );
  }
  return (
    <>
      <Center width={'100%'}>
        <Text pl={'20px'} width={'100%'} as="h3" fontSize={'25px'} fontWeight="semibold" my={4}>
          Calculatrice de capacité d'achat
        </Text>
      </Center>

      <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: 8, lg: 8 }}>
        <VStack
          as="fieldset"
          align={'left'}
          flex={1}
          spacing={4}
          width={{ base: '100%', lg: '50%' }}
        >
          <Panel p={'30px'} w="100%">
            <Text mt={'10px'} mb={'5px'}>
              Type de résidence
            </Text>
            <RadioGroup value={usage}>
              <Stack spacing={5} direction="row">
                <Radio
                  colorScheme="teal"
                  value={'RES_PRINCIPALE'}
                  onClick={() => setUsage('RES_PRINCIPALE')}
                >
                  Principale
                </Radio>
                <Radio
                  colorScheme="teal"
                  value={'RES_SECONDAIRE'}
                  onClick={() => setUsage('RES_SECONDAIRE')}
                >
                  Secondaire
                </Radio>
                <Radio colorScheme="teal" value={'LOCATIF'} onClick={() => setUsage('LOCATIF')}>
                  Locative
                </Radio>
              </Stack>
            </RadioGroup>
          </Panel>
          <Panel p={'30px'} w="100%">
            <Text as="b">Information du bien </Text>
            <Text mt={'10px'} mb={'5px'}>
              Projet{' '}
            </Text>
            <RadioGroup value={typeLogement}>
              <Stack spacing={5} direction="row">
                <Radio
                  colorScheme="teal"
                  value={'LOGEMENT_NEUF'}
                  onClick={() => setTypeLogement('LOGEMENT_NEUF')}
                >
                  Neuf
                </Radio>
                <Radio
                  colorScheme="teal"
                  value={'LOGEMENT_ANCIEN'}
                  onClick={() => setTypeLogement('LOGEMENT_ANCIEN')}
                >
                  Ancien
                </Radio>
              </Stack>
            </RadioGroup>
          </Panel>
          <Panel p={'30px'} w="100%">
            <Text as="b">Information de financement </Text>
            <Text mt={'10px'} mb={'5px'}>
              Mensualité Souhaité
            </Text>
            <InputGroup>
              <Input
                value={mensualite}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, '');
                  setMensualite(value);
                }}
              ></Input>{' '}
              <InputRightAddon h={'45px'}>Euros</InputRightAddon>
            </InputGroup>
            {usage !== 'LOCATIF' && (
              <>
                <Text fontSize={'12px'}>
                  La mensualité ne doit pas exedéder 35% du revenu mensuel.<br></br>
                  Le revenu annuel minimum est de
                  <b> {Math.round(((Number(mensualite) * 100) / 35) * 12)}</b> euros (soit{' '}
                  <i>{Math.round((Number(mensualite) * 100) / 35)}</i> euros mensuel) <br></br>
                </Text>
              </>
            )}
            {usage === 'LOCATIF' && (
              <>
                <Text mt={'10px'} mb={'5px'}>
                  Loyer estimé
                </Text>
                <Input
                  value={loyer}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, '');
                    setLoyer(value);
                  }}
                ></Input>
                <Text fontSize={'12px'}>
                  La mensualité total prise en compte est l'addition de la mensualité possible et
                  70% du futur loyer soit{' '}
                  <b>{Math.round(Number(loyer) * 0.7 + Number(mensualite))}</b> euros.
                </Text>
              </>
            )}
            <Text mt={'10px'} mb={'5px'}>
              Apport personnel
            </Text>
            <InputGroup>
              <Input
                value={apport}
                onChange={(e) => setApport(e.target.value.replace(/\D/g, ''))}
              ></Input>
              <InputRightAddon h={'45px'}>Euros</InputRightAddon>
            </InputGroup>
            <Text mb={'5px'} mt={'20px'}>
              Durée souhaitée : <b>{duree}</b> ans
            </Text>{' '}
            <Slider
              aria-label="slider-ex-6"
              min={10}
              max={25}
              value={duree}
              onChange={(val) => {
                setDuree(val);
                setTx(resultatMeteo[val - 1].interet / 100 + '');
              }}
            >
              <SliderMark ml={'-4px'} pt={'5px'} value={10}>
                10
              </SliderMark>
              <SliderMark ml={'-4px'} pt={'5px'} value={15}>
                15
              </SliderMark>
              <SliderMark ml={'-4px'} pt={'5px'} value={20}>
                20
              </SliderMark>
              <SliderMark ml={'-4px'} pt={'5px'} value={25}>
                25
              </SliderMark>
              <SliderTrack bg={'lightgray'}>
                <SliderFilledTrack bg={'teal'} />
              </SliderTrack>
              <SliderThumb bg={'teal'}></SliderThumb>{' '}
            </Slider>
            <Text mt={'20px'} mb={'5px'}>
              Taux emprunt
            </Text>
            <InputGroup>
              <Input
                value={tx}
                onChange={(e) => {
                  let value = e.target.value;
                  value = value.replace(/[^0-9.]+/g, '');
                  const split = value.split('.');
                  if (split.length > 2) {
                    value = split[0] + '.' + split[1];
                  }
                  setTx(value.replace(/[^0-9.]+/g, ''));
                }}
              ></Input>
              <InputRightAddon h={'45px'}>%</InputRightAddon>
            </InputGroup>
          </Panel>

          {usage === 'RES_PRINCIPALE' && (
            <Panel p={'30px'} w="100%">
              <Text as="b">Information relative au PTZ </Text>

              <Text mt={'10px'} mb={'5px'}>
                Primo Accèdant
              </Text>
              <RadioGroup value={primoAccedant}>
                <Stack spacing={5} direction="row">
                  <Radio colorScheme="teal" value={1} onClick={() => setPrimoAccedant(1)}>
                    Oui
                  </Radio>
                  <Radio colorScheme="teal" value={0} onClick={() => setPrimoAccedant(0)}>
                    Non
                  </Radio>
                </Stack>
              </RadioGroup>
              {primoAccedant === 1 && (
                <>
                  {typeLogement !== 'LOGEMENT_NEUF' && (
                    <>
                      <Text mt={'10px'} mb={'5px'}>
                        Logement ancien avec travaux ou social ?
                      </Text>
                      <RadioGroup value={logementAncienSousType}>
                        <Stack spacing={5} direction="column">
                          <Radio
                            colorScheme="teal"
                            value={'NOTRAVAUX'}
                            onClick={() => setLogementAncienSousType('NOTRAVAUX')}
                          >
                            Montant de travaux inférieur à 25% du coût total de l’opération
                          </Radio>
                          <Radio
                            colorScheme="teal"
                            value={'TRAVAUX'}
                            onClick={() => setLogementAncienSousType('TRAVAUX')}
                          >
                            Montant de travaux supérieur à 25% du coût total de l’opération
                          </Radio>
                          <Radio
                            colorScheme="teal"
                            value={'SOCIAL'}
                            onClick={() => setLogementAncienSousType('SOCIAL')}
                          >
                            Rachat d’un logement social
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </>
                  )}
                  {typeLogement === 'LOGEMENT_NEUF' && (
                    <>
                      <Text mt={'10px'} mb={'5px'}>
                        Logement individuel ou collectif ?
                      </Text>
                      <RadioGroup value={logementNeufSousType}>
                        <Stack spacing={5} direction="column">
                          <Radio
                            colorScheme="teal"
                            value={'INDIVIDUEL'}
                            onClick={() => setLogementNeufSousType('INDIVIDUEL')}
                          >
                            Individuel
                          </Radio>
                          <Radio
                            colorScheme="teal"
                            value={'COLLECTIF'}
                            onClick={() => setLogementNeufSousType('COLLECTIF')}
                          >
                            Collectif
                          </Radio>
                          <Radio
                            colorScheme="teal"
                            value={'PSLA'}
                            onClick={() => setLogementNeufSousType('PSLA')}
                          >
                            Logement social (PSLA ou BRS)
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </>
                  )}
                  <Text mt={'10px'} mb={'5px'}>
                    Zone PTZ du bien
                  </Text>
                  <Text fontSize={'12px'}>
                    La ville permet de connaitre la zone PTZ, sélectionnez là ou entrer directement
                    la zone cible.
                  </Text>
                  <CitySearch
                    onSelect={(city) => {
                      setZone(city.zone_ptz);
                      setCC(city.cc);
                      setCP(city.cp);
                    }}
                    resetZoneTrigger={resetZone}
                  />

                  <RadioGroup value={zone} mt={'10px'}>
                    <Stack spacing={5} direction="row">
                      <Radio
                        disabled={true}
                        colorScheme="teal"
                        value={'A'}
                        onClick={() => {
                          setZone('A');
                          SetResetZone(resetZone + 1);
                        }}
                      >
                        A
                      </Radio>
                      <Radio
                        disabled={true}
                        colorScheme="teal"
                        value={'A BIS'}
                        onClick={() => {
                          setZone('A BIS');
                          SetResetZone(resetZone + 1);
                        }}
                      >
                        A Bis
                      </Radio>
                      <Radio
                        disabled={true}
                        colorScheme="teal"
                        value={'B1'}
                        onClick={() => {
                          setZone('B1');
                          SetResetZone(resetZone + 1);
                        }}
                      >
                        B1
                      </Radio>
                      <Radio
                        disabled={true}
                        colorScheme="teal"
                        value={'B2'}
                        onClick={() => {
                          setZone('B2');
                          SetResetZone(resetZone + 1);
                        }}
                      >
                        B2
                      </Radio>
                      <Radio
                        disabled={true}
                        colorScheme="teal"
                        value={'C'}
                        onClick={() => {
                          setZone('C');
                          SetResetZone(resetZone + 1);
                        }}
                      >
                        C
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  <Text mt={'10px'} mb={'5px'}>
                    Nombre d'occupants du futur logement{' '}
                  </Text>

                  <Slider
                    aria-label="slider-ex-6"
                    min={1}
                    max={6}
                    pt={'20px'}
                    value={nbOccupant}
                    onChange={(val) => setNbOccupant(val)}
                  >
                    <SliderMark ml={'-4px'} pt={'5px'} value={1}>
                      1
                    </SliderMark>
                    <SliderMark ml={'-4px'} pt={'5px'} value={2}>
                      2
                    </SliderMark>
                    <SliderMark ml={'-4px'} pt={'5px'} value={3}>
                      3
                    </SliderMark>
                    <SliderMark ml={'-4px'} pt={'5px'} value={4}>
                      4
                    </SliderMark>
                    <SliderMark ml={'-4px'} pt={'5px'} value={5}>
                      5
                    </SliderMark>
                    <SliderMark ml={'-6px'} pt={'5px'} value={6}>
                      5+
                    </SliderMark>
                    <SliderTrack bg={'lightgray'}>
                      <SliderFilledTrack bg={'teal'} />
                    </SliderTrack>
                    <SliderThumb bg={'teal'}></SliderThumb>{' '}
                  </Slider>

                  <Text pt="20px" mt={'10px'} mb={'5px'}>
                    Revenu Fiscal de référence
                  </Text>
                  <InputGroup>
                    <Input
                      value={rfr}
                      onChange={(e) => setRfr(e.target.value.replace(/\D/g, ''))}
                    ></Input>{' '}
                    <InputRightAddon h={'45px'}>Euros</InputRightAddon>
                  </InputGroup>
                </>
              )}
            </Panel>
          )}
        </VStack>
        <VStack flex={1} spacing={4} align={'left'} width={{ base: '100%', lg: '50%' }}>
          <Panel p={'30px'} width={'100%'}>
            <Text as="b" mb="10px">
              Résultat de la simulation :
            </Text>
            {resultLoading && (
              <Box mt={'50px'}>
                <Center>
                  <Spinner size="xl" mb="5px" color="teal" thickness="4px" speed="0.65s"></Spinner>
                </Center>
                <Center>
                  <Text mt={'10px'}>Chargement du resultat</Text>
                </Center>
              </Box>
            )}
            {!resultLoading && resultat && (
              <>
                <Center mt="20px">
                  <Text>Prix de logement </Text>
                </Center>
                <Center>
                  <Text as="b" color="green" fontSize={'20px'}>
                    {Math.round(resultat.prix_logement)} €
                  </Text>
                </Center>

                <Text mt={'20px'}>
                  Avec un financement total sur <b>{resultat.duree_pp / 12}</b> ans de{' '}
                  <b>{resultat.montant_total}</b> € incluant un apport de <b>{resultat.apport}</b> €
                  {resultat.montant_ptz && resultat.montant_ptz !== 0 ? (
                    <>
                      , un prêt principal de <b>{resultat.montant_pp}</b> € et un prêt a taux zéro
                      de <b>{resultat.montant_ptz}</b> €{' '}
                    </>
                  ) : (
                    <>
                      {' '}
                      et un prêt principal de <b>{resultat.montant_pp}</b> €
                    </>
                  )}
                </Text>

                <Text mt={'20px'}>
                  Le financement total est décomposé tel que :
                  <UnorderedList>
                    <ListItem>
                      Prix de logement : <b>{Math.round(resultat.prix_logement)}</b> €
                    </ListItem>
                    <ListItem>
                      Frais de notaire : <b>{Math.round(resultat.frais_notaire)}</b> €
                    </ListItem>
                    <ListItem>
                      Frais de garantie : <b>{Math.round(resultat.frais_garantie)}</b> €
                    </ListItem>
                  </UnorderedList>
                </Text>

                {/**
                 <Box mt={'50px'}>
                  <Center>
                    <Text>
                      <b>Résultats alternatifs</b>
                    </Text>
                  </Center>

                  {resultat.capacites ? (
                    <>
                      {resultat.capacites.map((capa: any) => (
                        <Box>
                          <Center mt={'20px'}>
                            <Text>
                              {' '}
                              Sur <b>{capa.duree_pp / 12}</b> ans, prix du logement :{' '}
                            </Text>
                          </Center>
                          <Center>
                            <Text as="b" color="green" fontSize={'20px'}>
                              {Math.round(capa.prix_logement)} €
                            </Text>
                          </Center>
                        </Box>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
                 */}
              </>
            )}

            {!resultLoading && !resultat && (
              <Box mt={'50px'}>
                <Center>
                  <WarningTwoIcon fontSize={'60px'} color="red.200"></WarningTwoIcon>
                </Center>
                <Center>
                  <Text color={'red'} mt={'10px'}>
                    Oups
                  </Text>
                </Center>
                <Center>
                  <Text mt={'10px'} color={'red'}>
                    Aucun résultat a afficher
                  </Text>
                </Center>
              </Box>
            )}
          </Panel>
        </VStack>
      </Stack>
    </>
  );
};

export const CalculatriceCapacitePageRoute = () => {
  const me = useContext(MeContext);

  return (
    <Layout
      courtier={me.courtier!}
      utilisateur={me.utilisateur!}
      sidebar={<HomeSidebar withKPI={false} withFilter={false} utilisateur={me.utilisateur!} />}
      leftSidebar={true}
    >
      <CapacitePage />
    </Layout>
  );
};

export const PublicCalculatriceCapacitePageRoute = () => {
  return (
    <CustomLayoutBouygue>
      <CapacitePage />
    </CustomLayoutBouygue>
  );
};

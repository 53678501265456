import { createIcon } from '@chakra-ui/react';

export const EyeIcon = createIcon({
  displayName: 'EyeIcon',
  viewBox: '0 0 21 15',
  path: (
    <path
      fill="currentColor"
      d="M20.859 7.283C20.679 7.03 16.09.637 10.456.637 4.787.637.236 7.03.055 7.283c-.072.109-.072.29 0 .434.18.253 4.768 6.646 10.402 6.646 5.599 0 10.222-6.393 10.403-6.646.108-.145.108-.29 0-.434zm-10.403 4.19A3.962 3.962 0 016.484 7.5a3.962 3.962 0 013.974-3.973A3.962 3.962 0 0114.43 7.5a3.962 3.962 0 01-3.973 3.973z"
    />
  ),
});

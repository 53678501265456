import { Action, EtatProjet, MaturiteProjet, TypeProjet, UsageProjet } from 'types';

export const BIEN_A_VENDRE = {
  VENDRE_AVANT: 'Bien à vendre avant achat',
  VENDRE_APRES: 'Bien à vendre après achat',
  GARDER: 'Garde son bien',
};

export const ACTIONS: Record<Action, string> = {
  RELANCE_CLIENT: 'Relancer client',
  TRANSFERT_DOSSIER: 'Transférer',
  SUPPRIMER_DOSSIER: 'Supprimer',
};

export const TYPE_PROJET: Record<TypeProjet, string> = {
  LOGEMENT_ANCIEN: 'Ancien',
  LOGEMENT_NEUF: 'Neuf',
  TERRAIN_ET_CONSTRUCTION: 'Terrain et construction',
  RENEGOCIATION: 'Renégociation',
  TRAVAUX: 'Travaux',
  CONSTRUCTION: 'Construction',
  TERRAIN: 'Terrain',
  ACHAT_SCPI: 'Achat SCPI',
};

export const MATURITE_PROJET: Record<MaturiteProjet, string> = {
  RECHERCHE: 'En recherche',
  SIGNE: 'Signé',
  TROUVE: 'Trouvé',
};

export const USAGE_PROJET: Record<UsageProjet, string> = {
  RES_PRINCIPALE: 'Résidence principale',
  RES_SECONDAIRE: 'Résidence secondaire',
  LOCATIF: 'Projet Locatif',
  PRO: 'Local Professionel',
};

export const ETAT_PROJET: Record<EtatProjet, string> = {
  A_SIGNER_PAR_COEMPRUNTEUR: 'A signer par le co-emprunteur',
  A_SIGNER_PAR_EMPRUNTEUR: "A signer par l'emprunteur",
  A_SIGNER_PAR_MANDATAIRE: 'A signer par le mandataire',
  ANNULE: 'Annulé',
  ARCHIVE: 'Archivé',
  ATTENTE_LEVEE_PSLA: 'Attente levée PSLA',
  AUCUNE_OFFRE_ACCEPTEE: 'Aucune proposition acceptée',
  AUCUNE_OFFRE_REÇUE: 'Aucune proposition reçue',
  BANQUE_A_FACTURER: 'Banque à facturer',
  BANQUE_FACTUREE: 'Banque facturée',
  CLAUSE_DEDIT: 'Clause de dédit',
  CONTACTE: 'Contacté',
  COORDONNEES_INVALIDES: 'Coordonnées invalides',
  DOSSIER_A_COMPLETER: 'Dossier à compléter par le client',
  DOSSIER_A_CONTROLER: 'Dossier à contrôler par le courtier',
  DOSSIER_ANNULE: 'Dossier annulé',
  DOSSIER_CLOS: 'Dossier clos',
  DOSSIER_CONCLU: 'Dossier conclu',
  DOSSIER_DOUBLON: 'Simulation doublon',
  DOSSIER_NON_FINANCABLE: 'Dossier non finançable',
  DOUBLON: 'Doublon',
  ECHEC: 'Échec',
  IMPOSSIBLE: 'Impossible',
  INJOIGNABLE: 'Injoignable',
  MANDAT_A_ENVOYER: 'Mandat à envoyer (service signature)',
  MANDAT_DENONCE: 'Mandat dénoncé',
  MANDAT_ROMPU: 'Mandat rompu',
  MEL_ENVOYE: 'Mail envoyé',
  NON_CHALLENGEABLE: 'Offre non challengeable',
  NON_FINANCABLE: 'Non finançable',
  NON_INTERESSE: 'Non intéressé',
  OBTENTION_CHOIX_OFFRE: 'En attente retour banques et validation',
  OFFRE_ACCEPTEE: 'Proposition acceptée',
  OFFRE_ACCEPTEE_SANS_COMMISSION: 'Proposition acceptée sans commission',
  PROJET_A_QUALIFIER: 'Projet à qualifier',
  PROJET_ABANDONNE: 'Prrojet abandonné',
  PROJET_NON_QUALIFIE: 'Dossier clos hors périmètre',
  PROJET_QUALIFIE: 'Simulation réalisée',
  RETRACTATION: 'Rétractation client après validation client',
  RUPTURE_EN_COURS: 'Rupture en cours',
};

export const mappingLabelFacets: Map<string, string> = new Map([
  ['dossiers', 'Dossier'],
  ['liens', 'Lien'],
  ['contacts', 'Fiche contact'],
  ['RECHERCHE', 'En recherche'],
  ['TROUVE', 'Bien trouvé'],
  ['SIGNE', 'Compromis signé'],
  ['LOGEMENT_ANCIEN', 'Logement ancien'],
  ['LOGEMENT_NEUF', 'Logement neuf'],
  ['TERRAIN_ET_CONSTRUCTION', 'Terrain et construction'],
  ['RENEGOCIATION', 'Renégociation'],
  ['TRAVAUX', 'Travaux'],
  ['CONSTRUCTION', 'Construction'],
  ['TERRAIN', 'Terrain uniquement'],
  ['ACHAT_SCPI', 'Achat SCPI'],
  ['RES_PRINCIPALE', 'Résidence principale'],
  ['LOCATIF', 'Locatif'],
  ['RES_SECONDAIRE', 'Résidence secondaire'],
  ['PRO', 'Local profesionnel'],
  ['GARDER', 'Garde son bien'],
  ['VENDRE_AVANT', 'Vente avant projet'],
  ['VENDRE_APRES', 'Vente après projet'],
  ['A_CONTACTER', 'A contacter'],
  ['A_SIGNER_PAR_COEMPRUNTEUR', 'A signer par le co-emprunteur'],
  ['A_SIGNER_PAR_EMPRUNTEUR', "A signer par l'emprunteur"],
  ['A_SIGNER_PAR_MANDATAIRE', 'A signer par le mandataire'],
  ['A_SUIVRE', 'A suivre'],
  ['ANNULE', 'Annulé'],
  ['ARCHIVE', 'Archivé'],
  ['ATTENTE_LEVEE_PSLA', 'Attente levée PSLA'],
  ['AUCUNE_OFFRE_ACCEPTEE', 'Aucune proposition acceptée'],
  ['AUCUNE_OFFRE_REÇUE', 'Aucune proposition reçue'],
  ['BANQUE_A_FACTURER', 'Banque à facturer'],
  ['BANQUE_FACTUREE', 'Banque facturée'],
  ['CLAUSE_DEDIT', 'Clause de dédit'],
  ['CONTACTE', 'Contacté'],
  ['COORDONNEES_INVALIDES', 'Coordonnées invalides'],
  ['DOSSIER_A_COMPLETER', 'Dossier à compléter par le client'],
  ['DOSSIER_A_CONTROLER', 'Dossier à contrôler par le courtier'],
  ['DOSSIER_ANNULE', 'Dossier annulé'],
  ['DOSSIER_CLOS', 'Dossier clos'],
  ['DOSSIER_CONCLU', 'Dossier conclu'],
  ['DOSSIER_DOUBLON', 'Simulation doublon'],
  ['DOSSIER_NON_FINANCABLE', 'Dossier non finançable'],
  ['DOUBLON', 'Doublon'],
  ['ECHEC', 'Échec'],
  ['IMPOSSIBLE', 'Impossible'],
  ['INJOIGNABLE', 'Injoignable'],
  ['MANDAT_A_ENVOYER', 'Mandat à envoyer (service signature)'],
  ['MANDAT_DENONCE', 'Mandat dénoncé'],
  ['MANDAT_ROMPU', 'Mandat rompu'],
  ['MEL_ENVOYE', 'Mail envoyé'],
  ['NON_CHALLENGEABLE', 'Offre non challengeable'],
  ['NON_FINANCABLE', 'Non finançable'],
  ['NON_INTERESSE', 'Non intéressé'],
  ['OBTENTION_CHOIX_OFFRE', 'En attente retour banques et validation'],
  ['OFFRE_ACCEPTEE', 'Proposition acceptée'],
  ['OFFRE_ACCEPTEE_SANS_COMMISSION', 'Proposition acceptée sans commission'],
  ['PROJET_A_QUALIFIER', 'Projet à qualifier'],
  ['PROJET_ABANDONNE', 'Projet abandonné'],
  ['PROJET_NON_QUALIFIE', 'Dossier clos hors périmètre'],
  ['PROJET_QUALIFIE', 'Projet Qualifié'],
  ['PROJET_QUALIFIE', 'Simulation réalisée'],
  ['RETRACTATION', 'Rétractation client après validation client'],
  ['RUPTURE_EN_COURS', 'Rupture en cours'],
]);

export const LabelToast = {
  SUCCESS_LIEN_SMS: 'Envoi de lien SMS réussie',
  FAIL_LIEN_SMS: "Échec d'envoi de lien SMS: ",
  SUCCESS_LIEN_MAIL: 'Envoi de lien MAIL réussie',
  FAIL_LIEN_MAIL: "Échec d'envoi de lien mail: ",
  SUCCESS_FICHE_CONTACT: 'Dépôt de fiche contact réussie',
  FAIL_FICHE_CONTACT: 'Échec de creation de fiche contact: ',
  SUCCESS_DEPOT_COMMENTAIRE: 'Envoi de commentaire réussie',
  FAIL_DEPOT_COMMENTAIRE: "Échec d'envoi de commentaire: ",
};

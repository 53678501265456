import React, { useState } from 'react';

import { Text } from '@chakra-ui/react';

import { DatePicker } from './DatePicker';
import { localization as defaultLocalization } from './defaultLocalization';

type RefineProps = {
  min?: number;
  max?: number;
};

type Props = {
  refine: (nextRefinement: RefineProps) => void;
  datePickerProps: any;
  min: any;
  max: any;
  titre: string;
};

const getBorneMin = (d1: string, d2: string) => {
  const date1 = new Date(d1).getTime();
  const date2 = new Date(d2).getTime();

  return Math.max(date1, date2);
};

const getBorneMax = (d1: string, d2: string) => {
  const date1 = new Date(d1).getTime();
  const date2 = new Date(d2).getTime();

  const msDansUneJournee = 86400000;
  if (Math.abs(date1 - date2) < msDansUneJournee) {
    //On a choisi la dernière journée disponible, on prend la valeur haute pour inclure les éléments de la dernière journée dans le filtre
    return Math.max(date1, date2);
  }
  return Math.min(date1, date2);
};

const convertToString = (timestamp: number) => {
  const date = new Date(timestamp);
  return `${date.getFullYear()}-${date.getMonth() < 9 ? '0' : ''}${date.getMonth() + 1}-${
    date.getDate() < 10 ? '0' : ''
  }${date.getDate()}`;
};

const convertToStringFr = (timestamp: number) => {
  if (!timestamp) {
    return;
  }
  const date = new Date(timestamp);
  return `${date.getDate() < 10 ? '0' : ''}${date.getDate()}/${date.getMonth() < 9 ? '0' : ''}${
    date.getMonth() + 1
  }/${date.getFullYear()}`;
};

export const DateRangePickerComponent = (props: Props) => {
  const [beginDate, setBeginDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const refine = (begin: string | null, end: string | null) => {
    const min = begin ? getBorneMin(begin, props.min) : undefined;
    const max = end ? getBorneMax(end, props.max) : undefined;

    props.refine({ min, max });
  };

  const { dateAdapter = undefined, localization = defaultLocalization } =
    props.datePickerProps || {};

  return (
    <>
      <Text as="h3" fontWeight="bold" fontSize="lg" px={6} py={2}>
        {props.titre}
      </Text>
      <div className="date-range-picker">
        <div className="first_picker">
          <DatePicker
            dateAdapter={dateAdapter}
            localization={{
              ...localization,
              placeholder: convertToStringFr(props.min),
            }}
            value={beginDate}
            min={convertToString(props.min)}
            max={convertToString(props.max)}
            duetChange={(event: any) => {
              setBeginDate(event.detail.value);
              if (endDate && new Date(event.detail.value) > new Date(endDate)) {
                setEndDate(null);
                refine(event.detail.value, null);
              } else {
                refine(event.detail.value, endDate);
              }
            }}
          />
        </div>

        <div>
          <DatePicker
            dateAdapter={dateAdapter}
            localization={{
              ...localization,
              placeholder: convertToStringFr(props.max),
            }}
            value={endDate}
            min={beginDate}
            max={convertToString(props.max)}
            duetChange={(event: any) => {
              if (beginDate && new Date(event.detail.value) < new Date(beginDate)) {
                setBeginDate(null);
                refine(null, event.detail.value);
              } else {
                setEndDate(event.detail.value);
                refine(beginDate, event.detail.value);
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

import { CSSObject } from '@chakra-ui/react';
import { GlobalStyleProps } from '@chakra-ui/theme-tools';
import { rgba } from 'polished';

import { arrowLeftIconDataURI } from 'components/Icons/ArrowLeft';
import { arrowRightIconDataURI } from 'components/Icons/ArrowRight';

export const getStyles = (props: GlobalStyleProps): CSSObject => ({
  '.DayPicker': {
    display: 'inline-block',
  },

  '.DayPicker-wrapper': {
    flexDirection: 'row',
    userSelect: 'none',
  },

  '.DayPicker-Months': {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },

  '.DayPicker-Month': {
    borderCollapse: 'collapse',
    borderSpacing: 0,
    display: 'table',
    userSelect: 'none',
  },

  '.DayPicker-NavButton': {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    display: 'inline-block',
    position: 'absolute',
    top: '7px',
    height: 6,
    width: 6,
  },

  '.DayPicker-NavButton:hover': {
    opacity: 0.8,
  },

  '.DayPicker-NavButton--prev': {
    backgroundImage: `url('${arrowLeftIconDataURI}')`,
    left: 0,
  },

  '.DayPicker-NavButton--next': {
    backgroundImage: `url('${arrowRightIconDataURI}')`,
    right: 0,
  },

  '.DayPicker-NavButton--interactionDisabled': {
    display: 'none',
  },

  '.DayPicker-Caption': {
    display: 'table-caption',
    fontSize: '2xl',
    fontWeight: 'light',
    marginBottom: 3,
    textAlign: 'center',
  },

  '.DayPicker-Weekdays': {
    display: 'table-header-group',
  },

  '.DayPicker-WeekdaysRow': {
    display: 'table-row',
  },

  '.DayPicker-Weekday': {
    display: 'table-cell',
    fontSize: 'xxs',
    fontWeight: 'bold',
    paddingBottom: 7,
    textAlign: 'center',
    textTransform: 'uppercase',
  },

  '.DayPicker-Weekday abbr[title]': {
    borderBottom: 'none',
    textDecoration: 'none',
  },

  '.DayPicker-Body': {
    display: 'table-row-group',
  },

  '.DayPicker-Week': {
    display: 'table-row',
  },

  '.DayPicker-Day': {
    color: 'white',
    display: 'table-cell',
    fontSize: 'lg',
    fontWeight: 'medium',
    position: 'relative',
    width: '32px',
    zIndex: 1,
  },

  '.DayPicker--interactionDisabled .DayPicker-Day': {
    cursor: 'default',
  },

  '.DayPicker-Day--outside': {
    color: rgba('white', 0.4),
    cursor: 'default',
  },

  '.DayPicker-Day--disabled': {
    color: rgba('white', 0.4),
    cursor: 'not-allowed',
  },

  '.DayPicker-Day__content': {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    height: '32px',
    justifyContent: 'center',
    marginBottom: 3,
    zIndex: 2,
  },

  /* First & last days selected */
  '.DayPicker-Day--start:not(.DayPicker-Day--outside) .DayPicker-Day__content, .DayPicker-Day--end:not(.DayPicker-Day--outside) .DayPicker-Day__content':
    {
      backgroundColor: 'teal.500',
      borderRadius: '50%',
    },

  '.DayPicker-Day--start:not(.DayPicker-Day--outside)::after, .DayPicker-Day--end:not(.DayPicker-Day--outside)::after':
    {
      backgroundColor: rgba(props.theme.colors.teal['500'], 0.5),
      content: "''",
      display: 'block',
      height: `calc(100% - 5px)`,
      left: '50%',
      position: 'absolute',
      top: 0,
      width: '50%',
      zIndex: -1,
    },

  '.DayPicker-Day--end:not(.DayPicker-Day--outside)::after': {
    left: 0,
  },

  /* First & last selected days of the week */
  '.DayPicker-Week > .DayPicker-Day--selected:first-of-type .DayPicker-Day__content': {
    borderBottomLeftRadius: '50%',
    borderTopLeftRadius: '50%',
  },

  '.DayPicker-Week > .DayPicker-Day--selected:last-of-type .DayPicker-Day__content': {
    borderBottomRightRadius: '50%',
    borderTopRightRadius: '50%',
  },

  /* Selected day */
  '.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) .DayPicker-Day__content':
    {
      backgroundColor: rgba(props.theme.colors.teal['500'], 0.5),
    },

  /* Hovering a selected day */
  '.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) .DayPicker-Day__content:hover':
    {
      backgroundColor: 'teal.500',
    },

  /* Selecting a day */
  '.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside) .DayPicker-Day__content:hover':
    {
      backgroundColor: 'teal.500',
      borderRadius: '50%',
    },
});

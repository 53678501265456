import { Panel } from 'components/Panel';
import { useContext } from 'react';
import { AuthContext } from 'auth/AuthContextProvider';
import { useQuery } from 'react-query';
import { Center } from '@chakra-ui/react';
import { Chart } from 'react-google-charts';

import { StatError, StatLoading, StatNoData } from '../StatSharedElement';

import { FilterDefinition, GenerateFilterQuery } from '../StatFilter';

export type Props = {
  label: string;
  targetQuery: string;
  grand?: boolean;
  marginAreaPourcent?: number;
  sortByValue?: boolean;
  filterDefinition?: FilterDefinition;
};

type KeyValue = {
  key: string;
  value: number;
};

export const StatPanelColChart = ({
  label,
  targetQuery,
  grand = false,
  marginAreaPourcent = 15,
  sortByValue = false,
  filterDefinition,
}: Props) => {
  const { token } = useContext(AuthContext);

  const Query = '/API/stats/' + targetQuery + GenerateFilterQuery(filterDefinition);
  const { isLoading, error, data } = useQuery(
    'get' + Query,
    () =>
      fetch(Query, {
        method: 'GET',
        headers: new Headers({
          Authorization: token,
        }),
      }).then((res) => res.json()),
    { refetchOnWindowFocus: false },
  );

  let componentsToSHow = <></>;
  if (isLoading) {
    componentsToSHow = StatLoading;
  }

  if (error) {
    componentsToSHow = StatError;
  }

  let chartData = [['Type', 'Nombre', { role: 'annotation' }, { role: 'tooltip' }]];

  if (data) {
    if (data.list.length > 0) {
      if (sortByValue) {
        data.list.sort((a: KeyValue, b: KeyValue) => (a.value < b.value ? 1 : -1));
      } else {
        data.list.sort((a: KeyValue, b: KeyValue) => (a.key < b.key ? -1 : 1));
      }
      data.list.forEach(function (value: any) {
        const key = value.key == null ? 'NC' : value.key + ' étoiles';
        const tooltip =
          value.value +
          ' dossiers ' +
          (value.key == null ? ' sans évaluation' : 'avec ' + value.key + ' étoiles');
        chartData = chartData.concat([[key, value.value, value.value, tooltip]]);
      });
      componentsToSHow = (
        <Chart
          chartType="ColumnChart"
          options={{
            pieHole: 0.4,
            height: 260,
            width: grand ? 610 : 290,
            colors: ['#31A0A1', '#FCB630', '#D58500', '#F4D560', '#1A3742', '#09535E'],
            chartArea: {
              left: marginAreaPourcent + '%',
              top: '5%',
              width: 100 - marginAreaPourcent + '%',
              height: '85%',
            },
            legend: { position: 'none' },
          }}
          data={chartData}
        />
      );
    } else {
      componentsToSHow = StatNoData;
    }
  }

  return (
    <Panel
      height="300px"
      position="relative"
      width={grand ? '620px' : '300px'}
      float={'left'}
      margin={{ base: '10px' }}
      py={'10px'}
    >
      <Center as="b" fontSize={'15px'}>
        {label}
      </Center>
      {componentsToSHow}
    </Panel>
  );
};

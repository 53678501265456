import { createIcon } from '@chakra-ui/react';

export const UserIcon = createIcon({
  displayName: 'UserIcon',
  viewBox: '0 0 16 17',
  path: (
    <path
      fill="currentColor"
      d="M8 9.167c2.206 0 4-1.794 4-4V4.5c0-2.206-1.794-4-4-4s-4 1.794-4 4v.667c0 2.206 1.794 4 4 4zM12.881 11.154a18.612 18.612 0 00-9.762 0A3.35 3.35 0 00.667 14.37v2.13h14.666v-2.13a3.35 3.35 0 00-2.452-3.216z"
    />
  ),
});

import { Select, Props as SelectProps } from 'components/Select';

export type Props = SelectProps;

// "PopoverSelect" is a Select component that looks like a Popover (with a caret icon),
// but opening a native dropdown of <option>'s.
export const PopoverSelect = (props: Props) => {
  return (
    <Select
      _hover={{ textDecoration: 'underline' }}
      backgroundColor="transparent"
      borderRadius="md"
      color="teal.900"
      cursor="pointer"
      fontSize="md"
      fontWeight="semibold"
      iconColor="gray.300"
      iconSize="10px"
      variant="unstyled"
      width={{ base: 'full', lg: 'auto' }}
      {...props}
    />
  );
};

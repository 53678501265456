import { useState } from 'react';
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Stack,
} from '@chakra-ui/react';
import { Etape } from 'types';
import { format } from 'date-fns';
import { DATE_FORMAT } from 'utils/helpers';

export type Props = {
  etapes?: Etape[];
};

export const EtapeViewer = ({ etapes, ...props }: Props) => {
  const [etapeModalOpen, setEtapeModalOpen] = useState<boolean>(false);

  // liste non vide
  if (etapes && etapes.length > 0) {
    let numeroValide = 0;
    for (let i = 0; i < etapes.length; i++) {
      if (etapes[i].validee) {
        numeroValide = i;
      }
    }
    const derniere_etape = numeroValide === etapes.length - 1;
    const premiere_etape = numeroValide === 0;

    return (
      <>
        <Stack spacing={4} direction={{ base: 'column', lg: 'row' }}>
          {derniere_etape && (
            <>
              <Box w={'255px'} backgroundColor={'#F7F6F4'} borderRadius={'15px'} padding={'8px'}>
                <OneEtape etape={etapes[numeroValide - 2]} />
              </Box>
              <Box w={'255px'} backgroundColor={'#F7F6F4'} borderRadius={'15px'} padding={'8px'}>
                <OneEtape etape={etapes[numeroValide - 1]} />
              </Box>
              <Box w={'255px'} backgroundColor={'#F7F6F4'} borderRadius={'15px'} padding={'8px'}>
                <OneEtape etape={etapes[numeroValide]} />
              </Box>
            </>
          )}
          {premiere_etape && (
            <>
              <Box w={'255px'} backgroundColor={'#F7F6F4'} borderRadius={'15px'} padding={'8px'}>
                <OneEtape etape={etapes[numeroValide]} />
              </Box>
              <Box w={'255px'} backgroundColor={'#F7F6F4'} borderRadius={'15px'} padding={'8px'}>
                <OneEtape etape={etapes[numeroValide + 1]} />
              </Box>
              <Box w={'255px'} backgroundColor={'#F7F6F4'} borderRadius={'15px'} padding={'8px'}>
                <OneEtape etape={etapes[numeroValide + 2]} />
              </Box>
            </>
          )}
          {!derniere_etape && !premiere_etape && numeroValide !== -1 && (
            <>
              <Box w={'255px'} backgroundColor={'#F7F6F4'} borderRadius={'15px'} padding={'8px'}>
                <OneEtape etape={etapes[numeroValide - 1]} />
              </Box>
              <Box w={'255px'} backgroundColor={'#F7F6F4'} borderRadius={'15px'} padding={'8px'}>
                <OneEtape etape={etapes[numeroValide]} />
              </Box>
              <Box w={'255px'} backgroundColor={'#F7F6F4'} borderRadius={'15px'} padding={'8px'}>
                <OneEtape etape={etapes[numeroValide + 1]} />
              </Box>
            </>
          )}
          <Box
            onClick={() => setEtapeModalOpen(true)}
            w={'100px'}
            h={'40px'}
            backgroundColor={'#F7F6F4'}
            borderRadius={'15px'}
            padding={'9px'}
            textAlign={'center'}
          >
            <Text color="teal.900" fontSize="sm">
              Voir plus ...
            </Text>
          </Box>
        </Stack>

        <Modal
          isOpen={etapeModalOpen}
          onClose={() => setEtapeModalOpen(false)}
          scrollBehavior={'inside'}
          size={'xl'}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Avancement Projet</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={5} direction="column">
                {etapes.map((etape, index) => {
                  return (
                    <>
                      <OneEtape etape={etape} />
                    </>
                  );
                })}
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  }
  // liste potentiellement vide ou undefined
  else {
    return <>Liste d'étapes KO</>;
  }
};

export type PropsEtape = {
  etape: Etape;
};
const OneEtape = ({ etape, ...props }: PropsEtape) => {
  return (
    <Stack spacing={8} direction="row">
      <Box
        marginTop={'3px'}
        width={'40px'}
        minWidth={'40px'}
        maxWidth={'40px'}
        height={'40px'}
        fontWeight={900}
        fontSize={'bold'}
        backgroundColor={etape.validee ? '#14CF97' : '#ffdc7d'}
        textAlign="center"
        paddingTop={'8px'}
        borderRadius={'20px'}
      >
        {etape.numero + 1}
      </Box>
      <Box>
        <Text fontSize="sm">{etape.code}</Text>
        {etape.validee ? (
          <>
            <Text color={'#14CF97'} fontSize="sm" fontWeight="bold">
              Réalisé
              {etape.date_validation &&
                ' le ' + format(new Date(etape.date_validation), DATE_FORMAT)}
            </Text>
          </>
        ) : (
          <>
            <Text color={'#FF9A01'} fontSize="sm" fontWeight="bold">
              A suivre
            </Text>
          </>
        )}
      </Box>
    </Stack>
  );
};

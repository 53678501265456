import { useState } from 'react';
import { getParameterByNameOnUrl } from 'utils/helpers';
import { useHistory } from 'react-router-dom';
import { InputField } from 'utils/form';
import { getLogo } from 'utils/marque';

import { auth } from 'auth/AuthContextProvider';
import { Center, Text, FormControl, Box, Image, Spinner } from '@chakra-ui/react';
import { Button } from 'components/Button';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required('Nouveau mot de passe requis')
    .min(6, 'Doit comporter au moins 6 caractères'),
  newPasswordVerif: yup
    .string()
    .required('Vérification nouveau mot de passe requis')
    .min(6, 'Doit comporter au moins 6 caractères'),
});
export type PasswordChange = {
  newPassword: string;
  newPasswordVerif: string;
};

export const PasswordRecoveryPage = ({ ...props }) => {
  const [codeVerificationAsked, setCodeVerificationAsked] = useState<boolean>(false);
  const [codeChecked, setCodeChecked] = useState<boolean>(false);
  const [codeError, setCodeError] = useState<boolean>(true);
  const [accountToRecovery, setAccountToRecovery] = useState<string>('');
  const [error, setErrorMessage] = useState<string | null>(null);
  const [success, setSuccessMessage] = useState<string | null>(null);
  const history = useHistory();

  const actionMode: string = getParameterByNameOnUrl('mode')!;
  const actionOobCode: string = getParameterByNameOnUrl('oobCode')!;
  const actionApiKey: string = getParameterByNameOnUrl('apiKey')!;

  if (!codeVerificationAsked) {
    setCodeVerificationAsked(true);
    auth
      .verifyPasswordResetCode(actionOobCode)
      .then((mail) => {
        console.log('mail a reset = ' + mail);
        setCodeChecked(true);
        setCodeError(false);
        setAccountToRecovery(mail);
      })
      .catch((error) => {
        console.log('Erreur rencontrée: ' + error.message);
        setCodeChecked(true);
        setCodeError(true);
      });
  }

  const formikPasswordChange = useFormik({
    validationSchema,
    initialValues: {
      newPassword: '',
      newPasswordVerif: '',
    },
    onSubmit: (values: PasswordChange, actions) => {
      setErrorMessage(null);
      setSuccessMessage(null);

      if (values.newPassword !== values.newPasswordVerif) {
        actions.setSubmitting(false);
        setErrorMessage('Les deux mots de passe sont différents');
      } else {
        auth
          .confirmPasswordReset(actionOobCode, values.newPassword)
          .then((data) => {
            console.log('sucessfully change');
            actions.setSubmitting(false);
            actions.resetForm();
            setSuccessMessage(
              'Nouveau mot de passe correctement enregistré, redirection vers la page de connexion ...',
            );
            setTimeout(function () {
              history.push('/');
            }, 5000);
          })
          .catch((error) => {
            console.log('error on change password ' + error.message);
            actions.setSubmitting(false);
            actions.resetForm();
            setErrorMessage('Erreur rencontrée: ' + error.message);
          });
      }
    },
  });

  return (
    <>
      <Box position="fixed" width="100%" bottom="0" right="0">
        <Image
          float="right"
          width={{ base: '100%', lg: '70%' }}
          src="/login_background.svg"
        ></Image>
      </Box>
      <Box
        margin="0px"
        padding="0px"
        width="100%"
        height="100%"
        backgroundColor="#E8F4F4"
        paddingTop="100px"
      >
        <Box
          position="relative"
          marginLeft={{ base: 'calc( 50% - 175px )', lg: '200px' }}
          width="350px"
        >
          <Text
            fontWeight="900"
            fontSize="26px"
            marginLeft="20px"
            color="white"
            backgroundColor="#349FA1"
            padding="5px"
            transform="skew(-0.00turn, -5deg)"
            width="fit-content"
          >
            RÉCUPÉRATION DE
          </Text>

          <Text
            fontWeight="900"
            fontSize="26px"
            marginLeft="80px"
            marginTop="5px"
            color="white"
            backgroundColor="#F8B632"
            padding="5px"
            transform="skew(-0.00turn, -5deg)"
            width="fit-content"
          >
            VOS IDENTIFIANTS
          </Text>

          <Box
            marginLeft={'0px'}
            marginTop="40px"
            padding="20px"
            width="350px"
            height="540px"
            zIndex="1"
            backgroundColor="white"
            borderRadius="-webkit-border-radius: 10px; -moz-border-radius: 10px; border-radius: 10px;"
            boxShadow="0px 0px 30px 00px rgba(0,0,0,0.1)"
            textAlign="center"
          >
            <Center marginBottom="10px">
              <Image maxWidth="100%" marginWidth="auto" src={getLogo()}></Image>
            </Center>

            {!codeChecked && (
              <>
                <Text color="teal.900" fontSize="md" fontWeight="bold">
                  Procédure de création ou réinitialisation de mot de passe.
                </Text>
                <Text color="teal.900" fontSize="md" fontWeight="bold">
                  Vérification du lien en cours ...
                </Text>
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </>
            )}
            {codeChecked && codeError && (
              <>
                <Text color="teal.900" fontSize="md" fontWeight="bold">
                  Procédure de création ou réinitialisation de mot de passe.
                </Text>
                <Text color="red" fontSize="md" fontWeight="bold">
                  Le liens a expiré ou n'est plus valide
                </Text>
                <Text as="u" mt={3} onClick={() => history.push('/')} fontSize="md">
                  Retour a la page de connexion
                </Text>
              </>
            )}
            {codeChecked && !codeError && (
              <>
                <Text color="teal.900" fontSize="md" fontWeight="bold">
                  Créer ou réinitialiser votre mot de passe pour le compte :{' '}
                  <b>{accountToRecovery}</b>
                </Text>
                {success && (
                  <Center>
                    <Text mt={5} color="green.500" fontSize="sm" fontWeight="semibold">
                      {success}
                    </Text>
                  </Center>
                )}
                {!success && (
                  <>
                    <Box marginTop="10px">
                      <FormikProvider value={formikPasswordChange}>
                        <FormControl>
                          <InputField
                            name="newPassword"
                            label="Nouveau mots de passe"
                            placeholder="*************"
                            type="password"
                            isRequired
                            forceColumn={true}
                          />
                          <InputField
                            name="newPasswordVerif"
                            label="Vérification mot de passe"
                            placeholder="*************"
                            type="password"
                            isRequired
                            forceColumn={true}
                          />

                          {error && (
                            <Center>
                              <Text mt={5} color="red.500" fontSize="sm" fontWeight="semibold">
                                {error}
                              </Text>
                            </Center>
                          )}

                          <Center>
                            <Button
                              mt={5}
                              isDisabled={
                                !formikPasswordChange.isValid || !formikPasswordChange.dirty
                              }
                              isLoading={formikPasswordChange.isSubmitting}
                              onClick={formikPasswordChange.submitForm}
                            >
                              Définir mon nouveau mot de passe
                            </Button>
                          </Center>
                        </FormControl>
                      </FormikProvider>
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export const PasswordRecoveryRoute = () => {
  return <PasswordRecoveryPage />;
};

import { IconButton as ChakraIconButton, IconButtonProps } from '@chakra-ui/react';

export type Props = {
  colorScheme?: 'teal' | 'gray' | 'orange';
  rounded?: boolean;
} & Omit<IconButtonProps, 'colorScheme' | 'rounded'>;

export const IconButton = ({ colorScheme: c = 'teal', icon: Icon, rounded, ...props }: Props) => {
  const backgroundColor = c === 'teal' ? 'green.500' : c === 'gray' ? 'gray.50' : 'orange.500';

  return (
    <ChakraIconButton
      _focus={{ boxShadow: c }}
      _active={{ backgroundColor }}
      _hover={{ backgroundColor }}
      borderRadius={rounded ? 'full' : 'md'}
      colorScheme={c}
      icon={Icon}
      height={rounded ? 10.5 : 9}
      width={rounded ? 10.5 : 9}
      {...props}
    />
  );
};

import { useEffect, useState } from 'react';
import './search.css';
import { Text, Input, Box } from '@chakra-ui/react';

type City = {
  cc: string;
  cp: string;
  nom: string;
  zone_ptz: string;
};

type props = {
  onSelect: (city: City) => void;
  resetZoneTrigger?: number;
};
export const CitySearch = ({ onSelect, resetZoneTrigger }: props) => {
  const [value, setValue] = useState<string>('');
  const [cities, setCities] = useState<City[]>([]);

  function getVille(query: string) {
    fetch(`/app/me2/commune/rech?nb=15&q=${query}`, {
      method: 'GET',
    }).then((res) =>
      res.json().then((result) => {
        const cityResult: City[] = result as City[];
        setCities(cityResult);
      }),
    );
  }

  useEffect(() => {
    console.log('reset zone pte string ville');
    setValue('');
    setCities([]);
  }, [resetZoneTrigger]);

  return (
    <>
      <Input
        placeholder={'Cherchez une ville'}
        value={value}
        onChange={(e) => {
          getVille(e.target.value);
          setValue(e.target.value);
        }}
      />
      {cities.map((city: City) => {
        return (
          <Box
            ml={'15px'}
            onClick={(e) => {
              setValue(city.nom);
              setCities([]);
              onSelect(city);
            }}
          >
            <Text as="span" fontSize={'11px'}>
              {city.nom} ({city.cp} - {city.zone_ptz})
            </Text>
          </Box>
        );
      })}
    </>
  );
};

//https://www.monemprunt.com/app/me2/commune/rech?nb=15&q=tre

import { connectHits } from 'react-instantsearch-dom';
import { Box, HStack, Text, VStack, useTheme } from '@chakra-ui/react';
import { FilePanel, COLUMN_WIDTHS } from 'components/FilePanel';

export const InstantSearchAfficheurResultat = () => {
  const { colors, fontSizes, sizes } = useTheme();
  const afficheurResultat = ({ hits }: any) => (
    <>
      {hits.map((hit: any) => (
        <FilePanel key={hit.objectID} file={hit} />
      ))}
    </>
  );
  const AfficheurResultatComponent = connectHits(afficheurResultat);

  return (
    <>
      <Box as="section">
        <HStack
          as="ul"
          backgroundColor="gray.50"
          boxShadow={{ lg: `20px 0 0 0 ${colors.gray[50]}, -20px 0 0 0 ${colors.gray[50]}` }}
          display={{ base: 'none', lg: 'flex' }}
          fontSize="md"
          fontWeight="semibold"
          justifyContent={{ lg: 'space-between' }}
          lineHeight={1}
          padding={5}
          paddingRight={{ base: 5, lg: 3 }}
          position="sticky"
          top={sizes.headerDesktop}
          spacing={{ lg: 5 }}
          css={{ '& li': { display: 'inline-block' } }}
          zIndex={1}
        >
          <Text as="li" width={COLUMN_WIDTHS.CLIENT}>
            Client
          </Text>
          <Text as="li" width={COLUMN_WIDTHS.PROJECT}>
            Projet
          </Text>
          <Text as="li" width={COLUMN_WIDTHS.STATE}>
            Avancement
          </Text>
          <Text as="li" width={COLUMN_WIDTHS.FOLLOW_UP}>
            Suivi
          </Text>
          <Text as="li" width={COLUMN_WIDTHS.ACTIONS}>
            Action
          </Text>
          {/** Forces the text in this cell to overflow */}
          <Box as="li" width={COLUMN_WIDTHS.PARTNER} height={fontSizes.md} position="relative">
            <Text position="absolute" left={0} top={0}>
              Affectation
            </Text>
          </Box>
          <Box width={COLUMN_WIDTHS.MORE}></Box>
        </HStack>
        <VStack mb={6} spacing={4}>
          <AfficheurResultatComponent />
        </VStack>
      </Box>
    </>
  );
};

import { Avatar, Image, Box, BoxProps, Divider, Flex, VStack, Stack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Rating } from 'components/Rating';
import { Tag } from 'components/Tag';
import { TagActionList } from 'components/TagActionList';
import { BIEN_A_VENDRE, ETAT_PROJET, MATURITE_PROJET } from 'utils/labels';
import { idIsMe, DATE_FORMAT } from 'utils/helpers';
import { EtatProjet, MaturiteProjet, ProjetAlgolia } from 'types';
import { MeContext } from 'auth/AuthContextProvider';
import { useContext } from 'react';
import { TimelineConstructor } from 'components/Timeline';
import { EtapeViewerMini } from 'components/EtapeViewerMini';

export const COLUMN_WIDTHS = {
  CLIENT: { lg: '17%' },
  PROJECT: { lg: '17%' },
  BUDGET: { lg: '0px' },
  STATE: { lg: '16%' },
  FOLLOW_UP: { lg: '20%' },
  ACTIONS: { lg: '13%' },
  PARTNER: { lg: '54px' },
  MORE: { lg: '0px' },
};

export type Props = {
  file: ProjetAlgolia;
} & BoxProps;

export const FilePanel = ({ file, ...props }: Props) => {
  const me = useContext(MeContext);

  const id_projet = file.objectID;
  const id = file.id;
  const kind = file.kind;
  const nom = file.nom;
  const prenom = file.prenom;
  let title = `Voir le projet`;
  if (prenom || nom) {
    title += ` de ${prenom ?? ''} ${nom ?? ''}`;
  }

  const mail = file.mail;
  const telephone = file.telephone;
  const score = file.eval;
  const montant = file.montant;
  const maturite_projet: string = MATURITE_PROJET[file.maturite as MaturiteProjet];
  const etat_projet: string = ETAT_PROJET[file.etat as EtatProjet];
  const montant_max_achat = file.capacite_achat_max;
  const code_postal_projet = file.code_postal_localisation_projet;
  const localite_projet = file.nom_localisation_projet;
  const code_postal_client = file.code_postal_localisation_client;
  const localite_client = file.nom_localisation_client;
  const date_derniere_modification = file.date_modification
    ? format(file.date_modification, DATE_FORMAT)
    : '';

  const date_transfert = file.date_transfert ? format(file.date_transfert, DATE_FORMAT) : '';
  const derCom: string = file.dernier_commentaire ? file.dernier_commentaire : '';
  const dernier_commentaire = derCom.length > 100 ? derCom.substring(0, 100) + ' ...' : derCom;
  const bien_a_vendre =
    file.bien_a_vendre === 'VENDRE_AVANT' ||
    file.bien_a_vendre === 'VENDRE_APRES' ||
    file.bien_a_vendre === 'GARDER'
      ? file.bien_a_vendre
      : '';

  const url_image_affectation: string =
    'https://storage.googleapis.com/mec-public/courtier/' + file.affectation + '.jpg';

  if (kind === 'liens') {
    const date_creation = file.date_creation ? format(file.date_creation, DATE_FORMAT) : '';

    return (
      <Stack
        _hover={{ backgroundColor: 'gray.50' }}
        _active={{ backgroundColor: 'gray.100' }}
        backgroundColor="white"
        borderRadius="md"
        boxShadow="xs"
        direction={{ lg: 'row' }}
        display={{ base: 'block', lg: 'flex' }}
        fontSize="md"
        fontWeight="medium"
        justifyContent={{ lg: 'space-between' }}
        padding={5}
        paddingRight={{ base: 5, lg: 3 }}
        spacing={{ lg: 5 }}
        transition="background-color 0.15s"
        w="full"
        {...props}
      >
        {/** Client column */}
        <Flex
          flexDirection={{ lg: 'column' }}
          justifyContent={{ base: 'space-between', lg: 'flex-start' }}
          mb={{ base: montant_max_achat ? 4 : 0, lg: 0 }}
          width={COLUMN_WIDTHS.CLIENT}
        >
          <Box maxW={{ base: '48%', lg: 'none' }}>
            <Text isTruncated color="teal.500" fontSize="xl" lineHeight="18px">
              {prenom} {nom}
            </Text>
          </Box>
          <Flex
            direction={{ base: 'column', lg: 'column-reverse' }}
            maxW={{ base: '48%', lg: 'none' }}
            textAlign={{ base: 'right', lg: 'left' }}
          >
            {telephone && <Text isTruncated>{telephone}</Text>}
            {mail && <Text isTruncated>{mail}</Text>}
          </Flex>
        </Flex>
        <Divider display={{ base: id_projet ? 'block' : 'none', lg: 'none' }} my={5} />
        {/** Project column */}
        <Box width={COLUMN_WIDTHS.PROJECT}>
          Lien {mail ? <b>MAIL</b> : telephone ? <b>SMS</b> : <></>}
        </Box>

        <Divider display={{ base: 'block', lg: 'none' }} my={5} />
        {/** State column */}
        <VStack
          alignItems={{ base: 'center', lg: 'flex-start' }}
          spacing={{ base: 3, lg: 0 }}
          width={COLUMN_WIDTHS.STATE}
        >
          <Text>
            Envoyé le{' '}
            <Text as="span" fontWeight="semibold">
              {date_creation}
            </Text>{' '}
          </Text>
          {!idIsMe(file.id_apporteur, me) && (
            <Text>
              {file.id_apporteur ? (
                <>
                  Apporteur : {file.prenom_apporteur} {file.nom_apporteur}
                </>
              ) : (
                <></>
              )}
            </Text>
          )}
        </VStack>
        {/** Follow-up column - DESKTOP */}
        <Box display={{ base: 'none', lg: 'flex' }} width={COLUMN_WIDTHS.FOLLOW_UP}></Box>
        {/** Actions column - DESKTOP */}
        <Stack
          display={{ base: 'none', lg: 'flex' }}
          alignItems={{ lg: 'flex-start' }}
          spacing={3}
          width={COLUMN_WIDTHS.ACTIONS}
        ></Stack>
        {/** Partenaire column - DESKTOP */}
        <Stack
          display={{ base: 'none', lg: 'flex' }}
          spacing={3}
          mr={5}
          width={COLUMN_WIDTHS.PARTNER}
        ></Stack>
        {/** Three-dots button column - DESKTOP*/}
        <Box display={{ base: 'none', lg: 'flex' }} width={COLUMN_WIDTHS.MORE}></Box>
      </Stack>
    );
  }

  return (
    <Stack
      as={Link}
      to={`/${kind}/${id}`}
      _hover={{ backgroundColor: 'gray.50' }}
      _active={{ backgroundColor: 'gray.100' }}
      backgroundColor="white"
      borderRadius="md"
      boxShadow="xs"
      direction={{ lg: 'row' }}
      display={{ base: 'block', lg: 'flex' }}
      fontSize="md"
      fontWeight="medium"
      justifyContent={{ lg: 'space-between' }}
      padding={5}
      paddingRight={{ base: 5, lg: 3 }}
      spacing={{ lg: 5 }}
      title={title}
      transition="background-color 0.15s"
      w="full"
      {...props}
    >
      {/** Client column */}
      <Flex
        flexDirection={{ lg: 'column' }}
        justifyContent={{ base: 'space-between', lg: 'flex-start' }}
        mb={{ base: montant_max_achat ? 4 : 0, lg: 0 }}
        width={COLUMN_WIDTHS.CLIENT}
      >
        <Box maxW={{ base: '48%', lg: 'none' }}>
          <Text isTruncated color="teal.500" fontSize="xl" lineHeight="18px">
            {prenom} {nom}
          </Text>
          {mail && <Text isTruncated>{mail}</Text>}
        </Box>
        <Flex
          direction={{ base: 'column', lg: 'column-reverse' }}
          maxW={{ base: '48%', lg: 'none' }}
          textAlign={{ base: 'right', lg: 'left' }}
        >
          {localite_client && (
            <Text isTruncated fontSize="sm" lineHeight="18px">
              {localite_client}
            </Text>
          )}
          {code_postal_client && (
            <Text isTruncated fontSize="sm" lineHeight="18px">
              ({code_postal_client})
            </Text>
          )}

          {telephone && <Text isTruncated>{telephone}</Text>}
        </Flex>
      </Flex>
      <Divider display={{ base: id_projet ? 'block' : 'none', lg: 'none' }} my={5} />
      {/** Project column */}
      <Box width={COLUMN_WIDTHS.PROJECT}>
        <Stack
          direction={{ base: 'row', lg: 'column' }}
          alignItems={{ lg: 'flex-start' }}
          spacing={3}
          mb={montant ? 4 : 0}
        >
          {maturite_projet && <Tag colorScheme="teal">{maturite_projet}</Tag>}
          {kind === 'contacts' && (
            <Tag background="orange.100" color="orange.500" fontWeight="bold">
              Fiche contact
            </Tag>
          )}
          {montant && <Tag>Projet : {Number(montant).toLocaleString('fr-FR')} €</Tag>}
          {montant_max_achat && (
            <Stack
              display={{ base: 'none', lg: 'inline-flex' }}
              direction={{ base: 'row', lg: 'column' }}
              alignItems={{ lg: 'flex-start' }}
              spacing={3}
              mb={montant ? 4 : 0}
            >
              <Tag>Max achat : {montant_max_achat.toLocaleString('fr-FR')} € </Tag>
            </Stack>
          )}
          {score && <Rating>{score}</Rating>}
        </Stack>

        <Stack
          direction={{ base: 'row', lg: 'column' }}
          fontSize="sm"
          justifyContent="space-between"
          spacing={{ base: 4, lg: 0 }}
        >
          {localite_projet && (
            <Text isTruncated>
              {localite_projet}
              {code_postal_projet && ` (${code_postal_projet})`}
            </Text>
          )}
          {bien_a_vendre && (
            <Text isTruncated flex={1} color="orange.500" textAlign={{ base: 'right', lg: 'left' }}>
              {BIEN_A_VENDRE[bien_a_vendre]}
            </Text>
          )}
        </Stack>
      </Box>
      <Divider display={{ base: 'block', lg: 'none' }} my={5} />
      {/** State column */}
      <VStack
        alignItems={{ base: 'center', lg: 'flex-start' }}
        spacing={{ base: 3, lg: 0 }}
        width={COLUMN_WIDTHS.STATE}
      >
        <Text>
          {etat_projet ? etat_projet : 'Dernière modification'} le{' '}
          <Text as="span" fontWeight="semibold">
            {date_derniere_modification}
          </Text>
        </Text>

        {kind === 'dossiers' && (
          <>
            {/**<Text mt={3}>Progression : </Text>
             <TimelineConstructor etat={file.etat as EtatProjet} tinyMode={true} /> */}
            <EtapeViewerMini file={file} />
          </>
        )}

        {!idIsMe(file.id_apporteur, me) && (
          <Text>
            {file.id_apporteur ? (
              <>
                Apporteur : {file.prenom_apporteur} {file.nom_apporteur}
              </>
            ) : (
              <></>
            )}
          </Text>
        )}
      </VStack>
      {/** Follow-up column - DESKTOP */}
      <Stack display={{ base: 'none', lg: 'flex' }} width={COLUMN_WIDTHS.FOLLOW_UP}>
        {file.numero_dossier && (
          <Text>
            Numéro de dossier : <b>{file.numero_dossier}</b>
          </Text>
        )}
        {date_transfert && (
          <Text>
            Transféré le : <b>{date_transfert}</b>
          </Text>
        )}
        {dernier_commentaire && <Text>Dernier commentaire : {dernier_commentaire}</Text>}
      </Stack>

      {/** Actions column - DESKTOP */}
      <Stack
        display={{ base: 'none', lg: 'flex' }}
        alignItems={{ lg: 'flex-start' }}
        spacing={3}
        width={COLUMN_WIDTHS.ACTIONS}
      >
        <TagActionList fileAlgolia={file} />
      </Stack>
      {/** Partenaire column - DESKTOP */}
      <Stack
        display={{ base: 'none', lg: 'flex' }}
        spacing={3}
        mr={5}
        width={COLUMN_WIDTHS.PARTNER}
      >
        {file.affectation && file.affectation !== 0 && (
          <Avatar src={url_image_affectation} icon={<Image src="/profil.png" />} size="lg" />
        )}
      </Stack>
      {/** Three-dots button column - DESKTOP*/}
      <Box display={{ base: 'none', lg: 'flex' }} width={COLUMN_WIDTHS.MORE}>
        {/**<Button _focus={{ outline: 'none' }} alignSelf="flex-start" variant="unstyled">
          <VerticalDotsIcon boxSize="24px" color="gray.500" />
        </Button>*/}
      </Box>
    </Stack>
  );
};

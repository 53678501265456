import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Center,
  Avatar,
  Text,
} from '@chakra-ui/react';
import { Courtier } from 'types';
import { Button } from 'components/Button';

export type Props = {
  courtier: Courtier;
} & Omit<ModalProps, 'children'>;

export const CourtierModal = ({ courtier, ...props }: Props) => {
  if (courtier === undefined) {
    return <></>;
  }
  return (
    <Modal {...props} scrollBehavior="inside" size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center>
            <Text my={2} color="teal.900" fontSize="xl" fontWeight="extrabold">
              Mon courtier
            </Text>
          </Center>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign="center" pb={10}>
          <Avatar name={courtier.nom} src={courtier.url_image} mr={4} size="2xl" />
          <Text my={2} color="teal.900" fontSize="xl" fontWeight="medium">
            Mon courtier attitré est :{' '}
          </Text>
          <Text my={2} color="teal.900" fontSize="xl" fontWeight="bold">
            {courtier.prenom} {courtier.nom}
          </Text>

          <Text mt={5} color="teal.900" fontSize="xl" fontWeight="medium">
            Email : {courtier.mail}
            {courtier.telephone && (
              <>
                <br />
                N° de téléphone : {courtier.telephone}
              </>
            )}
            {courtier.telephone_portable && (
              <>
                <br />
                N° de téléphone portable : {courtier.telephone_portable}
              </>
            )}
          </Text>

          {courtier.calendly && (
            <Button
              mt={7}
              onClick={(e) => window.open('https://calendly.com/' + courtier.calendly)}
            >
              Prendre un rendez-vous
            </Button>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

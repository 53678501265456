import { createIcon } from '@chakra-ui/react';

export const UserFrameIcon = createIcon({
  displayName: 'UserFrameIcon',
  viewBox: '0 0 33 32',
  path: (
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M27.125 30a6 6 0 00-6-6h-10a6 6 0 00-6 6m11-10a5 5 0 01-5-5v-3a5 5 0 0110 0v3a5 5 0 01-5 5zm-15-19h30v30h-30V1z"
    />
  ),
});

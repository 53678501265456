import { Redirect, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useContext } from 'react';
import { FileDetailsRoute } from 'components/FileDetails';
import { FileDetailsHeader } from 'components/FileDetailsHeader';
import { FileDetailsSidebarRoute } from 'components/FileDetailsSidebar';
import { Layout } from 'components/Layout';
import { ErrorPage } from 'components/ErrorPage';
import { LoadingPage } from 'components/LoadingPage';
import { Projet, Courtier, Utilisateur } from 'types';
import { dossier1 } from 'utils/storybook';
import { AuthContext, MeContext } from 'auth/AuthContextProvider';

export type Props = {
  file: Projet;
  isDossier: boolean;
  courtier: Courtier;
  utilisateur: Utilisateur;
};

export const FilePage = ({ file, isDossier, courtier, utilisateur }: Props) => {
  const element =
    file.affectation && file.affectation != null && file.affectation !== 0 ? (
      <FileDetailsSidebarRoute id={file.affectation} />
    ) : (
      <></>
    );
  return (
    <Layout courtier={courtier} utilisateur={utilisateur} sidebar={element}>
      <FileDetailsHeader file={file} isDossier={isDossier} mb={{ base: 6, lg: 9 }} />
      <FileDetailsRoute file={file} isDossier={isDossier} />
    </Layout>
  );
};
export type PropsRoute = {
  ressources: string;
};
export const FilePageRoute = ({ ressources }: PropsRoute) => {
  const { id } = useParams<{ id: string }>();
  const { token } = useContext(AuthContext);
  const me = useContext(MeContext);
  let file = dossier1;

  const { isLoading, error, data } = useQuery(
    ['RecuperationDossiersContacts', [id, token, ressources]],
    () =>
      fetch('/API/' + ressources + '/' + id, {
        method: 'GET',
        headers: new Headers({
          Authorization: token,
        }),
      }).then((res) => res.json()),
  );
  if (isLoading) {
    return <LoadingPage courtier={me.courtier!} utilisateur={me.utilisateur!} />;
  }

  if (error) {
    return <ErrorPage courtier={me.courtier!} utilisateur={me.utilisateur!} />;
  }

  if (data) {
    // recupération de tout les champs
    file = data;
    // adaptation des champs spécifique
    file.date_transfert_traitement = data.date_transfert_traitement
      ? new Date(data.date_transfert_traitement)
      : undefined;
    file.date_creation = new Date(data.date_creation);
    file.capacite_emprunt_max = data.capacite_emprunt_max ? data.capacite_emprunt_max / 100 : 0;
    file.capacite_achat_max = data.capacite_achat_max ? data.capacite_achat_max / 100 : 0;
    file.apport = data.apport ? data.apport / 100 : 0;
    file.date_modification = new Date(data.date_modification);
    file.date_creation_fiche_contact =
      null != data.date_creation_fiche_contact
        ? new Date(data.date_creation_fiche_contact)
        : undefined;
    file.date_transfert = null != data.date_transfert ? new Date(data.date_transfert) : undefined;
  }

  return file ? (
    <FilePage
      file={file}
      isDossier={ressources === 'dossiers'}
      courtier={me.courtier!}
      utilisateur={me.utilisateur!}
    />
  ) : (
    <Redirect to="/" />
  );
};

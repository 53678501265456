import {
  Radio as ChakraRadio,
  RadioProps,
  RadioGroup as ChakraRadioGroup,
  RadioGroupProps,
} from '@chakra-ui/react';

export type Props = RadioProps;
export const Radio = (props: Props) => <ChakraRadio colorScheme="teal" {...props} />;

export type GroupProps = RadioGroupProps;
export const RadioGroup = (props: GroupProps) => {
  return <ChakraRadioGroup {...props} />;
};

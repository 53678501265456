import { CSSObject } from '@chakra-ui/react';
import { GlobalStyleProps } from '@chakra-ui/theme-tools';

export const getStyles = (_: GlobalStyleProps): CSSObject => ({
  '.swiper-pagination-bullet': {
    width: '10px',
    height: '6px',
    borderRadius: 'sm',
    transition: 'width 100ms linear',
    backgroundColor: 'teal.900',
  },

  '.swiper-pagination-bullet-active': {
    backgroundColor: 'teal.900',
    width: 6,
  },
});

import {
  Box,
  BoxProps,
  Flex,
  HStack,
  List,
  ListItem,
  Text,
  TextProps,
  forwardRef,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { format } from 'date-fns';
import { useQuery } from 'react-query';
import { useContext } from 'react';
import { EtapeViewer } from 'components/EtapeViewer';
import { AuthContext, MeContext } from 'auth/AuthContextProvider';
import { Button } from 'components/Button';
import { NewCommentModal } from 'components/NewCommentModal';
import { Panel } from 'components/Panel';
import { Rating } from 'components/Rating';
import { Tag } from 'components/Tag';
import { TagActionList } from 'components/TagActionList';
import { Commentaire, Projet, EtatProjet, MaturiteProjet, UsageProjet } from 'types';
import { ETAT_PROJET, MATURITE_PROJET, USAGE_PROJET } from 'utils/labels';
import { idIsMe, DATE_FORMAT, DATETIME_FORMAT } from 'utils/helpers';

export type CommentairesProps = {
  isLoading: boolean;
  error: boolean;
  listCommentaires: Commentaire[];
};

export type Props = {
  file: Projet;
  isDossier: boolean;
  commentaires: CommentairesProps;
  forceRefetchCommentaire: () => void;
} & BoxProps;

export const FileDetails = ({
  file,
  isDossier,
  commentaires,
  forceRefetchCommentaire,
  ...props
}: Props) => {
  const me = useContext(MeContext);

  const { token } = useContext(AuthContext);

  const stateCommentaire = useDisclosure();

  // Callbacks
  const onDoneAddingComment = useCallback(() => {
    stateCommentaire.onClose();
    forceRefetchCommentaire();
  }, [stateCommentaire.onClose]);

  const projet_date_creation =
    file && file.date_creation ? format(file.date_creation, DATETIME_FORMAT) : '';

  const projet_date_derniere_modification =
    file && file.date_modification ? format(file.date_modification, DATETIME_FORMAT) : '';

  const projet_date_creation_fiche_contact =
    file && file.date_creation_fiche_contact
      ? format(file.date_creation_fiche_contact, DATETIME_FORMAT)
      : '';

  const projet_date_transfert_traitement =
    file && file.date_transfert_traitement
      ? format(file.date_transfert_traitement, DATETIME_FORMAT)
      : '';

  return (
    <>
      {isDossier && (
        <>
          <Flex as="section" mt={'20px'} flexDirection={{ base: 'column', lg: 'row' }} {...props}>
            {/** Follow-up panel */}
            <Panel
              as={Flex}
              flex={{ base: 1, lg: 1 }}
              px={{ base: 4, xxl: 6 }}
              py={{ base: 4, xxl: 6 }}
              flexDirection={{ base: 'column', lg: 'row' }}
            >
              <EtapeViewer etapes={file.etapes} />
            </Panel>
          </Flex>
        </>
      )}

      <Flex as="section" mt={'20px'} flexDirection={{ base: 'column', lg: 'row' }} {...props}>
        {/** Client panel */}
        <Panel flex={{ base: 1, lg: 3 }} px={{ base: 6, xxl: 10 }} py={{ base: 6, xxl: 8 }}>
          <Text as="h3" fontSize={{ base: 'xl', xxl: '2xl' }} fontWeight="semibold" mb={6}>
            Profil client
          </Text>
          <Box as="dl">
            <DT>Nom</DT>
            <DD>{file.prenom || file.nom ? `${file.prenom} ${file.nom}`.trim() : 'NC'}</DD>
            {file.mail && (
              <>
                <DT>Adresse email</DT>
                <DD>{file.mail}</DD>
              </>
            )}
            {file.telephone && (
              <>
                <DT>Téléphone</DT>
                <DD>{file.telephone}</DD>
              </>
            )}
            <DT>Localisation</DT>
            <DD mb={0}>
              {file.nom_localisation}{' '}
              {file.code_postal_localisation ? ` (${file.code_postal_localisation})` : ''}
            </DD>
          </Box>
          <HStack spacing={6} mt={6}>
            <Box>
              <DT>Max. empruntable</DT>
              <DD mb={0}>
                <Tag>
                  {file.capacite_emprunt_max
                    ? `${file.capacite_emprunt_max.toLocaleString('fr-FR')} €`
                    : 'NC'}
                </Tag>
              </DD>
            </Box>
            <Box>
              <DT>Apport</DT>
              <DD mb={0}>
                <Tag>{file.apport ? `${file.apport.toLocaleString('fr-FR')} €` : 'NC'}</Tag>
              </DD>
            </Box>
            <Box>
              <DT>Max. achat</DT>
              <DD mb={0}>
                <Tag>
                  {file.capacite_achat_max
                    ? `${file.capacite_achat_max.toLocaleString('fr-FR')} €`
                    : 'NC'}
                </Tag>
              </DD>
            </Box>
          </HStack>
        </Panel>
        {/** Project panel */}
        <Panel
          flex={{ base: 1, lg: 3 }}
          mx={{ lg: 6 }}
          my={{ base: 6, lg: 0 }}
          px={{ base: 6, xxl: 10 }}
          py={{ base: 6, xxl: 8 }}
        >
          <Text as="h3" fontSize={{ base: 'xl', xxl: '2xl' }} fontWeight="semibold" mb={6}>
            Projet
          </Text>
          <HStack spacing={3} mb={6}>
            {file.maturite && (
              <Tag colorScheme="teal">{MATURITE_PROJET[file.maturite as MaturiteProjet]}</Tag>
            )}
            {file.montant && <Tag>{file.montant.toLocaleString('fr-FR')} €</Tag>}
            {file.eval && <Rating height={7}>{file.eval}</Rating>}
          </HStack>
          <Box as="dl">
            {file.usage && (
              <>
                <DT>Usage</DT>
                <DD>{USAGE_PROJET[file.usage as UsageProjet]}</DD>
              </>
            )}
            {projet_date_creation_fiche_contact && (
              <>
                <DT>Date fiche contact</DT>
                <DD>{projet_date_creation_fiche_contact}</DD>
              </>
            )}

            <DT>Date création</DT>
            <DD>{projet_date_creation}</DD>
            <DT>Numéro de dossier</DT>
            <DD>{file.numero_dossier}</DD>
          </Box>
        </Panel>
        {/** Follow-up panel */}
        <Panel as={Flex} flex={{ base: 1, lg: 3 }} flexDirection={{ base: 'column', lg: 'row' }}>
          <Box
            borderColor="gray.100"
            borderBottomWidth={{ base: 1, lg: 0 }}
            borderRightWidth={{ base: 0, lg: 1 }}
            flex={{ base: 1, lg: 42 }}
            pl={{ base: 6, xxl: 10 }}
            pr={{ base: 6, xxl: 8 }}
            py={{ base: 6, xxl: 8 }}
            pt={{ base: 6, xxl: 7 }}
          >
            <Text as="h3" fontSize={{ base: 'xl', xxl: '2xl' }} fontWeight="semibold" mb={6}>
              Suivi du dossier
            </Text>
            <Box as="dl">
              {file.organisation && file.organisation.nom_region && (
                <>
                  <DT>Région</DT>
                  <DD>{file.organisation.nom_region}</DD>
                </>
              )}
              {file.organisation && file.organisation.nom_agence && (
                <>
                  <DT>Agence</DT>
                  <DD>{file.organisation.nom_agence}</DD>
                </>
              )}
              {file.apporteur && !idIsMe(file.apporteur.id, me) && (
                <>
                  <DT>Apporteur</DT>
                  <DD>
                    {file.apporteur.prenom} {file.apporteur.nom}
                  </DD>
                </>
              )}
              <DT>Avancement</DT>
              <DD>{ETAT_PROJET[file.etat as EtatProjet]}</DD>
              <DT>Dernière modification</DT>
              <DD>{projet_date_derniere_modification}</DD>
              {projet_date_transfert_traitement && (
                <>
                  <DT>Date de transfert pour traitement</DT>
                  <DD>{projet_date_transfert_traitement}</DD>
                </>
              )}
              <DT>Action</DT>
              <DD mb={0}>
                <TagActionList file={file} />
              </DD>
            </Box>
          </Box>
        </Panel>
      </Flex>
      <Flex as="section" mt={'20px'} flexDirection={{ base: 'column', lg: 'row' }} {...props}>
        {/** Follow-up panel */}
        <Panel
          as={Flex}
          flex={{ base: 1, lg: 1 }}
          px={{ base: 6, xxl: 10 }}
          py={{ base: 6, xxl: 8 }}
          flexDirection={{ base: 'column', lg: 'row' }}
        >
          <Box
            flex={{ base: 1, lg: 58 }}
            pl={{ base: 6, xxl: 8 }}
            pr={{ base: 6, xxl: 10 }}
            pb={{ base: 6, xxl: 5 }}
            pt={{ base: 6, xxl: 7 }}
          >
            <Flex
              as="header"
              alignItems={{ base: 'center', lg: 'flex-start' }}
              justifyContent="space-between"
              mb={5}
            >
              <Text as="h3" fontSize={{ base: 'xl', xxl: '2xl' }} fontWeight="semibold">
                Commentaires
              </Text>
              <Button
                onClick={stateCommentaire.onOpen}
                minHeight={8}
                paddingLeft={4}
                paddingRight={4}
                children={<>Ajouter un commentaire</>}
              />
            </Flex>
            {commentaires.isLoading ? (
              <>
                <Text fontSize="sm">Chargement en cours ...</Text>
              </>
            ) : commentaires.error ? (
              <Text color="red.500" fontSize="sm">
                ⚠️ Erreur de chargement des commentaires.
              </Text>
            ) : commentaires.listCommentaires && commentaires.listCommentaires.length > 0 ? (
              <List maxH={{ lg: '275px' }} overflowY={{ lg: 'scroll' }}>
                {commentaires.listCommentaires.map((comment) => (
                  <Comment
                    key={comment.id}
                    as={ListItem}
                    comment={comment}
                    pb={6}
                    _first={{ pt: 0 }}
                    _notLast={{ borderBottomWidth: 1, borderBottomColor: 'gray.100' }}
                  />
                ))}
              </List>
            ) : (
              <Text fontSize="sm">Aucun commentaire.</Text>
            )}
          </Box>
        </Panel>
      </Flex>
      {/** New Comment Modal */}
      <NewCommentModal
        idProjet={file.id}
        isDossier={isDossier}
        isOpen={stateCommentaire.isOpen}
        onClose={stateCommentaire.onClose}
        onDone={onDoneAddingComment}
      />
    </>
  );
};

export type PropsRoute = {
  file: Projet;
  isDossier: boolean;
} & BoxProps;

export const FileDetailsRoute = ({ file, isDossier, ...props }: PropsRoute) => {
  const [refetchTime, setRefetchTime] = useState<number>(0);
  const refetch = () => setRefetchTime(Date.now());

  const { token } = useContext(AuthContext);
  const id = file.id;
  const { isLoading, error, data } = useQuery(
    ['RecuperationCommentaires', [id, token, isDossier, refetchTime]],
    () =>
      fetch('/API/commentaires/' + (isDossier ? 'dossier' : 'contact') + '/' + id, {
        method: 'GET',
        headers: new Headers({
          Authorization: token,
        }),
      }).then((res) => res.json()),
  );

  if (isLoading) {
    return (
      <FileDetails
        file={file}
        isDossier={isDossier}
        commentaires={{ error: false, isLoading: true, listCommentaires: [] }}
        forceRefetchCommentaire={refetch}
      />
    );
  }
  if (error) {
    return (
      <FileDetails
        file={file}
        isDossier={isDossier}
        commentaires={{ error: true, isLoading: false, listCommentaires: [] }}
        forceRefetchCommentaire={refetch}
      />
    );
  }
  if (data) {
    const coms: Commentaire[] = [];
    data.forEach((commentaire: any) => {
      coms.push({
        date_creation: new Date(commentaire.date_creation),
        detail: commentaire.detail,
        id: commentaire.id,
        nom_utilisateur: commentaire.nom_utilisateur,
        prenom_utilisateur: commentaire.prenom_utilisateur,
        type: commentaire.type,
      });
    });

    return (
      <FileDetails
        file={file}
        isDossier={isDossier}
        commentaires={{
          error: false,
          isLoading: false,
          listCommentaires: coms,
        }}
        forceRefetchCommentaire={refetch}
      />
    );
  }
  return (
    <FileDetails
      file={file}
      isDossier={isDossier}
      commentaires={{
        error: true,
        isLoading: false,
        listCommentaires: [],
      }}
      forceRefetchCommentaire={refetch}
    />
  );
};

// Description Term - Used internally above
const DT = forwardRef<TextProps, 'dt'>(({ children, ...props }, ref) => (
  <Text
    as="dt"
    color="teal.500"
    fontWeight="bold"
    fontSize="sm"
    lineHeight={1}
    mb={3}
    ref={ref}
    {...props}
  >
    {children}
  </Text>
));

// Description Details - Used internally above
const DD = forwardRef<TextProps, 'dd'>(({ children = 'NC', ...props }, ref) => (
  <Text as="dd" fontSize="sm" fontWeight="medium" mb={6} ref={ref} {...props}>
    {children}
  </Text>
));

// Comment - Used internally above
type CommentProps = { comment: Commentaire } & BoxProps;
const Comment = forwardRef<CommentProps, 'div'>(({ comment, ...props }, ref) => (
  <Box fontSize="sm" py={6} ref={ref} {...props}>
    <HStack mb={3} spacing={5}>
      <Text as="time" dateTime={format(new Date(), 'yyyy-MM-dd')} color="gray.500">
        {/* ici la date  {format(comment.date, 'yyyy-MM-dd')}*/}
        {format(comment.date_creation, DATE_FORMAT)}
        {/* ici la date tel {format(comment.date, DATE_FORMAT)} */}
      </Text>
      <Text color="teal.500">
        {comment.prenom_utilisateur} {comment.nom_utilisateur}
      </Text>
      {/* ici l'auteur tel que {comment.auteur} */}
    </HStack>
    <Text fontWeight="medium">{comment.detail}</Text>
  </Box>
));

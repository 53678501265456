import { useState, useContext } from 'react';
import { AuthContext, MeContext } from 'auth/AuthContextProvider';
import { Field, FieldProps, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Button } from 'components/Button';
import { ArrowLeftIcon } from 'components/Icons';
import { TabsPanel } from 'components/TabsPanel';
import { spaceRegExp, phoneRegExp, presenceLienUrlRegExp } from 'utils/helpers';
import { InputField, TextareaField } from 'utils/form';
import { LabelToast } from 'utils/labels';

const validationSchemaSMS = yup.object().shape({
  content: yup
    .string()
    .required('Contenu du SMS requis')
    .max(400, 'Contenu du SMS trop long (maximum 400 caractères)')
    .matches(
      presenceLienUrlRegExp,
      'Le contenu du SMS doit contenir le texte "[LIEN_URL]" pour pouvoir étre envoyé',
    ),
  nom_destinataire: yup.string(),
  ref_partenaire: yup.string(),
  destinataire: yup
    .string()
    .matches(spaceRegExp, "Le numéro ne doit pas contenir d'espaces")
    .matches(
      phoneRegExp,
      'Numéro de téléphone invalide, il doit commencer par 06 ou 07. (seuls les numéros français, DOMTOM et Suisse sont pris en charge) ',
    )
    .required('Numéro de télphone requis'),
});
const validationSchemaMAIL = yup.object().shape({
  content: yup
    .string()
    .required('Contenu du mail requis')
    .matches(
      presenceLienUrlRegExp,
      'Le contenu du mail doit contenir le texte "[LIEN_URL]" pour pouvoir étre envoyé',
    ),
  objet: yup.string().required('Object du mail requis'),
  nom_destinataire: yup.string(),
  ref_partenaire: yup.string(),
  destinataire: yup
    .string()
    .matches(spaceRegExp, "Le mail ne doit pas contenir d'espaces")
    .email('Format du mail invalide')

    .required('Mail requis'),
});

export type Values = {
  content: string;
  nom_destinataire?: string;
  objet?: string;
  ref_partenaire?: string;
  destinataire: string;
};

export type Props = {
  onBack?: () => void;
  onDone: () => void;
} & Omit<ModalProps, 'children'>;

export const SendLinkModal = ({ onBack, onDone, ...props }: Props) => {
  const toast = useToast();
  const [activeTab, setActiveTab] = useState(0);
  const { token } = useContext(AuthContext);
  const me = useContext(MeContext);

  const nom_immo_string = me.utilisateur?.organisation?.nom_immo;
  let validationSchema = validationSchemaSMS;
  // Form declaration
  const formikSMS = useFormik({
    validationSchema,
    initialValues: {
      content:
        (nom_immo_string ? '[' + nom_immo_string + '] ' : '') +
        'Vérifiez votre budget et les conditions de taux en quelques clics pour mieux définir votre projet immobilier. Cliquez sur ce lien [LIEN_URL]. ' +
        me?.utilisateur?.prenom +
        ' ' +
        me?.utilisateur?.nom +
        '.',
      ref_partenaire: undefined,
      nom_destinataire: '',
      destinataire: '',
    },
    onSubmit: (values: Values, actions) => {
      const contenu_sms = values.content.replace(
        '[LIEN_URL]',
        ' ' +
          me?.marque?.url_tunnel +
          '?apcode=' +
          me?.utilisateur?.apporteur_code +
          (values.ref_partenaire ? '&ref_part=' + values.ref_partenaire : '') +
          ' ',
      );
      const creationDeLienSMS = {
        destinataire: values.destinataire,
        message: contenu_sms,
        nom: values.nom_destinataire,
      };
      console.log('ENVOI DE LIEN PAR SMS');
      fetch('/API/lien-sms', {
        method: 'POST',
        body: JSON.stringify(creationDeLienSMS),
        headers: new Headers({
          Authorization: token,
          'Content-Type': 'application/json',
        }),
      })
        .then((res) => {
          if (res.status === 200) {
            actions.setSubmitting(false);
            actions.resetForm();
            toast({
              title: LabelToast.SUCCESS_LIEN_SMS,
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
            onDone();
          } else {
            toast({
              title: 'Erreur (code : ' + res.status + ')',
              description: LabelToast.FAIL_LIEN_SMS + res.statusText,
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
            actions.setSubmitting(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          actions.setSubmitting(false);
        });
    },
  });

  validationSchema = validationSchemaMAIL;
  const formikMAIL = useFormik({
    validationSchema,
    initialValues: {
      objet:
        (nom_immo_string ? '[' + nom_immo_string + '] ' : '') +
        'Vérifiez votre budget et vos conditions de taux en quelques clics',
      ref_partenaire: undefined,
      content:
        `Bonjour,\n Je vous invite à réaliser une évaluation précise de votre capacité d’emprunt ainsi que les conditions de taux que vous pouvez obtenir sur notre simulateur d’emprunt.\n\n` +
        (nom_immo_string ? `Ce mail vous est envoyé par ` + nom_immo_string + `.\n\n` : '') +
        `[LIEN_URL]\n\n` +
        `Cette étude ne vous prendra que 3mn en cliquant sur ce lien et vous fera gagner un temps précieux pour bien définir votre budget.\n\n` +
        `Dans l'attente et vous en souhaitant bonne réception,\n` +
        `Très bonne journée\n` +
        me?.utilisateur?.prenom +
        ' ' +
        me?.utilisateur?.nom,
      nom_destinataire: '',
      destinataire: '',
    },
    onSubmit: (values: Values, actions) => {
      const contenu_mail = values.content
        .replace(
          '[LIEN_URL]',
          ' <a href="' +
            me?.marque?.url_tunnel +
            '?apcode=' +
            me?.utilisateur?.apporteur_code +
            (values.ref_partenaire ? '&ref_part=' + values.ref_partenaire : '') +
            '">ACCÉDER À LA SIMULATION</a>',
        )
        .replaceAll('\n', '<br>');

      const creationDeLienMail = {
        destinataire: values.destinataire,
        corps: contenu_mail,
        objet: values.objet,
        nom: values.nom_destinataire,
      };
      console.log('ENVOI DE LIEN PAR MAIL');
      fetch('/API/lien-mail', {
        method: 'POST',
        body: JSON.stringify(creationDeLienMail),
        headers: new Headers({
          Authorization: token,
          'Content-Type': 'application/json',
        }),
      })
        .then((res) => {
          if (res.status === 200) {
            actions.setSubmitting(false);
            actions.resetForm();
            toast({
              title: LabelToast.SUCCESS_LIEN_MAIL,
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
            onDone();
          } else {
            toast({
              title: 'Erreur (code : ' + res.status + ')',
              description: LabelToast.FAIL_LIEN_MAIL + res.statusText,
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
            actions.setSubmitting(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          actions.setSubmitting(false);
        });
    },
  });
  // Tabs to use
  const tabs = [
    {
      title: 'Par SMS',
      content: <SendLinkForm activeTab={activeTab} />,
      isDisabled: formikSMS.isSubmitting,
    },
    {
      title: 'Par email',
      content: <SendLinkForm activeTab={activeTab} />,
      isDisabled: formikMAIL.isSubmitting,
    },
  ];

  const formik = activeTab === 0 ? formikSMS : formikMAIL;

  return (
    <FormikProvider value={formik}>
      <Modal {...props} scrollBehavior="inside" size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack alignItems="center" spacing={6}>
              <Button disabled={formik.isSubmitting} onClick={onBack} variant="unstyled">
                <ArrowLeftIcon boxSize="18px" color="gray.200" />
              </Button>
              <Text as="h3" pt="3px">
                Envoi d'un lien pour simulation
              </Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton disabled={formik.isSubmitting} />
          <ModalBody>
            <form onSubmit={formik.handleSubmit}>
              <TabsPanel
                defaultIndex={activeTab}
                onChange={setActiveTab}
                tabs={tabs}
                variant="line"
              />
            </form>
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Text mr={5} color="red" fontSize={'sm'}>
              Les champs comportant une étoile (*) sont obligatoires
            </Text>
            <Button
              isDisabled={!formik.isValid || !formik.dirty}
              isLoading={formik.isSubmitting}
              onClick={formik.submitForm}
            >
              Envoyer par {activeTab === 0 ? 'SMS' : 'email'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormikProvider>
  );
};

const SendLinkForm = ({ activeTab }: { activeTab: number }) => {
  const contentLabel = activeTab === 0 ? 'Texte du SMS' : "Texte de l'email";

  return (
    <VStack py={8} spacing={6}>
      {activeTab === 1 ? (
        <InputField name="objet" label="Objet de l'email" placeholder="Objet: Mail de Simulation" />
      ) : null}

      {/** Textarea: Content field */}
      <Field name="content">
        {({ field }: FieldProps) => (
          <>
            <TextareaField name="content" label={contentLabel} isRequired={true} />
            <Text as="span" color="teal.900" fontSize="sm" fontWeight="normal">
              L'élément "
              <Text as="span" fontWeight="bold">
                [LIEN_URL]
              </Text>
              ” doit étre présent et sera remplacé par une URL qui vous est dédiée lors de l'envoi.
            </Text>
          </>
        )}
      </Field>
      {/** Input: destinataire name field */}

      <InputField
        name="nom_destinataire"
        label="Nom du destinataire"
        placeholder="Nom du destinataire"
      />

      {/** Input: Project number field */}

      <InputField
        name="ref_partenaire"
        label="Référence interne du projet"
        placeholder="Ex: ABC123"
      />

      {/** Input: Destination field */}

      <InputField
        name="destinataire"
        label={activeTab === 0 ? 'Tél. portable' : 'Adresse email'}
        placeholder={
          activeTab === 0
            ? 'Numéro de téléphone mobile du client à évaluer'
            : 'Adresse email du client à évaluer'
        }
        isRequired
      />
    </VStack>
  );
};

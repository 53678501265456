import {
  Box,
  ComponentWithAs,
  forwardRef,
  IconProps,
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Text,
  useTheme,
} from '@chakra-ui/react';

export type Props = {
  icon?: ComponentWithAs<'svg', IconProps>;
  iconColor?: string;
  iconPosition?: 'left' | 'right';
  info?: string;
} & InputProps;

export const Input = forwardRef<Props, 'input'>(
  (
    {
      backgroundColor = 'white',
      borderRadius = 'sm',
      icon: Icon,
      iconColor = 'gray.200',
      iconPosition = 'right',
      info,
      ...props
    },
    ref,
  ) => {
    const { space } = useTheme();
    const pl = [`calc(${space[9]} + ${space[1]})`, null, `calc(${space[11]} + ${space[1]})`];

    return (
      <InputGroup>
        <ChakraInput
          backgroundColor={backgroundColor}
          borderRadius={borderRadius}
          height="full"
          pl={iconPosition === 'left' ? pl : undefined}
          ref={ref}
          {...props}
        />
        {(Icon || info) && (
          <Box
            as={iconPosition === 'left' ? InputLeftElement : InputRightElement}
            alignItems="center"
            borderRadius={borderRadius}
            backgroundColor={backgroundColor}
            display="flex"
            height="calc(100% - 2px)"
            left={iconPosition === 'left' ? 1 : undefined}
            pl={iconPosition === 'left' ? [4, null, 6] : 4}
            pointerEvents="none"
            pr={4}
            right={iconPosition === 'right' ? 1 : undefined}
            top={1}
            width="auto"
            zIndex={1}
          >
            {Icon ? (
              <Icon color={iconColor} boxSize={5} />
            ) : (
              <Text as="span" color={iconColor} fontSize="sm">
                {info}
              </Text>
            )}
          </Box>
        )}
      </InputGroup>
    );
  },
);

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Center,
  Text,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import { Button, ColorScheme } from 'components/Button';
import * as React from 'react';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  mentions: string;
};

export function ErrorAuthentModal(props: Props) {
  return (
    <Modal {...props} scrollBehavior="inside" size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center>
            <Text my={2} color="teal.900" fontSize="xl" fontWeight="extrabold">
              Erreur
            </Text>
          </Center>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign="center" pb={10}>
          <Box>{props.mentions}</Box>

          <Center mt={7}>
            <Box>
              <Button onClick={props.onClose}>Fermer</Button>
            </Box>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

import { createIcon } from '@chakra-ui/react';

export const MessageIcon = createIcon({
  displayName: 'MessageIcon',
  viewBox: '0 0 16 17',
  path: (
    <path
      fill="currentColor"
      d="M8 .5c-4.4 0-8 3.1-8 7s3.6 7 8 7c.4 0 .8 0 1.1-.1l4.9 2.1v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7z"
    />
  ),
});

import { Box, Text } from '@chakra-ui/react';
import { InstantSearchSelecteurFiltresUnitaire } from 'components/InstantSearchSelecteurFiltresUnitaire';
import { DateRangePicker } from 'components/DateRangePicker';
import { Panel } from 'components/Panel';
import { useContext } from 'react';
import { MeContext } from 'auth/AuthContextProvider';
import { roleCodePeuxVoirPerimetre } from 'utils/helpers';
import { datePickerProps } from 'components/DateRangePicker/lib/defaultProps';

export const InstantSearchSelecteurFiltresDesktop = () => {
  const me = useContext(MeContext);

  return (
    <Box display={{ base: 'none', xl: 'block' }}>
      <Panel
        as="section"
        width={{
          base: '100%',
          md: '260px',
        }}
        float={'left'}
        margin={{ base: '5px' }}
      >
        <Text as="h3" fontWeight="bold" fontSize="2xl" px={4} py={4}>
          Filtres
        </Text>
        <InstantSearchSelecteurFiltresUnitaire titre="Interaction" attribute="kind" />
        <InstantSearchSelecteurFiltresUnitaire titre="Maturité" attribute="maturite" />
        <InstantSearchSelecteurFiltresUnitaire titre="Usage projet" attribute="usage" />
        <InstantSearchSelecteurFiltresUnitaire titre="Type projet" attribute="type" />
        <InstantSearchSelecteurFiltresUnitaire titre="Bien a vendre" attribute="bien_a_vendre" />
        <DateRangePicker
          attribute="date_creation"
          datePickerProps={datePickerProps}
          titre="Date de création"
        />
        <InstantSearchSelecteurFiltresUnitaire
          showMore={true}
          titre="Étape"
          attribute="code_derniere_etape"
        />
      </Panel>

      {me.utilisateur?.role && roleCodePeuxVoirPerimetre(me.utilisateur?.role) && (
        <Panel
          as="section"
          width={{
            base: '100%',
            md: '260px',
          }}
          float={'left'}
          margin={{ base: '5px' }}
        >
          <Text as="h3" fontWeight="bold" fontSize="2xl" px={4} py={4}>
            Périmètres
          </Text>
          <InstantSearchSelecteurFiltresUnitaire
            showMore={true}
            titre="Région"
            attribute="libelle_region"
          />
          <InstantSearchSelecteurFiltresUnitaire
            showMore={true}
            titre="Agence"
            attribute="libelle_agence"
          />
          <InstantSearchSelecteurFiltresUnitaire
            search={true}
            showMore={true}
            titre="Apporteur"
            attribute="nom_apporteur"
          />
        </Panel>
      )}
    </Box>
  );
};

import { DateUtils } from 'react-day-picker';

export const DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const LABELS = {
  selectDate: 'Sélectionner une date',
  previousMonth: 'Mois précédent',
  nextMonth: 'Mois suivant',
  lastOneMonths: '30 derniers jours',
  lastTwoMonths: '2 derniers mois',
  lastSixMonths: '6 derniers mois',
  thisYear: 'Cette année',
  lastYear: "L'année dernière",
  all: 'Voir tout',
};

export const MONTHS = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];

export const WEEKDAYS_LONG = [
  'Dimanche',
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
];

export const WEEKDAYS_SHORT = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];

export const isSelectingFirstDay = (day: Date, from?: Date, to?: Date) => {
  const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
  const isRangeSelected = from && to;
  return !from || isBeforeFirstDay || isRangeSelected;
};

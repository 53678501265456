import { createIcon } from '@chakra-ui/react';

export const FolderWithCheckmarkIcon = createIcon({
  displayName: 'FolderWithCheckmarkIcon',
  viewBox: '0 0 20 20',
  path: (
    <>
      <path
        clipRule="evenodd"
        fill="currentColor"
        fillRule="evenodd"
        d="M14.874 7.626a1 1 0 010 1.414L9.04 14.874a1 1 0 01-1.414 0l-2.5-2.5a1 1 0 111.414-1.415l1.793 1.793 5.127-5.126a1 1 0 011.414 0z"
      />
      <path
        clipRule="evenodd"
        fill="currentColor"
        fillRule="evenodd"
        d="M-.167 1.667a1 1 0 011-1H7.5a1 1 0 01.8.4L10.5 4h8.667a1 1 0 011 1v11.667a2.666 2.666 0 01-2.667 2.666h-15a2.667 2.667 0 01-2.667-2.666v-15zm2 1v14a.667.667 0 00.667.666h15a.666.666 0 00.667-.666V6H10a1 1 0 01-.8-.4L7 2.667H1.833z"
      />
    </>
  ),
});

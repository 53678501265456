import { connectRefinementList } from 'react-instantsearch-dom';
import { Box, Text, Checkbox } from '@chakra-ui/react';
import { getLabelFromFacet } from 'utils/helpers';
import { Input } from 'components/Input';
import { useState } from 'react';

export type Props = {
  attribute: string;
  limit?: number;
  titre?: string;
  search?: boolean;
  showMore?: boolean;
};
export const InstantSearchSelecteurFiltresUnitaire = ({
  titre,
  attribute,
  limit = 10,
  search = false,
  showMore = false,
}: Props) => {
  const [showMoreValue, setShowMoreValue] = useState<boolean>(false);

  const refinementList = ({ items, refine, searchForItems }: any) => (
    <>
      {titre && (search || items.length > 0) && (
        <Text as="h3" fontWeight="bold" fontSize="lg" px={6} py={2}>
          {titre}
        </Text>
      )}
      {search && (
        <Input
          mx={6}
          mt={2}
          mb={3}
          maxW={'140px'}
          maxH={'80px'}
          onChange={(event) => {
            searchForItems(event.currentTarget.value);
          }}
          placeholder="Recherche"
        />
      )}
      <Box mx={6} mb={4} overflow="hidden">
        {items.map((item: any) => (
          <Box
            key={item.label}
            onClick={(event) => {
              event.preventDefault();
              localStorage.setItem(attribute, item.value);

              refine(item.value);
            }}
          >
            <Checkbox
              colorScheme="teal"
              overflow="hidden"
              isChecked={item.isRefined ? true : false}
            >
              {getLabelFromFacet(item.label)} ({item.count})
            </Checkbox>
          </Box>
        ))}
        {showMore && items.length >= limit && (
          <Text
            fontWeight="light"
            fontSize="md"
            ml={13}
            mt={3}
            onClick={(event) => setShowMoreValue(!showMoreValue)}
          >
            {showMoreValue ? 'Voir moins ...' : 'Voir plus ...'}
          </Text>
        )}
      </Box>
    </>
  );

  const CustomRefinementListComponent = connectRefinementList(refinementList);
  return (
    <CustomRefinementListComponent
      limit={limit}
      showMoreLimit={100}
      showMore={showMoreValue}
      attribute={attribute}
      defaultRefinement={
        localStorage.getItem(attribute) !== ''
          ? localStorage.getItem(attribute)?.split(',')
          : undefined
      }
    />
  );
};

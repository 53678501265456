import { Button as ChakraButton, HStack } from '@chakra-ui/react';

import { ArrowLeftIcon, ArrowRightIcon } from 'components/Icons';

export type Props = {
  currentPage: number;
  totalPages: number;
  onGotoPage: (index: number) => void;
};

const onClickPreviousPage = (currentPage: number, onGotoPage: (index: number) => void) => {
  console.log('click');
  if (currentPage > 1) onGotoPage(currentPage - 1);
};

const onClickNextPage = (
  currentPage: number,
  totalPages: number,
  onGotoPage: (index: number) => void,
) => {
  if (currentPage < totalPages) onGotoPage(currentPage + 1);
};

export const Pagination = ({ currentPage, onGotoPage, totalPages }: Props) => {
  const hasPreviousPage = currentPage > 1;
  const hasNextPage = currentPage < totalPages;

  // Toujours afficher 4 pages/buttons, quelle que soit la current page.
  // Ex: si `currentPage` === 5, on veut afficher `4, 5, 6, 7`, avec la `5` en "active".
  // const numberOfPages = Math.min(Math.max(totalPages, 1), 4);
  const pages: number[] = [];
  if (currentPage >= 3) {
    pages.push(currentPage - 2);
    pages.push(currentPage - 1);
  } else if (currentPage === 2) {
    pages.push(1);
  }
  pages.push(currentPage);
  for (let i = 1; i < 5 && currentPage + i <= totalPages && pages.length < 5; i++) {
    pages.push(currentPage + i);
  }

  return (
    <HStack alignItems="center" justifyContent="center" spacing={6}>
      <ChakraButton
        borderRadius="base"
        disabled={!hasPreviousPage}
        onClick={(e) => onClickPreviousPage(currentPage, onGotoPage)}
        role="group"
        variant="unstyled"
        width={7}
        height={7}
      >
        <ArrowLeftIcon
          _groupHover={{ opacity: 1 }}
          color="teal.900"
          opacity={hasPreviousPage ? 0.3 : 0.1}
        />
      </ChakraButton>
      <HStack spacing={3}>
        {pages.map((index) => {
          const isActive = index === currentPage;
          return (
            <ChakraButton
              key={index}
              _hover={{ backgroundColor: 'teal.500', color: 'white' }}
              _focus={{ backgroundColor: 'teal.500', color: 'white' }}
              backgroundColor={isActive ? 'teal.500' : 'transparent'}
              borderRadius="base"
              color={isActive ? 'white' : 'teal.900'}
              fontSize="md"
              fontWeight="medium"
              height={7}
              onClick={() => {
                onGotoPage(index);
              }}
              px={0}
              variant="unstyled"
              width={7}
            >
              {index}
            </ChakraButton>
          );
        })}
      </HStack>
      <ChakraButton
        borderRadius="base"
        disabled={!hasNextPage}
        role="group"
        onClick={(e) => onClickNextPage(currentPage, totalPages, onGotoPage)}
        variant="unstyled"
        width={7}
        height={7}
      >
        <ArrowRightIcon
          color="teal.900"
          opacity={hasNextPage ? 0.3 : 0.1}
          _groupHover={{ opacity: 1 }}
        />
      </ChakraButton>
    </HStack>
  );
};

import { createIcon } from '@chakra-ui/react';

export const ChevronDownIcon = createIcon({
  displayName: 'ChevronDownIcon',
  viewBox: '0 0 10 7',
  path: (
    <path
      clipRule="evenodd"
      fill="currentColor"
      fillRule="evenodd"
      d="M9.707 2.207A1 1 0 008.293.793L5 4.086 1.707.793A1 1 0 00.293 2.207l3.994 3.994a1 1 0 001.42.006l4-4z"
    />
  ),
});

import { createIcon } from '@chakra-ui/react';

import { generateDataURIFromSVG } from 'utils/helpers';

const viewBox = '0 0 18 13';
const icon = {
  displayName: 'ArrowRightIcon',
  viewBox,
  path: (
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M1.5 6.5h15m0 0l-5.25-5.25M16.5 6.5l-5.25 5.25"
    />
  ),
};

export const ArrowRightIcon = createIcon(icon);
export const arrowRightIconDataURI = generateDataURIFromSVG(viewBox, icon.path, 'none', 'white');

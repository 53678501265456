import { Client, UsageProjet } from 'types';

/**
 * Form options to use in <Select /> or <Radio /> across the app.
 */

export const TITLE_OPTIONS: { label: string; value: Client['civilite'] }[] = [
  { label: 'Madame', value: 'MADAME' },
  { label: 'Monsieur', value: 'MONSIEUR' },
];

export const COUNTRY_OPTIONS = [
  { value: 'FRA', label: 'France' },
  { value: 'ABW', label: 'Aruba' },
  { value: 'AFG', label: 'Afghanistan' },
  { value: 'AGO', label: 'Angola' },
  { value: 'ALB', label: 'Albanie' },
  { value: 'AND', label: 'Andorre' },
  { value: 'ARE', label: 'Émirats arabes unis' },
  { value: 'ARG', label: 'Argentine' },
  { value: 'ARM', label: 'Arménie' },
  { value: 'AUS', label: 'Australie' },
  { value: 'AUT', label: 'Autriche' },
  { value: 'AZE', label: 'Azerbaïdjan' },
  { value: 'BDI', label: 'Burundi' },
  { value: 'BEL', label: 'Belgique' },
  { value: 'BEN', label: 'Bénin' },
  { value: 'BFA', label: 'Burkina Faso' },
  { value: 'BGD', label: 'Bangladesh' },
  { value: 'BGR', label: 'Bulgarie' },
  { value: 'BHR', label: 'Bahreïn' },
  { value: 'BHS', label: 'Bahamas' },
  { value: 'BIH', label: 'Bosnie-Herzégovine' },
  { value: 'BLR', label: 'Biélorussie' },
  { value: 'BLZ', label: 'Belize' },
  { value: 'BMU', label: 'Bermudes' },
  { value: 'BOL', label: 'Bolivie' },
  { value: 'BRA', label: 'Brésil' },
  { value: 'BRB', label: 'Barbade' },
  { value: 'BRN', label: 'Brunéi' },
  { value: 'BTN', label: 'Bhoutan' },
  { value: 'BWA', label: 'Botswana' },
  { value: 'CAF', label: 'République centrafricaine' },
  { value: 'CAN', label: 'Canada' },
  { value: 'CHE', label: 'Suisse' },
  { value: 'CHL', label: 'Chili' },
  { value: 'CHN', label: 'Chine' },
  { value: 'CIV', label: 'Côte d’Ivoire' },
  { value: 'CMR', label: 'Cameroun' },
  { value: 'COD', label: 'Rép. démocratique du Congo' },
  { value: 'COG', label: 'République du Congo' },
  { value: 'COL', label: 'Colombie' },
  { value: 'COM', label: 'Comores' },
  { value: 'CPV', label: 'Cap-Vert' },
  { value: 'CRI', label: 'Costa Rica' },
  { value: 'CUB', label: 'Cuba' },
  { value: 'CYP', label: 'Chypre' },
  { value: 'CZE', label: 'République tchèque' },
  { value: 'DEU', label: 'Allemagne' },
  { value: 'DJI', label: 'Djibouti' },
  { value: 'DMA', label: 'Dominique' },
  { value: 'DNK', label: 'Danemark' },
  { value: 'DOM', label: 'République dominicaine' },
  { value: 'DZA', label: 'Algérie' },
  { value: 'ECU', label: 'Équateur' },
  { value: 'EGY', label: 'Égypte' },
  { value: 'ERI', label: 'Érythrée' },
  { value: 'ESP', label: 'Espagne' },
  { value: 'EST', label: 'Estonie' },
  { value: 'ETH', label: 'Éthiopie' },
  { value: 'FIN', label: 'Finlande' },
  { value: 'FJI', label: 'Fidji' },
  { value: 'GAB', label: 'Gabon' },
  { value: 'GBR', label: 'Royaume-Uni' },
  { value: 'GEO', label: 'Géorgie' },
  { value: 'GHA', label: 'Ghana' },
  { value: 'GIB', label: 'Gibraltar' },
  { value: 'GIN', label: 'Guinée' },
  { value: 'GMB', label: 'Gambie' },
  { value: 'GNB', label: 'Guinée-Bissau' },
  { value: 'GNQ', label: 'Guinée équatoriale' },
  { value: 'GRC', label: 'Grèce' },
  { value: 'GRD', label: 'Grenade' },
  { value: 'GTM', label: 'Guatemala' },
  { value: 'GUY', label: 'Guyana' },
  { value: 'HND', label: 'Honduras' },
  { value: 'HRV', label: 'Croatie' },
  { value: 'HTI', label: 'Haïti' },
  { value: 'HUN', label: 'Hongrie' },
  { value: 'IDN', label: 'Indonésie' },
  { value: 'IND', label: 'Inde' },
  { value: 'IRL', label: 'Irlande' },
  { value: 'IRN', label: 'Iran' },
  { value: 'IRQ', label: 'Irak' },
  { value: 'ISL', label: 'Islande' },
  { value: 'ISR', label: 'Israël' },
  { value: 'ITA', label: 'Italie' },
  { value: 'JAM', label: 'Jamaïque' },
  { value: 'JOR', label: 'Jordanie' },
  { value: 'JPN', label: 'Japon' },
  { value: 'KAZ', label: 'Kazakhstan' },
  { value: 'KEN', label: 'Kenya' },
  { value: 'KGZ', label: 'Kirghizistan' },
  { value: 'KIR', label: 'Kiribati' },
  { value: 'KOR', label: 'Corée du Sud' },
  { value: 'KWT', label: 'Koweït' },
  { value: 'LAO', label: 'Laos' },
  { value: 'LBN', label: 'Liban' },
  { value: 'LBR', label: 'Libéria' },
  { value: 'LBY', label: 'Libye' },
  { value: 'LIE', label: 'Liechtenstein' },
  { value: 'LKA', label: 'Sri Lanka' },
  { value: 'LSO', label: 'Lesotho' },
  { value: 'LTU', label: 'Lituanie' },
  { value: 'LUX', label: 'Luxembourg' },
  { value: 'LVA', label: 'Lettonie' },
  { value: 'MAR', label: 'Maroc' },
  { value: 'MCO', label: 'Monaco' },
  { value: 'MDG', label: 'Madagascar' },
  { value: 'MDV', label: 'Maldives' },
  { value: 'MEX', label: 'Mexique' },
  { value: 'MKD', label: 'Macédoine' },
  { value: 'MLI', label: 'Mali' },
  { value: 'MLT', label: 'Malte' },
  { value: 'MMR', label: 'Birmanie' },
  { value: 'MNE', label: 'Monténégro' },
  { value: 'MNG', label: 'Mongolie' },
  { value: 'MOZ', label: 'Mozambique' },
  { value: 'MRT', label: 'Mauritanie' },
  { value: 'MUS', label: 'Maurice' },
  { value: 'MWI', label: 'Malawi' },
  { value: 'MYS', label: 'Malaisie' },
  { value: 'NAM', label: 'Namibie' },
  { value: 'NER', label: 'Niger' },
  { value: 'NGA', label: 'Nigéria' },
  { value: 'NIC', label: 'Nicaragua' },
  { value: 'NLD', label: 'Pays-Bas' },
  { value: 'NOR', label: 'Norvège' },
  { value: 'NPL', label: 'Népal' },
  { value: 'NZL', label: 'Nouvelle-Zélande' },
  { value: 'OMN', label: 'Oman' },
  { value: 'PAK', label: 'Pakistan' },
  { value: 'PAN', label: 'Panama' },
  { value: 'PER', label: 'Pérou' },
  { value: 'PHL', label: 'Philippines' },
  { value: 'PNG', label: 'Papouasie-Nouvelle-Guinée' },
  { value: 'POL', label: 'Pologne' },
  { value: 'PRI', label: 'Porto Rico' },
  { value: 'PRK', label: 'Corée du Nord' },
  { value: 'PRT', label: 'Portugal' },
  { value: 'PRY', label: 'Paraguay' },
  { value: 'QAT', label: 'Qatar' },
  { value: 'ROU', label: 'Roumanie' },
  { value: 'RUS', label: 'Russie' },
  { value: 'RWA', label: 'Rwanda' },
  { value: 'SAU', label: 'Arabie Saoudite' },
  { value: 'SDN', label: 'Soudan' },
  { value: 'SEN', label: 'Sénégal' },
  { value: 'SGP', label: 'Singapour' },
  { value: 'SLE', label: 'Sierra Leone' },
  { value: 'SLV', label: 'Salvador' },
  { value: 'SOM', label: 'Somalie' },
  { value: 'SRB', label: 'Serbie' },
  { value: 'SUR', label: 'Suriname' },
  { value: 'SVK', label: 'Slovaquie' },
  { value: 'SVN', label: 'Slovénie' },
  { value: 'SWE', label: 'Suède' },
  { value: 'SWZ', label: 'Swaziland' },
  { value: 'SYR', label: 'Syrie' },
  { value: 'TCD', label: 'Tchad' },
  { value: 'TGO', label: 'Togo' },
  { value: 'THA', label: 'Thaïlande' },
  { value: 'TJK', label: 'Tadjikistan' },
  { value: 'TKM', label: 'Turkménistan' },
  { value: 'TLS', label: 'Timor-Oriental' },
  { value: 'TUN', label: 'Tunisie' },
  { value: 'TUR', label: 'Turquie' },
  { value: 'TUV', label: 'Tuvalu' },
  { value: 'TWN', label: 'Taïwan' },
  { value: 'TZA', label: 'Tanzanie' },
  { value: 'UGA', label: 'Ouganda' },
  { value: 'UKR', label: 'Ukraine' },
  { value: 'URY', label: 'Uruguay' },
  { value: 'USA', label: 'États-Unis' },
  { value: 'UZB', label: 'Ouzbékistan' },
  { value: 'VAT', label: 'Vatican' },
  { value: 'VCT', label: 'St-Vincent-et-les-Grenadines' },
  { value: 'VEN', label: 'Venezuela' },
  { value: 'VNM', label: 'Vietnam' },
  { value: 'YEM', label: 'Yémen' },
  { value: 'ZAF', label: 'Afrique du Sud' },
  { value: 'ZMB', label: 'Zambie' },
  { value: 'ZWE', label: 'Zimbabwe' },
];

export const PROJECT_FORMAT_OPTIONS = [
  { label: 'Dossiers', value: 'dossiers' },
  { label: 'Contacts', value: 'contacts' },
  { label: 'Liens', value: 'liens' },
];

export const PROJECT_TYPE_OPTIONS = [
  { label: 'Achat SCPI', value: 'ACHAT_SCPI' },
  { label: "Achat d'une construction", value: 'CONSTRUCTION' },
  { label: "Achat d'un logement ancien", value: 'LOGEMENT_ANCIEN' },
  { label: "Achat d'un logement neuf", value: 'LOGEMENT_NEUF' },
  { label: "Renégociation d'un prêt existant", value: 'RENEGOCIATION' },
  { label: "Achat d'un terrain", value: 'TERRAIN' },
  { label: "Achat d'un terrain et construction", value: 'TERRAIN_ET_CONSTRUCTION' },
  { label: 'Financement de travaux', value: 'TRAVAUX' },
];

export const PROJECT_USAGE_OPTIONS: { label: string; value: UsageProjet }[] = [
  { label: 'Résidence principale', value: 'RES_PRINCIPALE' },
  { label: 'Résidence secondaire', value: 'RES_SECONDAIRE' },
  { label: 'Locatif', value: 'LOCATIF' },
  { label: 'Local professionel', value: 'PRO' },
];

export const PROJECT_MATURITY_OPTIONS = [
  { label: 'Recherche', value: 'RECHERCHE' },
  { label: 'Signé', value: 'SIGNE' },
  { label: 'Trouvé', value: 'TROUVE' },
];

export const PROJECT_STEP_OPTIONS = [
  { label: 'Créé', value: 'CREE' },
  { label: '1er rappel', value: 'RAPPEL' },
  { label: '1ère relance', value: 'RELANCE1' },
  { label: '2ème relance', value: 'RELANCE2' },
];

export const PROJECT_PROSPECT_OPTIONS = [
  { label: 'Contacté', value: 'CONTACTE' },
  { label: 'Relance', value: 'RELANCE' },
  { label: 'Attente pièces', value: 'ATTENTE_PIECES' },
];

export const BORROWERS_COUNT_OPTIONS = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
];

export const PROFILE_TYPE_OPTIONS = [
  { label: 'Client', value: 'CLIENT' },
  { label: 'Prospect', value: 'PROSPECT' },
];

export const PROFILE_STATE_OPTIONS: { label: string; value: Client['etat'] }[] = [
  { label: 'À valider', value: 'A_VALIDER' },
  { label: 'Validé', value: 'VALIDE' },
];

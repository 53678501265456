import { createIcon } from '@chakra-ui/react';

import { generateDataURIFromSVG } from 'utils/helpers';

const viewBox = '0 0 11 8';
const icon = {
  displayName: 'CheckmarkIcon',
  viewBox,
  path: (
    <path
      fill="currentColor"
      d="M9.949 1.249L3.41 7.787a.726.726 0 01-1.028 0l-2.18-2.18a.726.726 0 011.028-1.026l1.666 1.665L8.922.222a.727.727 0 111.027 1.027z"
    />
  ),
};

export const CheckmarkIcon = createIcon(icon);
export const checkmarkIconDataURI = generateDataURIFromSVG(viewBox, icon.path);

import { ChangeEvent, useCallback, useRef, useState, useContext } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useToast,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';
import { Button } from 'components/Button';
import { Textarea } from 'components/Textarea';
import { AuthContext } from 'auth/AuthContextProvider';
import { LabelToast } from 'utils/labels';

export type Props = {
  idProjet: number | undefined;
  isDossier: boolean;
  onDone: () => void;
} & Omit<ModalProps, 'children'>;

type CommentairePostObject = {
  type: 'COMMENT';
  detail: string;
  est_public: number;
  objet: string;
  dossier_id?: number;
  contact_id?: number;
};

export const NewCommentModal = ({ idProjet, isDossier, onClose, onDone, ...props }: Props) => {
  const toast = useToast();
  const { token } = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValue] = useState('');
  const textareaRef = useRef(null);

  // Callbacks
  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    setIsSubmitting(true);
    const commentaireObject: CommentairePostObject = {
      type: 'COMMENT',
      detail: value,
      est_public: 1,
      objet: 'object',
    };
    if (idProjet !== undefined) {
      if (isDossier) {
        commentaireObject.dossier_id = idProjet;
      } else {
        commentaireObject.contact_id = idProjet;
      }
    }

    fetch('/API/commentaires', {
      method: 'POST',
      body: JSON.stringify(commentaireObject),
      headers: new Headers({
        Authorization: token,
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          setIsSubmitting(false);
          setValue('');
          toast({
            title: LabelToast.SUCCESS_DEPOT_COMMENTAIRE,
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          onDone();
        } else {
          toast({
            title: 'Erreur (code : ' + res.status + ')',
            description: LabelToast.FAIL_DEPOT_COMMENTAIRE + res.statusText,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          setIsSubmitting(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsSubmitting(false);
      });
  }, [onDone, value]);

  return (
    <Modal
      initialFocusRef={textareaRef}
      onClose={onClose}
      scrollBehavior="inside"
      size="3xl"
      {...props}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Nouveau commentaire</ModalHeader>
        <ModalCloseButton disabled={isSubmitting} />
        <ModalBody>
          <Textarea
            ref={textareaRef}
            required
            resize="none"
            size="sm"
            value={value}
            onChange={handleChange}
          />
        </ModalBody>
        <ModalFooter
          boxShadow={{ base: 'none', lg: 'xs' }}
          justifyContent={{ base: 'center', lg: 'flex-end' }}
        >
          <Button isLoading={isSubmitting} onClick={handleSubmit} type="submit">
            Enregistrer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

import { useContext } from 'react';
import { Image, Center, Box, Text, Spinner } from '@chakra-ui/react';
import { getLogo } from 'utils/marque';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'auth/AuthContextProvider';

export const AuthSso = ({ ...props }) => {
  return (
    <>
      <Box position="fixed" width="100%" bottom="0" right="0">
        <Image
          float="right"
          width={{ base: '100%', lg: '70%' }}
          src="/login_background.svg"
        ></Image>
      </Box>
      <Box
        margin="0px"
        padding="0px"
        width="100%"
        height="100%"
        backgroundColor="#E8F4F4"
        paddingTop="100px"
      >
        <Box
          position="relative"
          marginLeft={{ base: 'calc( 50% - 175px )', lg: '200px' }}
          width="350px"
        >
          <Text
            fontWeight="900"
            fontSize="26px"
            marginLeft="20px"
            color="white"
            backgroundColor="#349FA1"
            padding="5px"
            transform="skew(-0.00turn, -5deg)"
            width="fit-content"
          >
            CONNECTION
          </Text>
          <Text
            fontWeight="900"
            fontSize="26px"
            marginLeft="90px"
            marginTop="5px"
            color="white"
            backgroundColor="#F8B632"
            padding="5px"
            transform="skew(-0.00turn, -5deg)"
            width="fit-content"
          >
            À VOTRE ESPACE
          </Text>

          <Box
            marginLeft={'0px'}
            marginTop="40px"
            padding="20px"
            width="350px"
            height="540px"
            zIndex="1"
            backgroundColor="white"
            borderRadius="-webkit-border-radius: 10px; -moz-border-radius: 10px; border-radius: 10px;"
            boxShadow="0px 0px 30px 00px rgba(0,0,0,0.1)"
            textAlign="center"
          >
            <Center marginBottom="10px">
              <Image maxWidth="100%" marginWidth="auto" src={getLogo()}></Image>
            </Center>
            <Center>
              <Text as="h3" fontSize={{ base: 'xl', xxl: '2xl' }} fontWeight="semibold" mb={6}>
                Authentification SSO...
              </Text>
            </Center>
            <Center>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Center>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export const AuthSsoRoute = () => {
  const { token } = useContext(AuthContext);
  const history = useHistory();
  if (token !== '') {
    history.push('/home');
  }
  return <AuthSso />;
};

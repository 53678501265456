import { createIcon } from '@chakra-ui/react';

export const HouseWithCheckmarkIcon = createIcon({
  displayName: 'HouseWithCheckmarkIcon',
  viewBox: '0 0 17 20',
  path: (
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M6.167 19.167h-5V7.5L7 1.667 12.833 7.5v4.167m-3.333 5l1.667 1.666 4.166-4.166m-6.666-5a1.667 1.667 0 11-3.334 0 1.667 1.667 0 013.334 0z"
    />
  ),
});

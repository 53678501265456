import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  useToast,
  Center,
  Text,
} from '@chakra-ui/react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from 'components/Button';
import { LinkIcon, OpenFolderIcon, UserFrameIcon } from 'components/Icons';
import { MeContext } from 'auth/AuthContextProvider';
import { useContext } from 'react';

export type Props = {
  onOpenCreateContactModal?: () => void;
  onOpenSendLinkModal?: () => void;
  onOpenNewDossierTunnel?: () => void;
} & Omit<ModalProps, 'children'>;

export const ActionsModal = ({
  onOpenCreateContactModal,
  onOpenSendLinkModal,
  onOpenNewDossierTunnel,
  ...props
}: Props) => {
  const toast = useToast();
  const me = useContext(MeContext);
  console.log(me);
  console.log(me.utilisateur?.organisation);
  return (
    <Modal {...props} scrollBehavior="inside" size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader />
        <ModalCloseButton />
        <ModalBody pb={{ base: 7, lg: 14 }} pt={{ base: 0, lg: 14 }}>
          {me.utilisateur?.organisation?.autoriser_iobsp && (
            <Center mb={'20px'}>
              <Text as="b">Mode IOBSP</Text>
            </Center>
          )}

          <Stack
            alignItems={{ lg: 'center' }}
            direction={{ base: 'column', lg: 'row' }}
            justifyContent="stretch"
            spacing={6}
          >
            <Button
              borderColor="gray.200"
              borderRadius="sm"
              borderWidth={1}
              flex={1}
              minHeight={{ base: 14, lg: 16 }}
              onClick={onOpenSendLinkModal}
              p={7}
              py={{ base: 5, lg: 7 }}
              variant="ghost"
              isDisabled={me.utilisateur?.apporteur_code === null ? true : false}
            >
              <LinkIcon boxSize="32px" color="teal.500" mb={5} />
              <Text color="teal.900" fontSize="sm" fontWeight="semibold">
                Envoi d’un lien pour simulation
              </Text>
            </Button>
            {me.utilisateur?.organisation?.autoriser_fiche_contact && (
              <Button
                borderColor="gray.200"
                borderRadius="sm"
                borderWidth={1}
                flex={1}
                minHeight={{ base: 14, lg: 16 }}
                onClick={onOpenCreateContactModal}
                p={7}
                py={{ base: 5, lg: 7 }}
                variant="ghost"
              >
                <UserFrameIcon boxSize="32px" color="teal.500" mb={5} />
                <Text color="teal.900" fontSize="sm" fontWeight="semibold">
                  Création d'une fiche contact
                </Text>
              </Button>
            )}
            {me.utilisateur?.organisation?.autoriser_iobsp && (
              <Button
                borderColor="gray.200"
                borderRadius="sm"
                borderWidth={1}
                flex={1}
                minHeight={{ base: 14, lg: 16 }}
                onClick={onOpenNewDossierTunnel}
                p={7}
                py={{ base: 5, lg: 7 }}
                variant="ghost"
                isDisabled={me.utilisateur?.apporteur_code === null ? true : false}
              >
                <OpenFolderIcon boxSize="32px" color="teal.500" mb={5} />
                <Text color="teal.900" fontSize="sm" fontWeight="semibold">
                  Simulation pendant rendez-vous
                </Text>
              </Button>
            )}
          </Stack>
          <Stack
            alignItems={{ lg: 'center' }}
            direction={'column'}
            justifyContent="stretch"
            spacing={6}
          >
            <Center>
              {me.utilisateur?.apporteur_code !== null && (
                <CopyToClipboard
                  text={me?.marque?.url_tunnel + '?apcode=' + me?.utilisateur?.apporteur_code}
                  onCopy={() => {
                    toast({
                      title: 'Lien personnel copié dans votre presse papier.',
                      status: 'success',
                      duration: 3000,
                      isClosable: true,
                    });
                  }}
                >
                  <Button
                    mt={{ base: 5, lg: 7 }}
                    borderColor="gray.200"
                    borderRadius="sm"
                    borderWidth={1}
                    flex={1}
                    minHeight={7}
                    p={7}
                    py={{ base: 5, lg: 7 }}
                    variant="ghost"
                    overflow={'hidden'}
                  >
                    <Text color="teal.900" fontSize="sm" fontWeight="semibold">
                      Cliquez ici pour copier votre lien personnel :
                    </Text>
                    <Text color="teal.900" fontSize="md" fontWeight="bold">
                      {' ' + me?.marque?.url_tunnel + '?apcode=' + me?.utilisateur?.apporteur_code}
                    </Text>
                  </Button>
                </CopyToClipboard>
              )}
              {me.utilisateur?.apporteur_code === null && (
                <Text color="red.500" fontSize="lg" fontWeight="bold" mt={5} mb={5}>
                  Vous ne possèdez pas de code apporteur, contactez votre courtier pour en obtenir
                  un.
                </Text>
              )}
            </Center>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

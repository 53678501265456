import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
} from '@chakra-ui/react';
import { InstantSearchSelecteurFiltresUnitaire } from 'components/InstantSearchSelecteurFiltresUnitaire';
import { DateRangePicker } from 'components/DateRangePicker';
import { roleCodePeuxVoirPerimetre } from 'utils/helpers';
import { useContext } from 'react';
import { MeContext } from 'auth/AuthContextProvider';
import { datePickerProps } from 'components/DateRangePicker/lib/defaultProps';

export const InstantSearchSelecteurFiltresMobile = () => {
  const me = useContext(MeContext);

  return (
    <>
      <Accordion display={{ base: 'block', xl: 'none' }} allowToggle={true} allowMultiple={true}>
        <AccordionItem>
          <h2>
            <AccordionButton px={0} py={0}>
              <Text fontWeight="bold" fontSize="lg" px={4} py={4}>
                Filtres
              </Text>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <InstantSearchSelecteurFiltresUnitaire titre="Interaction" attribute="kind" />
            <InstantSearchSelecteurFiltresUnitaire titre="Maturité" attribute="maturite" />
            <InstantSearchSelecteurFiltresUnitaire titre="Usage projet" attribute="usage" />
            <InstantSearchSelecteurFiltresUnitaire titre="Type projet" attribute="type" />
            <InstantSearchSelecteurFiltresUnitaire
              titre="Bien à vendre"
              attribute="bien_a_vendre"
            />
            <DateRangePicker
              attribute="date_creation"
              datePickerProps={datePickerProps}
              titre="Date de création"
            />
            <InstantSearchSelecteurFiltresUnitaire
              showMore={true}
              titre="Étape"
              attribute="code_derniere_etape"
            />
          </AccordionPanel>
        </AccordionItem>
        {me.utilisateur?.role && roleCodePeuxVoirPerimetre(me.utilisateur?.role) && (
          <>
            <AccordionItem>
              <h2>
                <AccordionButton px={0} py={0}>
                  <Text fontWeight="bold" fontSize="lg" px={4} py={4}>
                    Périmètres
                  </Text>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <InstantSearchSelecteurFiltresUnitaire
                  showMore={true}
                  titre="Région"
                  attribute="libelle_region"
                />
                <InstantSearchSelecteurFiltresUnitaire
                  showMore={true}
                  titre="Agence"
                  attribute="libelle_agence"
                />
                <InstantSearchSelecteurFiltresUnitaire
                  showMore={true}
                  search={true}
                  titre="Apporteur"
                  attribute="nom_apporteur"
                />
              </AccordionPanel>
            </AccordionItem>
          </>
        )}
      </Accordion>
    </>
  );
};

import { useState } from 'react';
import { Box, BoxProps, Flex, Link, Stack } from '@chakra-ui/react';
import { Field, FieldProps, FormikProvider, useFormik } from 'formik';
import { format, parse } from 'date-fns';

import { DatePicker } from 'components/DatePicker';
import { KPIPanel } from 'components/KPIPanel';
import { PopoverSelect } from 'components/PopoverSelect';
import {
  EnvelopeIcon,
  FolderWithCheckmarkIcon,
  HouseWithCheckmarkIcon,
  LinkIcon,
} from 'components/Icons';
import { DATE_FORMAT } from 'utils/helpers';

const INITIAL_VALUES = {
  date_range: { from: undefined, to: undefined },
  region: '',
  agency: '',
  adviser: '',
};

const REGION_OPTIONS = [
  { label: 'Bretagne', value: 'BRETAGNE' },
  { label: 'Normandie', value: 'NORMANDIE' },
  { label: 'Paris', value: 'PARIS' },
];

const AGENCY_OPTIONS = [
  { label: 'Agence Rennes', value: 'RENNES' },
  { label: 'Agence Brest', value: 'BREST' },
  { label: 'Agence Lorient', value: 'LORIENT' },
];

const ADVISER_OPTIONS = [
  { label: 'Conseiller 1', value: '1' },
  { label: 'Conseiller 2', value: '2' },
  { label: 'Conseiller 3', value: '3' },
];

const INITIAL_DATA = {
  links: 0,
  contacts: 0,
  estimations: 0,
  files: 0,
};

export type Data = typeof INITIAL_DATA;
export type Values = typeof INITIAL_VALUES;
export type Props = BoxProps;

export const KPIs = (props: Props) => {
  const [data, setData] = useState<Data>(INITIAL_DATA);

  // Form declaration
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (values: Values, actions) => {
      console.log(values);

      // TODO: Call the API here
      setTimeout(() => {
        actions.setSubmitting(false);
        setData({
          links: Math.round(Math.random() * 100),
          contacts: Math.round(Math.random() * 100),
          estimations: Math.round(Math.random() * 100),
          files: Math.round(Math.random() * 100),
        });
      }, 3000);
    },
  });

  return (
    <FormikProvider value={formik}>
      <Box {...props}>
        <form onSubmit={formik.handleSubmit}>
          <Flex
            alignItems={{ lg: 'center' }}
            flexDirection={{ base: 'column-reverse', lg: 'row' }}
            justifyContent={{ lg: 'space-between' }}
          >
            <Flex flexDirection={{ base: 'column', lg: 'row' }}>
              {/** DatePicker: Date range field */}
              <Field name="date_range">
                {({ field, form }: FieldProps) => {
                  const { name, value } = field;
                  return (
                    <Flex alignItems="center" mb={{ base: 4, lg: 0 }} mr={{ base: 4, lg: 10 }}>
                      <DatePicker
                        initialFrom={value.from && parse(value.from, DATE_FORMAT, new Date())}
                        initialTo={value.to && parse(value.to, DATE_FORMAT, new Date())}
                        onChange={(from, to) => {
                          form.setFieldValue(name, {
                            from: from && format(from, DATE_FORMAT),
                            to: to && format(to, DATE_FORMAT),
                          });
                          form.submitForm();
                        }}
                      />
                    </Flex>
                  );
                }}
              </Field>
              {/** Select: Region field */}
              <Field name="region">
                {({ field, form }: FieldProps) => {
                  const { name, value } = field;
                  return (
                    <Flex alignItems="center" mb={{ base: 4, lg: 0 }} mr={{ lg: 10 }}>
                      <PopoverSelect
                        defaultValue={value}
                        name={name}
                        onChange={(val) => {
                          form.setFieldValue(name, val);
                          form.submitForm();
                        }}
                      >
                        {REGION_OPTIONS.map(({ label, value }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </PopoverSelect>
                    </Flex>
                  );
                }}
              </Field>
              {/** Select: Agency field */}
              <Field name="agency">
                {({ field, form }: FieldProps) => {
                  const { name, value } = field;
                  return (
                    <Flex alignItems="center" mb={{ base: 4, lg: 0 }} mr={{ lg: 10 }}>
                      <PopoverSelect
                        defaultValue={value}
                        name={name}
                        onChange={(val) => {
                          form.setFieldValue(name, val);
                          form.submitForm();
                        }}
                      >
                        {AGENCY_OPTIONS.map(({ label, value }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </PopoverSelect>
                    </Flex>
                  );
                }}
              </Field>
              {/** Select: Adviser field */}
              <Field name="adviser">
                {({ field, form }: FieldProps) => {
                  const { name, value } = field;
                  return (
                    <Flex alignItems="center" mb={{ base: 4, lg: 0 }}>
                      <PopoverSelect
                        defaultValue={value}
                        name={name}
                        onChange={(val) => {
                          form.setFieldValue(name, val);
                          form.submitForm();
                        }}
                      >
                        {ADVISER_OPTIONS.map(({ label, value }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </PopoverSelect>
                    </Flex>
                  );
                }}
              </Field>
            </Flex>
            <Link
              href="http://monemprunt.com"
              color="teal.500"
              alignSelf={{ base: 'flex-start', lg: 'center' }}
              fontSize="md"
              fontWeight="semibold"
              target="_blank"
              textDecoration="underline"
              mb={{ base: 4, lg: 0 }}
              _hover={{ color: 'green.500' }}
            >
              Voir le rapport data studio
            </Link>
          </Flex>
        </form>
        <Stack direction={{ base: 'row' }} justifyContent="center" spacing={{ base: 4, lg: 6 }}>
          <KPIPanel value={formik.isSubmitting ? '...' : data.links} icon={LinkIcon}>
            Liens envoyés
          </KPIPanel>
          <KPIPanel value={formik.isSubmitting ? '...' : data.contacts} icon={EnvelopeIcon}>
            Contact envoyés
          </KPIPanel>
          <KPIPanel
            value={formik.isSubmitting ? '...' : data.estimations}
            icon={HouseWithCheckmarkIcon}
          >
            Estimations
          </KPIPanel>
          <KPIPanel value={formik.isSubmitting ? '...' : data.files} icon={FolderWithCheckmarkIcon}>
            Dossiers conclus
          </KPIPanel>
        </Stack>
      </Box>
    </FormikProvider>
  );
};

import { createIcon } from '@chakra-ui/react';

export const ExitIcon = createIcon({
  displayName: 'ExitIcon',
  viewBox: '0 0 10 11',
  path: (
    <>
      <path
        fill="currentColor"
        d="M2.125 1.75H5V3h1.25V1.125C6.25.75 6 .5 5.625.5h-5C.25.5 0 .75 0 1.125V6.75c0 .188.063.313.188.438l3.124 3.125c.126.124.25.187.438.187.063 0 .188 0 .25-.063.25-.062.375-.312.375-.562V4.25a.567.567 0 00-.188-.438L2.126 1.75zm1 6.625L1.25 6.5V2.625L3.125 4.5v3.875z"
      />
      <path
        fill="currentColor"
        d="M9.813 5.063L7.5 2.75l-.875.875 1.25 1.25H5v1.25h2.875l-1.25 1.25.875.875 2.313-2.313a.604.604 0 000-.875z"
      />
    </>
  ),
});

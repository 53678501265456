import { Tag } from 'components/Tag';
import { ACTIONS } from 'utils/labels';
import { ExitIcon, BinIcon, EnvelopeIcon } from 'components/Icons';

export type Props = {
  action: string;
  onClick: () => void;
};

export const TagAction = ({ action, onClick, ...props }: Props) => {
  const clicActionWithPreventDefault: (e: React.MouseEvent) => void = (e) => {
    e.preventDefault();
    onClick();
  };
  switch (action) {
    case 'RELANCE_CLIENT':
      return (
        <Tag
          onClick={clicActionWithPreventDefault}
          key={action}
          colorScheme={'orange-light'}
          leftIcon={ExitIcon}
          _notLast={{ mb: 3, mr: 3 }}
          _hover={{ cursor: 'pointer' }}
        >
          {ACTIONS[action]}
        </Tag>
      );
    case 'TRANSFERT_DOSSIER':
      return (
        <Tag
          onClick={clicActionWithPreventDefault}
          key={action}
          colorScheme={'green-light'}
          leftIcon={EnvelopeIcon}
          _notLast={{ mb: 3, mr: 3 }}
          _hover={{ cursor: 'pointer' }}
        >
          {ACTIONS[action]}
        </Tag>
      );
    case 'SUPPRIMER_DOSSIER':
      return (
        <Tag
          onClick={clicActionWithPreventDefault}
          key={action}
          colorScheme={'red-light'}
          leftIcon={BinIcon}
          _notLast={{ mb: 3, mr: 3 }}
          _hover={{ cursor: 'pointer' }}
        >
          {ACTIONS[action]}
        </Tag>
      );
    default:
      return <></>;
  }
};

import { createIcon } from '@chakra-ui/react';

export const PlusIcon = createIcon({
  displayName: 'PlusIcon',
  viewBox: '0 0 16 17',
  path: (
    <path
      fill="currentColor"
      d="M14.667 7.167H9.333V1.833C9.333 1.033 8.8.5 8 .5c-.8 0-1.333.533-1.333 1.333v5.334H1.333C.533 7.167 0 7.7 0 8.5c0 .8.533 1.333 1.333 1.333h5.334v5.334c0 .8.533 1.333 1.333 1.333.8 0 1.333-.533 1.333-1.333V9.833h5.334C15.467 9.833 16 9.3 16 8.5c0-.8-.533-1.333-1.333-1.333z"
    />
  ),
});

import { Layout } from 'components/Layout';
import { Courtier, Utilisateur } from 'types';
import { Panel } from 'components/Panel';

import { Flex, Center, Text, Spinner } from '@chakra-ui/react';
export type Props = {
  courtier: Courtier;
  utilisateur: Utilisateur;
  hasStickyFooter?: boolean;
  sidebar?: React.ReactNode;
};

export const LoadingPage = ({
  courtier,
  utilisateur,
  hasStickyFooter,
  sidebar,
  ...props
}: Props) => {
  return (
    <Layout courtier={courtier} utilisateur={utilisateur} sidebar={sidebar} {...props}>
      <Flex as="section" flexDirection={{ base: 'column', lg: 'row' }} {...props}>
        <Panel
          w={{ base: '80%', lg: '50%' }}
          mx={{ base: '10%', lg: '25%' }}
          my={{ base: 6, lg: 0 }}
          px={{ base: 6, xxl: 10 }}
          py={{ base: 6, xxl: 8 }}
        >
          <Center>
            <Text as="h3" fontSize={{ base: 'xl', xxl: '2xl' }} fontWeight="semibold" mb={6}>
              Chargement des données ...
            </Text>
          </Center>
          <Center>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Center>
        </Panel>
      </Flex>
    </Layout>
  );
};

import { FileCourtierPanel } from 'components/FileCourtierPanel';
import { Courtier } from 'types';
import { useQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from 'auth/AuthContextProvider';

export type PropsCourtier = {
  courtier: Courtier;
};

export type PropsIdAffectation = {
  id: number | undefined;
};
export const FileDetailsSidebar = ({ courtier }: PropsCourtier) => {
  return <FileCourtierPanel courtiers={[courtier]} mt={{ xl: '65px' }} />;
};

export const FileDetailsSidebarRoute = ({ id }: PropsIdAffectation) => {
  const { token } = useContext(AuthContext);
  const { isLoading, error, data } = useQuery(['RecuperationCourtier', [id, token]], () =>
    fetch('/API/courtiers/' + id, {
      method: 'GET',
      headers: new Headers({
        Authorization: token,
      }),
    }).then((res) => res.json()),
  );
  if (isLoading) return <>Chargement du courtier</>;
  if (error) return <> Erreur chargement courtier</>;
  if (data) {
    const courtier: Courtier = {
      id: id!,
      nom: data.nom,
      prenom: data.prenom,
      mail: data.mail,
      telephone: data.telephone_fixe,
      telephone_portable: data.telephone_portable,
      url_image: 'https://storage.googleapis.com/mec-public/courtier/' + id + '.jpg',
    };
    return <FileDetailsSidebar courtier={courtier} />;
  }
  return <></>;
};

import {
  Button as ChakraButton,
  ButtonProps,
  ComponentWithAs,
  HStack,
  IconProps,
  forwardRef,
} from '@chakra-ui/react';
import { Tag } from 'components/Tag';

export type ColorScheme = 'teal' | 'gray' | 'orange-light' | 'red-light';
export type Props = {
  children?: React.ReactNode;
  colorScheme?: ColorScheme;
  icon?: ComponentWithAs<'svg', IconProps>;
  tag?: number;
} & Omit<ButtonProps, 'colorScheme'>;

export const Button = forwardRef<Props, 'button'>(
  ({ children, colorScheme = 'teal', icon: Icon, variant = 'solid', tag, ...props }, ref) => {
    const defaultStyles = { _active: { color: 'currentColor' } };
    const styles = variant === 'solid' ? getStyles(colorScheme, children) : defaultStyles;

    return (
      <ChakraButton colorScheme={colorScheme} ref={ref} variant={variant} {...styles} {...props}>
        <HStack spacing={4}>
          {Icon && <Icon boxSize="18px" />}
          {children && <span>{children}</span>}
          {tag && <Tag colorScheme="orange">{tag?.toLocaleString('fr-FR')}</Tag>}
        </HStack>
      </ChakraButton>
    );
  },
);

const getStyles = (colorScheme: ColorScheme, children?: React.ReactNode): ButtonProps => {
  const sharedStyles = { borderRadius: 'xl', minH: 45, px: !children ? 7 : 10 };

  switch (colorScheme) {
    case 'teal':
      return {
        ...sharedStyles,
        background: 'teal.500',
        color: 'white',
        _active: { background: 'green.500' },
        _hover: { background: 'green.500' },
        _focus: { boxShadow: 'teal' },
      };
    case 'gray':
      return {
        ...sharedStyles,
        background: 'gray.100',
        color: 'teal.900',
        _active: { background: 'gray.50' },
        _hover: { background: 'gray.50' },
        _focus: { boxShadow: 'gray' },
      };
    case 'orange-light':
      return {
        ...sharedStyles,
        background: 'orange.50',
        color: 'orange.500',
        _active: { background: 'orange.500', color: 'white' },
        _hover: { background: 'orange.500', color: 'white' },
        _focus: { boxShadow: 'orange' },
      };
    case 'red-light':
      return {
        ...sharedStyles,
        background: 'red.50',
        color: 'red.500',
        _active: { background: 'red.500', color: 'white' },
        _hover: { background: 'red.500', color: 'white' },
        _focus: { boxShadow: 'red' },
      };
    default:
      return {};
  }
};

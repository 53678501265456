import { useContext, useEffect, useRef, useState } from 'react';

import { MeContext } from 'auth/AuthContextProvider';
import { HomeSidebar } from 'components/HomeSidebar';
import { Layout } from 'components/Layout';
import { CustomLayoutBouygue } from 'components/CustomLayoutBouygue';

import {
  Center,
  Text,
  Stack,
  VStack,
  RadioGroup,
  Radio,
  Spinner,
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Box,
  Input,
  InputRightAddon,
  InputGroup,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Modal,
  TableContainer,
  Table,
  Thead,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  Tr,
  Th,
  Tbody,
  Td,
} from '@chakra-ui/react';
import { Panel } from 'components/Panel';
import { CheckIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { Button } from 'components/Button';
import { CitySearch } from 'components/CitySearch';
import { CrossIcon } from 'components/Icons';

type taux = {
  duree: number;
  interet: number;
  assurance: number;
};

export const MensualitePage = () => {
  const [counter, setCounter] = useState<number>(0);
  const counterRef = useRef<number>(0);
  const [resultatMeteo, setResultatMeteo] = useState<taux[] | undefined>(undefined);

  const [usage, setUsage] = useState<string>('RES_PRINCIPALE');
  const [typeLogement, setTypeLogement] = useState<string>('LOGEMENT_NEUF');
  const [logementNeufSousType, setLogementNeufSousType] = useState<string>('COLLECTIF');
  const [logementAncienSousType, setLogementAncienSousType] = useState<string>('TRAVAUX');

  const [apport, setApport] = useState<string>('20000');
  const [montant, setMontant] = useState<string>('150000');
  const [duree, setDuree] = useState<number>(25);
  const [tx, setTx] = useState<string>('3.77');
  const [txa, setTxa] = useState<string>('0.3');

  const [primoAccedant, setPrimoAccedant] = useState<number>(1);
  const [zone, setZone] = useState<string>('B1');
  const [resetZone, SetResetZone] = useState<number>(0);
  const [nbOccupant, setNbOccupant] = useState<number>(2);
  const [rfr, setRfr] = useState<string>('20000');

  const [resultLoading, setResultLoading] = useState<boolean>(false);
  const [resultat, setResultat] = useState<any>(undefined);

  const [targetTableau, setTargetTableau] = useState<any>(undefined);

  const { isOpen, onOpen, onClose } = useDisclosure();

  async function Callcalculatrice() {
    setResultLoading(true);
    setResultat(undefined);

    let cout_operation: number = Number(montant) * 100;
    let travaux = 0;
    if (
      typeLogement === 'LOGEMENT_ANCIEN' &&
      primoAccedant &&
      logementAncienSousType === 'TRAVAUX'
    ) {
      const pourcent: number = cout_operation * 0.26;
      travaux = pourcent;
      cout_operation -= pourcent;
    }

    await fetch('https://www.monemprunt.com/api/calculette/mensualite', {
      method: 'POST',
      headers: new Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify({
        usage: usage,
        assurance_pp: { quotite_emprunteur1: 100, type: 'CRD_CONST' },
        garantie_pp: 'CL',
        assurance_ptz: { quotite_emprunteur1: 100, type: 'KI' },
        garantie_ptz: 'CL',
        taux_duree: {
          assurance: Math.round(Number(txa) * 100),
          interet: Math.round(Number(tx) * 100),
          duree: Number(duree) * 12,
        },
        is_taux_loading: false,
        nombre_personnes_foyer: nbOccupant,
        primo_accedant: usage === 'RES_PRINCIPALE' && primoAccedant ? true : false,
        type_projet: typeLogement,
        apport: Number(apport) * 100,
        revenu_fiscal_reference: Number(rfr) * 100,
        type_neuf: logementNeufSousType,
        travaux: travaux,
        cout_operation: cout_operation,
        zone: zone,
        avec_mentions_legales: false,
        avec_tableau_amortissement: true,
        calculer_annuites: true,
      }),
    })
      .then((res) =>
        res.json().then((result) => {
          console.log(result);
          if (res.status === 200) {
            setResultat(result);
          } else {
            setResultat(undefined);
          }
        }),
      )
      .catch((err) => {
        console.log(err);
        setResultat(undefined);
      })
      .finally(() => setResultLoading(false));
  }

  async function launcherCallCalculatrice(actualCounter: number) {
    await new Promise((f) => setTimeout(f, 400));
    if (counterRef.current === actualCounter) Callcalculatrice();
  }

  useEffect(() => {
    if (resultatMeteo) {
      setResultLoading(true);
      setResultat(undefined);
      launcherCallCalculatrice(counter);
      counterRef.current = counter;
      setCounter(counter + 1);
    }
  }, [
    usage,
    typeLogement,
    logementNeufSousType,
    logementAncienSousType,
    apport,
    montant,
    primoAccedant,
    zone,
    nbOccupant,
    rfr,
    duree,
    tx,
    txa,
    resultatMeteo,
  ]);

  function generateTableauAmortissement(pret: any) {
    const count = pret.capital_rembourse.length;
    let tableau = <></>;
    for (let i = 0; i < count; i++) {
      tableau = (
        <>
          {tableau}
          <Tr>
            <Td isNumeric>{i + 1}</Td>
            <Td isNumeric>
              {targetTableau ? Math.round(targetTableau.capital_rembourse[i] / 100) + ' €' : ''}
            </Td>
            <Td isNumeric>
              {targetTableau ? Math.round(targetTableau.interet[i] / 100) + ' €' : ''}
            </Td>
            <Td isNumeric>
              {targetTableau ? Math.round(targetTableau.assurance[i] / 100) + ' €' : ''}
            </Td>
            <Td isNumeric>
              {targetTableau ? Math.round(targetTableau.total_echeance[i] / 100) + ' €' : ''}
            </Td>
          </Tr>
        </>
      );
    }

    return <>{tableau}</>;
  }

  async function getMeteo() {
    await fetch('https://www.monemprunt.com/api/calculette/taux', {
      method: 'GET',
    })
      .then((res) =>
        res.json().then((result) => {
          setResultatMeteo(result);
          setTx(result[duree - 1].interet / 100 + '');
          setTxa(result[duree - 1].assurance / 100 + '');
        }),
      )
      .catch((err) => setResultatMeteo(undefined));
  }

  useEffect(() => {
    getMeteo();
  }, []);

  if (resultatMeteo === undefined) {
    return (
      <>
        <Center width={'100%'}>
          <Text pl={'20px'} width={'100%'} as="h3" fontSize={'25px'} fontWeight="semibold" my={4}>
            Calculatrice de mensualité
          </Text>
        </Center>
        <Box mt={'50px'}>
          <Center>
            <Spinner size="xl" mb="5px" color="teal" thickness="4px" speed="0.65s"></Spinner>
          </Center>
          <Center>
            <Text mt={'10px'}>Chargement de la météo des taux</Text>
          </Center>
        </Box>
      </>
    );
  }

  return (
    <>
      <Center width={'100%'}>
        <Text pl={'20px'} width={'100%'} as="h3" fontSize={'25px'} fontWeight="semibold" my={4}>
          Calculatrice de mensualité
        </Text>
      </Center>
      <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: 8, lg: 8 }}>
        <VStack
          as="fieldset"
          align={'left'}
          flex={1}
          spacing={4}
          width={{ base: '100%', lg: '50%' }}
        >
          <Panel p={'30px'} w="100%">
            <Text mt={'10px'} mb={'5px'}>
              Type de résidence
            </Text>
            <RadioGroup value={usage}>
              <Stack spacing={5} direction="row">
                <Radio
                  colorScheme="teal"
                  value={'RES_PRINCIPALE'}
                  onClick={() => setUsage('RES_PRINCIPALE')}
                >
                  Principale
                </Radio>
                <Radio
                  colorScheme="teal"
                  value={'RES_SECONDAIRE'}
                  onClick={() => setUsage('RES_SECONDAIRE')}
                >
                  Secondaire
                </Radio>
              </Stack>
            </RadioGroup>
          </Panel>

          <Panel p={'30px'} w="100%">
            <Text as="b">Information du bien </Text>
            <Text mt={'10px'} mb={'5px'}>
              Projet{' '}
            </Text>
            <RadioGroup value={typeLogement}>
              <Stack spacing={5} direction="row">
                <Radio
                  colorScheme="teal"
                  value={'LOGEMENT_NEUF'}
                  onClick={() => setTypeLogement('LOGEMENT_NEUF')}
                >
                  Neuf
                </Radio>
                <Radio
                  colorScheme="teal"
                  value={'LOGEMENT_ANCIEN'}
                  onClick={() => setTypeLogement('LOGEMENT_ANCIEN')}
                >
                  Ancien
                </Radio>
              </Stack>
            </RadioGroup>
            <Text mt={'10px'} mb={'5px'}>
              Montant du bien
            </Text>
            <Text fontSize={'12px'}>
              Montant réel du bien hors frais annexes. (ne pas intégrer de frais de notaire ou frais
              de garantie par exemple)
            </Text>
            <InputGroup>
              <Input
                value={montant}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, '');
                  setMontant(value);
                }}
              ></Input>
              <InputRightAddon h={'45px'}>Euros</InputRightAddon>
            </InputGroup>
          </Panel>
          <Panel p={'30px'} w="100%">
            <Text as="b">Information de financement </Text>
            <Text mt={'10px'} mb={'5px'}>
              Apport personnel
            </Text>{' '}
            <InputGroup>
              <Input
                value={apport}
                onChange={(e) => setApport(e.target.value.replace(/\D/g, ''))}
              ></Input>{' '}
              <InputRightAddon h={'45px'}>Euros</InputRightAddon>
            </InputGroup>
            <Text mb={'5px'} mt={'20px'}>
              Durée souhaitée : <b>{duree}</b> ans
            </Text>{' '}
            <Slider
              aria-label="slider-ex-6"
              min={10}
              max={25}
              value={duree}
              onChange={(val) => {
                setDuree(val);
                setTx(resultatMeteo[val - 1].interet / 100 + '');
                setTxa(resultatMeteo[val - 1].assurance / 100 + '');
              }}
            >
              <SliderMark ml={'-4px'} pt={'5px'} value={10}>
                10
              </SliderMark>
              <SliderMark ml={'-4px'} pt={'5px'} value={15}>
                15
              </SliderMark>
              <SliderMark ml={'-4px'} pt={'5px'} value={20}>
                20
              </SliderMark>
              <SliderMark ml={'-4px'} pt={'5px'} value={25}>
                25
              </SliderMark>
              <SliderTrack bg={'lightgray'}>
                <SliderFilledTrack bg={'teal'} />
              </SliderTrack>
              <SliderThumb bg={'teal'}></SliderThumb>{' '}
            </Slider>
            <Stack direction={'row'} spacing={'30px'} mt={'20px'}>
              <Box>
                <Text mt={'10px'} mb={'5px'}>
                  Taux emprunt
                </Text>
                <InputGroup>
                  <Input
                    value={tx}
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^0-9.]+/g, '');
                      const split = value.split('.');
                      if (split.length > 2) {
                        value = split[0] + '.' + split[1];
                      }
                      setTx(value.replace(/[^0-9.]+/g, ''));
                    }}
                  ></Input>
                  <InputRightAddon h={'45px'}>%</InputRightAddon>
                </InputGroup>
              </Box>
              <Box>
                <Text mt={'10px'} mb={'5px'}>
                  Taux assurance
                </Text>
                <InputGroup>
                  <Input
                    value={txa}
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^0-9.]+/g, '');
                      const split = value.split('.');
                      if (split.length > 2) {
                        value = split[0] + '.' + split[1];
                      }
                      setTxa(value.replace(/[^0-9.]+/g, ''));
                    }}
                  ></Input>
                  <InputRightAddon h={'45px'}>%</InputRightAddon>
                </InputGroup>
              </Box>
            </Stack>
          </Panel>
          {usage === 'RES_PRINCIPALE' && (
            <Panel p={'30px'} w="100%">
              <Text as="b">Information relative au PTZ </Text>

              <Text mt={'10px'} mb={'5px'}>
                Primo Accèdant
              </Text>
              <RadioGroup value={primoAccedant}>
                <Stack spacing={5} direction="row">
                  <Radio colorScheme="teal" value={1} onClick={() => setPrimoAccedant(1)}>
                    Oui
                  </Radio>
                  <Radio colorScheme="teal" value={0} onClick={() => setPrimoAccedant(0)}>
                    Non
                  </Radio>
                </Stack>
              </RadioGroup>
              {primoAccedant === 1 && (
                <>
                  {typeLogement === 'LOGEMENT_NEUF' && (
                    <>
                      <Text mt={'10px'} mb={'5px'}>
                        Logement individuel ou collectif ?
                      </Text>
                      <RadioGroup value={logementNeufSousType}>
                        <Stack spacing={5} direction="column">
                          <Radio
                            colorScheme="teal"
                            value={'INDIVIDUEL'}
                            onClick={() => setLogementNeufSousType('INDIVIDUEL')}
                          >
                            Individuel
                          </Radio>
                          <Radio
                            colorScheme="teal"
                            value={'COLLECTIF'}
                            onClick={() => setLogementNeufSousType('COLLECTIF')}
                          >
                            Collectif
                          </Radio>
                          <Radio
                            colorScheme="teal"
                            value={'PSLA'}
                            onClick={() => setLogementNeufSousType('PSLA')}
                          >
                            Logement social (PSLA ou BRS)
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </>
                  )}
                  {typeLogement !== 'LOGEMENT_NEUF' && (
                    <>
                      <Text mt={'10px'} mb={'5px'}>
                        Avec travaux ou social ?
                      </Text>
                      <RadioGroup value={logementAncienSousType}>
                        <Stack spacing={5} direction="column">
                          <Radio
                            colorScheme="teal"
                            value={'NOTRAVAUX'}
                            onClick={() => setLogementAncienSousType('NOTRAVAUX')}
                          >
                            Montant de travaux inférieur à 25% du coût total de l’opération
                          </Radio>
                          <Radio
                            colorScheme="teal"
                            value={'TRAVAUX'}
                            onClick={() => setLogementAncienSousType('TRAVAUX')}
                          >
                            Montant de travaux supérieur à 25% du coût total de l’opération
                          </Radio>
                          <Radio
                            colorScheme="teal"
                            value={'SOCIAL'}
                            onClick={() => setLogementAncienSousType('SOCIAL')}
                          >
                            Rachat d’un logement social
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </>
                  )}
                  <Text mt={'10px'} mb={'5px'}>
                    Zone PTZ du bien
                  </Text>
                  <Text fontSize={'12px'}>
                    La ville permet de connaitre la zone PTZ, sélectionnez là ou entrer directement
                    la zone cible.
                  </Text>
                  <CitySearch
                    onSelect={(city) => {
                      setZone(city.zone_ptz);
                    }}
                    resetZoneTrigger={resetZone}
                  />
                  <RadioGroup value={zone} mt={'10px'}>
                    <Stack spacing={5} direction="row">
                      <Radio
                        colorScheme="teal"
                        value={'A'}
                        onClick={() => {
                          setZone('A');
                          SetResetZone(resetZone + 1);
                        }}
                      >
                        A
                      </Radio>
                      <Radio
                        colorScheme="teal"
                        value={'A BIS'}
                        onClick={() => {
                          setZone('A BIS');
                          SetResetZone(resetZone + 1);
                        }}
                      >
                        A Bis
                      </Radio>
                      <Radio
                        colorScheme="teal"
                        value={'B1'}
                        onClick={() => {
                          setZone('B1');
                          SetResetZone(resetZone + 1);
                        }}
                      >
                        B1
                      </Radio>
                      <Radio
                        colorScheme="teal"
                        value={'B2'}
                        onClick={() => {
                          setZone('B2');
                          SetResetZone(resetZone + 1);
                        }}
                      >
                        B2
                      </Radio>
                      <Radio
                        colorScheme="teal"
                        value={'C'}
                        onClick={() => {
                          setZone('C');
                          SetResetZone(resetZone + 1);
                        }}
                      >
                        C
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  <Text mt={'10px'} mb={'5px'}>
                    Nombre d'occupants du futur logement{' '}
                  </Text>
                  <Slider
                    aria-label="slider-ex-6"
                    min={1}
                    max={6}
                    pt={'20px'}
                    value={nbOccupant}
                    onChange={(val) => setNbOccupant(val)}
                  >
                    <SliderMark ml={'-4px'} pt={'5px'} value={1}>
                      1
                    </SliderMark>
                    <SliderMark ml={'-4px'} pt={'5px'} value={2}>
                      2
                    </SliderMark>
                    <SliderMark ml={'-4px'} pt={'5px'} value={3}>
                      3
                    </SliderMark>
                    <SliderMark ml={'-4px'} pt={'5px'} value={4}>
                      4
                    </SliderMark>
                    <SliderMark ml={'-4px'} pt={'5px'} value={5}>
                      5
                    </SliderMark>
                    <SliderMark ml={'-6px'} pt={'5px'} value={6}>
                      5+
                    </SliderMark>
                    <SliderTrack bg={'lightgray'}>
                      <SliderFilledTrack bg={'teal'} />
                    </SliderTrack>
                    <SliderThumb bg={'teal'}></SliderThumb>{' '}
                  </Slider>
                  <Text pt="20px" mt={'10px'} mb={'5px'}>
                    Revenu Fiscal de référence
                  </Text>{' '}
                  <InputGroup>
                    <Input
                      value={rfr}
                      onChange={(e) => setRfr(e.target.value.replace(/\D/g, ''))}
                    ></Input>{' '}
                    <InputRightAddon h={'45px'}>Euros</InputRightAddon>
                  </InputGroup>
                </>
              )}
            </Panel>
          )}
        </VStack>
        <VStack flex={1} spacing={4} align={'left'} width={{ base: '100%', lg: '50%' }}>
          <Panel p={'30px'} width={'100%'}>
            <Text as="b" mb="10px">
              Résultat de la simulation :
            </Text>
            {resultLoading && (
              <Box mt={'50px'}>
                <Center>
                  <Spinner size="xl" mb="5px" color="teal" thickness="4px" speed="0.65s"></Spinner>
                </Center>
                <Center>
                  <Text mt={'10px'}>Chargement du resultat</Text>
                </Center>
              </Box>
            )}
            {!resultLoading && resultat && (
              <>
                <Text mt={'20px'}>
                  Le coût total du financement est de <b>{resultat.montant_total / 100}</b> €.
                  <br></br>
                  {resultat.montant_ptz ? (
                    <>
                      Cela inclut un PTZ d'un montant de <b>{resultat.montant_ptz / 100} </b> €.
                    </>
                  ) : (
                    <></>
                  )}
                </Text>
                <Text mt={'20px'}>
                  {' '}
                  La mensualité est à <b>{resultat.mensualite / 100}</b> € / mois
                </Text>

                <Text fontSize={'14px'} mt={'20px'}>
                  Ces montants prennent en compte l'ensemble des frais annexes, incluant :
                </Text>
                <List spacing={3} fontSize={'14px'}>
                  <ListItem>
                    <ListIcon as={CheckIcon} color="green.500" />
                    Frais de notaire{' '}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckIcon} color="green.500" />
                    Frais de garantie{' '}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckIcon} color="green.500" />
                    Interêts du financement{' '}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckIcon} color="green.500" />
                    Assurance emprunteurs{' '}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CrossIcon} color="red.500" />
                    Frais d'agence éventuels non pris en compte
                  </ListItem>
                </List>

                <Center>
                  {resultat?.tableau_ammortissement?.pret_principal && (
                    <Button
                      mt={'20px'}
                      onClick={() => {
                        setTargetTableau(resultat.tableau_ammortissement.pret_principal);
                        onOpen();
                      }}
                    >
                      Détail prêt principal
                    </Button>
                  )}
                </Center>
                <Center>
                  {resultat?.tableau_ammortissement?.pret_secondaire && (
                    <Button
                      mt={'20px'}
                      onClick={() => {
                        setTargetTableau(resultat.tableau_ammortissement.pret_secondaire);
                        onOpen();
                      }}
                    >
                      Détail prêt secondaire
                    </Button>
                  )}
                </Center>
                <Center>
                  {resultat?.tableau_ammortissement?.pret_relais && (
                    <Button
                      mt={'20px'}
                      onClick={() => {
                        setTargetTableau(resultat.tableau_ammortissement.pret_relais);
                        onOpen();
                      }}
                    >
                      Détail prêt relais
                    </Button>
                  )}
                </Center>
                <Center>
                  {resultat?.tableau_ammortissement?.pret_taux_zero && (
                    <Button
                      mt={'20px'}
                      onClick={() => {
                        setTargetTableau(resultat.tableau_ammortissement.pret_taux_zero);
                        onOpen();
                      }}
                    >
                      Détail prêt à taux zéro
                    </Button>
                  )}
                </Center>
                <Center>
                  {resultat?.tableau_ammortissement?.total_prets && (
                    <Button
                      mt={'20px'}
                      onClick={() => {
                        setTargetTableau(resultat.tableau_ammortissement.total_prets);
                        onOpen();
                      }}
                    >
                      Détail total prêt
                    </Button>
                  )}
                </Center>
              </>
            )}

            {!resultLoading && !resultat && (
              <Box mt={'50px'}>
                <Center>
                  <WarningTwoIcon fontSize={'60px'} color="red.200"></WarningTwoIcon>
                </Center>
                <Center>
                  <Text color={'red'} mt={'10px'}>
                    Oups
                  </Text>
                </Center>
                <Center>
                  <Text mt={'10px'} color={'red'}>
                    Aucun résultat a afficher
                  </Text>
                </Center>
              </Box>
            )}
          </Panel>
        </VStack>
      </Stack>

      <Modal onClose={onClose} isOpen={isOpen} size="xl" scrollBehavior={'inside'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Tableau d'amortissement</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Années</Th>
                    <Th>Ammortie</Th>
                    <Th>Intérét</Th>
                    <Th>Assurance</Th>
                    <Th>Total</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {targetTableau ? (
                    <>{generateTableauAmortissement(targetTableau)}</>
                  ) : (
                    <>Rien a afficher</>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export const CalculatriceMensualitePageRoute = () => {
  const me = useContext(MeContext);

  return (
    <Layout
      courtier={me.courtier!}
      utilisateur={me.utilisateur!}
      sidebar={<HomeSidebar withKPI={false} withFilter={false} utilisateur={me.utilisateur!} />}
      leftSidebar={true}
    >
      <MensualitePage />
    </Layout>
  );
};

export const PublicCalculatriceMensualitePageRoute = () => {
  return (
    <CustomLayoutBouygue>
      <MensualitePage />{' '}
    </CustomLayoutBouygue>
  );
};

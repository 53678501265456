import { BoxProps, Center, Text, Box } from '@chakra-ui/react';
import { useState } from 'react';
import { NotAllowedIcon } from '@chakra-ui/icons';
import { StatPanelPieChart } from 'components/StatsViewer/StatPanelPieChart';
import { StatPanelBarChart } from 'components/StatsViewer/StatPanelBarChart';
import { StatPanelColChart } from 'components/StatsViewer/StatPanelColChart';
import { StatPanelValue } from 'components/StatsViewer/StatPanelValue';
import { Utilisateur } from 'types';

import { StatFilter, FilterDefinition } from './StatFilter';

export type Props = {
  scope?: string;
  utilisateur: Utilisateur;
} & BoxProps;

export const StatsViewer = ({ scope, utilisateur, ...props }: Props) => {
  const [filterDefinition, setFilterDefinition] = useState<FilterDefinition | undefined>(undefined);

  function setFilterDefinitionMethod(filter: FilterDefinition | undefined) {
    console.log(filter);
    setFilterDefinition(filter);
  }

  //inaccessible pour conseiller
  if (utilisateur.role === 'CIMMO' || utilisateur.organisation?.autoriser_statistique === false) {
    return (
      <>
        <Box mt={'40px'} mb={'40px'} w={'100%'}>
          <Center w={'100%'}>
            <NotAllowedIcon color="red" boxSize={9} mb={'15px'} />
          </Center>
          <Center>
            <Text as="h3" fontSize={'xl'} fontWeight="semibold" color="red">
              Page statistique inaccessible pour votre profil
            </Text>
          </Center>
        </Box>
      </>
    );
  }

  return (
    <>
      <StatFilter action={setFilterDefinitionMethod} utilisateur={utilisateur} />
      <Center width={'100%'}>
        <Text pl={'20px'} width={'100%'} as="h3" fontSize={'25px'} fontWeight="semibold" my={4}>
          Vision rapide
        </Text>
      </Center>

      <StatPanelValue
        label="Total Dossiers"
        targetQuery="total"
        filterDefinition={filterDefinition}
      />
      <StatPanelValue
        label="Mandats signés"
        targetQuery="mandat"
        filterDefinition={filterDefinition}
      />
      <StatPanelValue
        label="Envoyés en banque"
        targetQuery="enbanque"
        filterDefinition={filterDefinition}
      />
      <StatPanelValue
        label="Dossiers validés"
        targetQuery="valide"
        filterDefinition={filterDefinition}
      />
      <Center width={'100%'}>
        <Text pl={'20px'} width={'100%'} as="h3" fontSize={'25px'} fontWeight="semibold" my={4}>
          Zoom emprunteur
        </Text>
      </Center>

      <StatPanelBarChart
        label="Régions d’achat"
        grand
        marginAreaPourcent={30}
        targetQuery="reg"
        sortByValue
        filterDefinition={filterDefinition}
      />
      <StatPanelPieChart
        label="Situation Emprunteur principal"
        sortByValue
        targetQuery="emp1_cat_pro"
        filterDefinition={filterDefinition}
      />
      <StatPanelBarChart
        label="Tranches d’âge"
        targetQuery="emp1_tranche_age"
        filterDefinition={filterDefinition}
      />
      <StatPanelBarChart
        label="Tranches salaires"
        grand
        marginAreaPourcent={20}
        targetQuery="emp1_tranche_salaire"
        filterDefinition={filterDefinition}
      />
      <Center width={'100%'}>
        <Text pl={'20px'} width={'100%'} as="h3" fontSize={'25px'} fontWeight="semibold" my={4}>
          Zoom projet
        </Text>
      </Center>

      <StatPanelPieChart
        label="Type projet"
        targetQuery="prj_type"
        filterDefinition={filterDefinition}
      />
      <StatPanelColChart
        label="Evaluation Projet"
        targetQuery="prj_eval"
        filterDefinition={filterDefinition}
      />
      <StatPanelPieChart
        label="Maturité projet"
        targetQuery="prj_maturite"
        filterDefinition={filterDefinition}
      />
      <StatPanelPieChart
        label="Usage projet"
        targetQuery="prj_usage"
        filterDefinition={filterDefinition}
      />
      {/*besoin de financement*/}
    </>
  );
};

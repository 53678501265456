import { createIcon } from '@chakra-ui/react';

export const PencilIcon = createIcon({
  displayName: 'PencilIcon',
  viewBox: '0 0 16 17',
  path: (
    <path
      fill="currentColor"
      d="M15.3876 2.725L13.7751 1.1125C13.4376 0.775003 12.9501 0.550003 12.4626 0.550003C11.9751 0.550003 11.4876 0.737503 11.1501 1.1125L0.912611 11.3125C0.875111 11.35 0.875111 11.3875 0.837611 11.425V11.4625V11.5L0.0876112 16C0.0501112 16.1125 0.125111 16.225 0.200111 16.3375C0.275111 16.4125 0.350111 16.45 0.462611 16.45C0.500111 16.45 0.500111 16.45 0.537611 16.45L5.03761 15.7H5.07511H5.11261C5.15011 15.7 5.18761 15.6625 5.22511 15.625L15.4251 5.425C16.1376 4.675 16.1376 3.475 15.3876 2.725ZM2.48761 15.325L1.13761 13.975L1.40011 12.325L4.13761 15.0625L2.48761 15.325ZM10.5126 6.5125L4.40011 12.625C4.32511 12.7 4.21261 12.7375 4.13761 12.7375C4.02511 12.7375 3.95011 12.7 3.87511 12.625C3.72511 12.475 3.72511 12.25 3.87511 12.1L9.98761 5.9875C10.1376 5.8375 10.3626 5.8375 10.5126 5.9875C10.6626 6.1375 10.6626 6.3625 10.5126 6.5125ZM13.1751 6.55L9.95011 3.325L10.9251 2.35L14.1501 5.575L13.1751 6.55Z"
    />
  ),
});

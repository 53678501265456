import { createIcon } from '@chakra-ui/react';

export const StarIcon = createIcon({
  displayName: 'StarIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      stroke=" black"
      d="M15.143 5.296l-4.316-.657L8.9.542a1.067 1.067 0 00-.38-.397 1.005 1.005 0 00-1.039 0c-.158.095-.289.232-.38.397L5.174 4.64l-4.316.656a.979.979 0 00-.502.235c-.143.126-.249.291-.307.477a1.095 1.095 0 00-.02.576c.045.19.14.363.273.499l3.122 3.19-.737 4.503c-.031.192-.01.39.06.571.07.181.187.338.338.453a.962.962 0 001.053.08L8 13.753l3.86 2.125a.962.962 0 001.053-.08 1.04 1.04 0 00.339-.453c.07-.18.09-.379.06-.571l-.738-4.504L15.7 7.083c.133-.136.227-.31.272-.499.045-.19.038-.388-.02-.574a1.052 1.052 0 00-.306-.477.98.98 0 00-.5-.236h-.002z"
    />
  ),
});

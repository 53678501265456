import { useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { getParameterByNameOnUrl } from 'utils/helpers';
import { Image, Center, Box, Text } from '@chakra-ui/react';
import { InputField } from 'utils/form';
import { Button } from 'components/Button';
import { auth } from 'auth/AuthContextProvider';
import { getLogo } from 'utils/marque';
import { useHistory } from 'react-router-dom';
import { spaceRegExp } from 'utils/helpers';

const validationSchemaLogin = yup.object().shape({
  email: yup
    .string()
    .matches(spaceRegExp, "Le mail ne doit pas contenir d'espaces")
    .email('Format du mail invalide')
    .required('Mail requis'),
  password: yup.string().required('Mot de passe requis'),
});

export type LoginValue = {
  email: string;
  password: string;
};

const validationSchemaResetPassword = yup.object().shape({
  email: yup
    .string()
    .matches(spaceRegExp, "Le mail ne doit pas contenir d'espaces")
    .email('Format du mail invalide')
    .required('Mail requis'),
});

export type ResetPasswordValue = {
  email: string;
};

export type error = {
  code: string;
  message: string;
};

export const LoginPage = ({ ...props }) => {
  const [errorLogin, setLoginErrorMessage] = useState<string | null>(null);
  const [successLogin, setLoginSuccessMessage] = useState<string | null>(null);
  const [errorRAZ, setRAZErrorMessage] = useState<string | null>(null);
  const [successRAZ, setRAZSuccessMessage] = useState<string | null>(null);
  const [razPassword, setrazPassword] = useState<boolean>(false);

  const history = useHistory();

  // Form declaration
  let validationSchema = validationSchemaResetPassword;
  const formikResetPassword = useFormik({
    validationSchema,
    initialValues: {
      email: '',
    },
    onSubmit: (values: ResetPasswordValue, actions) => {
      setRAZErrorMessage(null);
      setRAZSuccessMessage(null);
      fetch('/API/auth/password-recovery', {
        method: 'POST',
        body: JSON.stringify({ email: values.email }),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      })
        .then((res) => {
          setRAZSuccessMessage(
            'Un mail contenant la procédure de récupération de mot de passe vous a été envoyé.',
          );
          history.push('/');

          actions.setSubmitting(false);
          actions.resetForm();
        })
        .catch(function (error) {
          actions.setSubmitting(false);
          setRAZErrorMessage('Erreur rencontrée: ' + error.message);
        });
    },
  });

  // activation du mode reset pré-remplis suite a un mail de migration user
  const reset_mail: string | null = getParameterByNameOnUrl('reset-mail');
  if (
    !razPassword &&
    formikResetPassword.values.email === '' &&
    reset_mail !== null &&
    reset_mail !== ''
  ) {
    setrazPassword(true);
    formikResetPassword.setValues(
      {
        email: reset_mail,
      },
      true,
    );
  }
  // Form declaration
  validationSchema = validationSchemaLogin;

  const formikLogin = useFormik({
    validationSchema,
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values: LoginValue, actions) => {
      setLoginErrorMessage(null);
      setLoginSuccessMessage(null);
      auth
        .signInWithEmailAndPassword(values.email, values.password)
        .then(() => {
          setLoginSuccessMessage('Connexion réussie, redirection en cours');
          actions.setSubmitting(false);
          actions.resetForm();
        })
        .catch((error) => {
          actions.setSubmitting(false);
          setLoginErrorMessage('Erreur rencontrée: ' + error.message);
        });
    },
  });

  return (
    <>
      <Box position="fixed" width="100%" bottom="0" right="0">
        <Image
          float="right"
          width={{ base: '100%', lg: '70%' }}
          src="/login_background.svg"
        ></Image>
      </Box>
      <Box
        margin="0px"
        padding="0px"
        width="100%"
        height="100%"
        backgroundColor="#E8F4F4"
        paddingTop="100px"
      >
        <Box
          position="relative"
          marginLeft={{ base: 'calc( 50% - 175px )', lg: '200px' }}
          width="350px"
        >
          <Text
            fontWeight="900"
            fontSize="26px"
            marginLeft="20px"
            color="white"
            backgroundColor="#349FA1"
            padding="5px"
            transform="skew(-0.00turn, -5deg)"
            width="fit-content"
          >
            CONNECTEZ-VOUS
          </Text>
          <Text
            fontWeight="900"
            fontSize="26px"
            marginLeft="90px"
            marginTop="5px"
            color="white"
            backgroundColor="#F8B632"
            padding="5px"
            transform="skew(-0.00turn, -5deg)"
            width="fit-content"
          >
            À VOTRE ESPACE
          </Text>

          <Box
            marginLeft={'0px'}
            marginTop="40px"
            padding="20px"
            width="350px"
            height="540px"
            zIndex="1"
            backgroundColor="white"
            borderRadius="-webkit-border-radius: 10px; -moz-border-radius: 10px; border-radius: 10px;"
            boxShadow="0px 0px 30px 00px rgba(0,0,0,0.1)"
            textAlign="center"
          >
            <Center marginBottom="10px">
              <Image maxWidth="100%" marginWidth="auto" src={getLogo()}></Image>
            </Center>
            {!razPassword && (
              <>
                <Center marginBottom="10px">
                  <Text fontWeight="900" width="fit-content">
                    Connexion
                  </Text>
                </Center>
                <FormikProvider value={formikLogin}>
                  <Box width="100%" alignItems="center">
                    <form onSubmit={formikLogin.handleSubmit}>
                      <InputField
                        name="email"
                        label="E-mail"
                        placeholder="Ex: michel.martin@gmail.com"
                        isRequired
                        forceColumn={true}
                      />
                      <br />
                      <InputField
                        name="password"
                        label="Mot de passe"
                        placeholder="password"
                        isRequired
                        type="password"
                        forceColumn={true}
                      />
                    </form>
                    <br />
                    {successLogin && (
                      <Center>
                        <Text color="green.500" fontSize="sm" fontWeight="semibold">
                          {successLogin}
                        </Text>
                      </Center>
                    )}

                    {errorLogin && (
                      <Center>
                        <Text color="red.500" fontSize="sm" fontWeight="semibold">
                          {errorLogin}
                        </Text>
                      </Center>
                    )}

                    <Center>
                      <Button
                        isDisabled={!formikLogin.isValid || !formikLogin.dirty}
                        isLoading={formikLogin.isSubmitting}
                        onClick={formikLogin.submitForm}
                      >
                        Connexion
                      </Button>
                    </Center>
                  </Box>
                </FormikProvider>
                <Text as="u" mt={3} onClick={() => setrazPassword(true)} fontSize="md">
                  Mot de passe oublié ?
                </Text>
              </>
            )}

            {razPassword && (
              <>
                <Center marginBottom="10px">
                  <Text fontWeight="900" width="fit-content">
                    Demande de création ou réinitialisation de mot de passe
                  </Text>
                </Center>
                <FormikProvider value={formikResetPassword}>
                  <Box w={'100%'} alignItems="center">
                    {successRAZ && (
                      <Center>
                        <Text color="green.500" fontSize="sm" fontWeight="semibold">
                          {successRAZ}
                        </Text>
                      </Center>
                    )}

                    {!successRAZ && (
                      <>
                        <form onSubmit={formikResetPassword.handleSubmit}>
                          <InputField
                            name="email"
                            label="E-mail"
                            placeholder="Ex: michel.martin@gmail.com"
                            isRequired
                            forceColumn={true}
                          />
                        </form>
                        <br />

                        {errorRAZ && (
                          <Center>
                            <Text color="red.500" fontSize="sm" fontWeight="semibold">
                              {errorRAZ}
                            </Text>
                          </Center>
                        )}

                        <Center>
                          <Button
                            isDisabled={!formikResetPassword.isValid || !formikResetPassword.dirty}
                            isLoading={formikResetPassword.isSubmitting}
                            onClick={formikResetPassword.submitForm}
                          >
                            Valider
                          </Button>
                        </Center>
                      </>
                    )}
                  </Box>
                </FormikProvider>
                <Text as="u" mt={3} onClick={() => setrazPassword(false)} fontSize="md">
                  Connexion ?
                </Text>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export const LoginPageRoute = () => {
  return <LoginPage />;
};

import { createIcon } from '@chakra-ui/react';

import { generateDataURIFromSVG } from 'utils/helpers';

const viewBox = '0 0 10 11';
const icon = {
  displayName: 'CrossIcon',
  viewBox,
  path: (
    <path
      clipRule="evenodd"
      fill="currentColor"
      fillRule="evenodd"
      d="M9.707 2.207A1 1 0 008.293.793L5 4.086 1.707.793A1 1 0 00.293 2.207L3.586 5.5.293 8.793a1 1 0 101.414 1.414L5 6.914l3.293 3.293a1 1 0 001.414-1.414L6.414 5.5l3.293-3.293z"
    />
  ),
};

export const CrossIcon = createIcon(icon);
export const crossIconDataURI = generateDataURIFromSVG(viewBox, icon.path);

// Exemples de dossiers & autres objets - Utilisé principalement pour Storybook
import {
  Client,
  Commentaire,
  Courtier,
  Organisation,
  Projet,
  ProjetAlgolia,
  Utilisateur,
} from 'types';

export const commentaire1: Commentaire = {
  date_creation: new Date(),
  detail:
    "Bonjour C&eacute;line, Mr MARRET m'indique par e-mail ce jour vouloir avancer avec vous sur une proposition &agrave; 10 ans. Vous pouvez donc le contacter pour avancer avec lui sur cette dur&eacute;e via la cr&eacute;dit foncier",
  id: 1,
  nom_utilisateur: 'martel',
  prenom_utilisateur: 'samuel',
  type: 'CONTACT_COMMENT',
};

export const commentaire2: Commentaire = {
  date_creation: new Date(),
  detail:
    "Bonjour C&eacute;line, Mr MARRET m'indique par e-mail ce jour vouloir avancer avec vous sur une proposition &agrave; 10 ans. Vous pouvez donc le contacter pour avancer avec lui sur cette dur&eacute;e via la cr&eacute;dit foncier",
  id: 2,
  nom_utilisateur: 'martel',
  prenom_utilisateur: 'samuel',
  type: 'CONTACT_COMMENT',
};

export const commentaire3: Commentaire = {
  date_creation: new Date(),
  detail:
    "Bonjour C&eacute;line, Mr MARRET m'indique par e-mail ce jour vouloir avancer avec vous sur une proposition &agrave; 10 ans. Vous pouvez donc le contacter pour avancer avec lui sur cette dur&eacute;e via la cr&eacute;dit foncier",
  id: 3,
  nom_utilisateur: 'martel',
  prenom_utilisateur: 'samuel',
  type: 'CONTACT_COMMENT',
};

export const commentaires = [commentaire1, commentaire2, commentaire3];

export const courtierDan: Courtier = {
  id: 660,
  nom: 'Abramov',
  prenom: 'Dan',
  mail: 'dan@abramov.com',
  telephone: '03 01 01 10 10',
  telephone_portable: '06 01 01 10 10',
  url_image: 'https://bit.ly/dan-abramov',
};

export const organisation: Organisation = {
  nom_direction: 'Grand ouest',
  nom_entite: 'Rennes',
  nom_immo: 'entreprise',
};
export const utilisateurJames: Utilisateur = {
  id: 66,
  nom: 'Siphron',
  role: 'roleeee',
  prenom: 'James',
  mail: 'mailuser@dede.fr',
  telephone: '0403020304',
  organisation: organisation,
  url_image: 'https://bit.ly/kent-c-dodds',
  search_key: 'edededede',
};

export const clientRandy: Client = {
  id_client: '1234',
  civilite: 'MONSIEUR',
  login: 'randy.press@gmail.com',
  prenom: 'Randy',
  nom: 'Press',
  profil: 'CLIENT',
  numero: '56808',
  etat: 'A_VALIDER',
  marque_partenaire: 'ALL',
  mel: 'randy.press@gmail.com',
  localite: 'Paris',
  code_postal: '75002',
  mobile: '06 68 34 39 86',
  date_creation: new Date(),
};

export const clientSimple: Client = {
  id_client: '1234',
  civilite: 'MONSIEUR',
  login: 'randy.press@gmail.com',
  profil: 'CLIENT',
  numero: '56808',
  etat: 'A_VALIDER',
  marque_partenaire: 'ALL',
  mel: 'randy.press@gmail.com',
  mobile: '06 68 34 39 86',
  date_creation: new Date(),
};

export const dossierAlgolia: ProjetAlgolia = {
  capacite_achat_max: 665227,
  code_postal_localisation_client: 'TODO',
  code_postal_localisation_projet: '59310',
  date_creation: new Date(),
  date_modification: new Date(),
  dernier_commentaire: 'TODO Dernier Com Dossier',
  etat: 'RETRACTATION',
  etat_relance: 'CLOS',
  eval: 5,
  id: 9621,
  kind: 'dossiers',
  mail: '8363@anonyme.com',
  maturite: 'SIGNE',
  montant: 583230,
  nom: 'Dupoont',
  nom_localisation_client: 'TODO',
  nom_localisation_projet: 'TODO',
  objectID: 'dossier:9621',
  prenom: 'Martin',
  telephone: '0668343986',
  type: 'LOGEMENT_ANCIEN',
  usage: 'RES_PRINCIPALE',
};
export const contactAlgolia: ProjetAlgolia = {
  code_postal_localisation_client: '35000',
  code_postal_localisation_projet: '80000',
  date_creation: new Date(),
  date_modification: new Date(),
  dernier_commentaire: 'TODO Dernier Com Contact',
  etat: 'A_CONTACTER',
  id: 580,
  kind: 'contacts',
  mail: '580@anonyme.com',
  nom: 'anonyme',
  nom_localisation_client: 'Rennes',
  nom_localisation_projet: 'Amiens',
  objectID: 'contact:580',
  prenom: 'anonyme',
  site_immo: 'Welcome courtier',
  telephone: '0123456789',
  type: 'contacts',
};
export const lienAlgolia: ProjetAlgolia = {
  kind: 'liens',
  nom: 'Mr Martin',
  telephone: '0668343097',
  id: 9,
  date_creation: new Date(),
  id_apporteur: 40291,
  prenom_apporteur: 'Nicolas',
  nom_apporteur: 'COMPART',
};
export const lienAlgoliaMail: ProjetAlgolia = {
  kind: 'liens',
  nom: 'Mr Dupont',
  mail: 'azededed.jdejj@azeaze.fr',
  id: 9,
  date_creation: new Date(),
  id_apporteur: 40291,
  prenom_apporteur: 'Nicolas',
  nom_apporteur: 'COMPART',
};
export const ListedossiersAlgolia: ProjetAlgolia[] = [
  dossierAlgolia,
  contactAlgolia,
  lienAlgolia,
  lienAlgoliaMail,
];

export const dossier1: Projet = {
  apport: 4000000,
  capacite_emprunt_max: 23131082,
  code_commune_contact: '85065',
  code_commune_projet: '85194',
  code_postal_contact: '85250',
  code_postal_projet: '85100',
  commentaires: commentaires,
  date_creation: new Date(),
  date_modification: new Date(),
  date_etat: new Date(),
  etat: 'DOSSIER_CLOS',
  etat_relance: 'CLOS',
  id: 25,
  id_utilisateur: 8164,
  mail: '8164@anonyme.com',
  maturite: 'RECHERCHE',
  montant: 217710,
  nom: 'nom',
  prenom: 'nom',
  score: 2,
  telephone: '0668343986',
  type_pret_relais: 'null',
  usage: 'RES_SECONDAIRE',
};

export const dossier2: Projet = {
  apport: 4000000,
  capacite_emprunt_max: 23131082,
  code_commune_contact: '85065',
  code_commune_projet: '85194',
  code_postal_contact: '85250',
  code_postal_projet: '85100',
  commentaires: commentaires,
  date_creation: new Date(),
  date_modification: new Date(),
  date_etat: new Date(),
  etat: 'DOSSIER_CLOS',
  etat_relance: 'CLOS',
  id: 26,
  id_utilisateur: 8164,
  mail: '8164@anonyme.com',
  maturite: 'RECHERCHE',
  montant: 217710,
  nom: 'nom',
  prenom: 'nom',
  score: 2,
  telephone: '0668343986',
  type_pret_relais: 'null',
  usage: 'RES_SECONDAIRE',
};
// Génère des IDs à la volée pour ne pas avoir de warnings React lorsque l'on loope à travers.
export const listeDossiers: Projet[] = [dossier1, dossier2];

// News slides (sidebar carousel)
export const newsSlides = [
  {
    title: 'Tempor scelerisque purus ultricies tortor tortor nullam pharetra',
    link: 'https://foo.com',
    image: 'https://picsum.photos/150/150',
  },
  {
    title: 'Projet',
    link: 'https://bar.com',
    image: 'https://picsum.photos/350/150',
  },
  {
    title: 'Emprunteur(s)',
    link: 'https://foobar.com',
    image: 'https://picsum.photos/250/450',
  },
];

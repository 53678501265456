import { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { format } from 'date-fns';
import * as yup from 'yup';
import type { Asserts } from 'yup';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
  Flex,
  HStack,
  Link,
  List,
  ListItem,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';

import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { BinIcon, DisketteIcon } from 'components/Icons';
import { Input } from 'components/Input';
import { Panel } from 'components/Panel';
import { StickyFooter } from 'components/StickyFooter';
import { Tag } from 'components/Tag';
import { InputField, SelectField, CheckboxField } from 'utils/form';
import { ETAT_PROJET, USAGE_PROJET } from 'utils/labels';
import { DATE_FORMAT } from 'utils/helpers';
import { PROFILE_STATE_OPTIONS, PROFILE_TYPE_OPTIONS, TITLE_OPTIONS } from 'utils/options';
import { Client, Projet, UsageProjet } from 'types';

export const COLUMN_WIDTHS = {
  ID: { lg: '15%' },
  PROJECT: { lg: '30%' },
  TYPE: { lg: '20%' },
  CITY: { lg: '18%' },
  BUDGET: { lg: '8%' },
  STATE: { lg: '9%' },
};

const validationSchema = yup.object().shape({
  login: yup.string().email().required(),
  title: yup.string(),
  lastName: yup.string().required(),
  type: yup.string(),
  number: yup.string(),
  email: yup.string().email().required(),
  phone: yup.string(),
  firstName: yup.string(),
  state: yup.string(),
  brand: yup.string(),
  noNewPassword: yup.boolean(),
  newPasswordCreate: yup.boolean(),
  newPassword1: yup.string(),
  newPassword2: yup.string(),
  newPasswordSendByEmail: yup.boolean(),
  newPasswordGenerate: yup.boolean(),
});

type Values = Asserts<typeof validationSchema>;

export type Props = {
  client: Client;
  files?: Projet[];
} & BoxProps;

export const ClientDetails = ({ client, files, ...props }: Props) => {
  const deleteButton = useBreakpointValue({ lg: 'Détruire' });
  const saveButton = useBreakpointValue({ lg: 'Enregistrer' });

  // Form declaration
  const formik = useFormik({
    validationSchema,
    initialValues: {
      login: client.login,
      title: client.civilite,
      lastName: client?.nom ?? '',
      type: client.profil,
      number: client.numero,
      email: client?.mel ?? '',
      phone: client?.mobile ?? '',
      firstName: client?.prenom ?? '',
      state: client.etat,
      brand: client.marque_partenaire,
      noNewPassword: true,
      newPasswordCreate: false,
      newPassword1: '',
      newPassword2: '',
      newPasswordSendByEmail: false,
      newPasswordGenerate: false,
    } as Values,
    onSubmit: (values: Values, actions) => {
      console.log('Save client:', values);

      // TODO: Call the API here
      setTimeout(() => {
        actions.setSubmitting(false);
      }, 3000);
    },
  });

  // Callbacks
  const onDelete = useCallback(() => {
    console.log('On delete!');
  }, []);

  const projet = true;
  const date_derniere_modification_etat = new Date();
  const projet_localite = 'Paris';
  const projet_code_postal = '75002';
  const projet_montant = 232323;
  const etat_projet = 'DOSSIER_CLOS';

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Panel {...props}>
          <Accordion allowMultiple defaultIndex={[0]}>
            <AccordionItem>
              <Text as="h3">
                <AccordionButton>
                  <Box flex={1} textAlign="left">
                    Informations client
                  </Box>
                  <AccordionIcon color="gray.500" />
                </AccordionButton>
              </Text>
              <AccordionPanel>
                {client.date_creation && (
                  <Text fontSize="sm" mb={8}>
                    Compte créé le {format(client.date_creation, DATE_FORMAT)}
                  </Text>
                )}
                <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: 8, lg: 16 }}>
                  <VStack as="fieldset" flex={1} spacing={4}>
                    <InputField
                      name="login"
                      label="Login"
                      placeholder="Ex: michel.martin@gmail.com"
                      type="email"
                      isRequired
                    />
                    <CheckboxField name="title" label="Civilité" options={TITLE_OPTIONS} />
                    <InputField name="lastName" label="Nom" placeholder="Ex: Martin" isRequired />
                    <SelectField name="type" label="Profil" options={PROFILE_TYPE_OPTIONS} />
                    <InputField name="number" label="Client" placeholder="Ex: 56808" />
                  </VStack>
                  <VStack as="fieldset" flex={1} spacing={4}>
                    <InputField
                      name="email"
                      label="Email"
                      placeholder="Ex: michel.martin@gmail.com"
                      type="email"
                      isRequired
                    />
                    <InputField
                      name="phone"
                      label="Téléphone"
                      type="tel"
                      placeholder="Ex: 06 10 09 08 01"
                    />
                    <InputField name="firstName" label="Prénom" placeholder="Ex: Michel" />
                    <SelectField
                      name="state"
                      label="État du compte"
                      options={PROFILE_STATE_OPTIONS}
                    />
                  </VStack>
                </Stack>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <Text as="h3">
                <AccordionButton>
                  <Box flex={1} textAlign="left">
                    Dossiers du client
                  </Box>
                  <AccordionIcon color="gray.500" />
                </AccordionButton>
              </Text>
              <AccordionPanel>
                {files && files.length > 0 ? (
                  <List>
                    {files.map((file) => (
                      <ListItem key={file.id} _notLast={{ mb: 6 }}>
                        <Link
                          as={RouterLink}
                          to={`/dossiers/${file.id}`}
                          _hover={{ bg: 'gray.50', textDecoration: 'none' }}
                          bg="gray.100"
                          borderRadius="md"
                          display="flex"
                          flexDirection={{ base: 'column', lg: 'row' }}
                          fontSize="lg"
                          justifyContent={{ lg: 'space-between' }}
                          px={6}
                          py={5}
                        >
                          <Flex alignItems="center" width={COLUMN_WIDTHS.ID}>
                            <Text fontSize="sm" fontWeight="semibold" isTruncated>
                              N°{file.id}
                            </Text>
                          </Flex>
                          <Flex alignItems="center" width={COLUMN_WIDTHS.PROJECT}>
                            <Text isTruncated>
                              {ETAT_PROJET[etat_projet]} le{' '}
                              <Text as="span" fontWeight="semibold">
                                {format(date_derniere_modification_etat, DATE_FORMAT)}
                              </Text>
                            </Text>
                          </Flex>
                          <Flex alignItems="center" width={COLUMN_WIDTHS.TYPE}>
                            {file.usage && (
                              <Text isTruncated>{USAGE_PROJET[file.usage as UsageProjet]}</Text>
                            )}
                          </Flex>
                          <Flex
                            alignItems="center"
                            mb={{ base: 4, lg: 0 }}
                            width={COLUMN_WIDTHS.CITY}
                          >
                            {projet && (
                              <Text isTruncated>
                                {projet_localite}
                                {projet_code_postal ? ` (${projet_code_postal})` : ''}
                              </Text>
                            )}
                          </Flex>
                          <Flex
                            alignItems="center"
                            mb={{ base: 3, lg: 0 }}
                            width={COLUMN_WIDTHS.BUDGET}
                          >
                            {projet && (
                              <Tag>{Number(projet_montant).toLocaleString('fr-FR')} €</Tag>
                            )}
                          </Flex>
                          <Flex
                            alignItems="center"
                            justifyContent={{ lg: 'flex-end' }}
                            width={COLUMN_WIDTHS.STATE}
                          >
                            {projet && <Tag colorScheme="teal">{ETAT_PROJET[etat_projet]}</Tag>}
                          </Flex>
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Text fontSize="2xl">Aucun projet associé au client.</Text>
                )}
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <Text as="h3">
                <AccordionButton>
                  <Box flex={1} textAlign="left">
                    Gestion du mot de passe
                  </Box>
                  <AccordionIcon color="gray.500" />
                </AccordionButton>
              </Text>
              <AccordionPanel>
                <Stack
                  direction={{ base: 'column', lg: 'row' }}
                  pb={{ base: 0, lg: 6 }}
                  spacing={{ base: 9, lg: 14 }}
                >
                  <Box flex={1}>
                    <Checkbox
                      name="noNewPassword"
                      isChecked={formik.values.noNewPassword}
                      onChange={formik.handleChange}
                      alignItems="flex-start"
                      lineHeight="20px"
                    >
                      Aucun changement
                    </Checkbox>
                  </Box>
                  <Box flex={1}>
                    <Checkbox
                      name="newPasswordCreate"
                      isChecked={formik.values.newPasswordCreate}
                      onChange={formik.handleChange}
                      alignItems="flex-start"
                      mb={{ base: 6, lg: 8 }}
                      lineHeight="20px"
                    >
                      Saisir un nouveau mot de passe
                    </Checkbox>
                    <Input
                      name="newPassword1"
                      disabled={!formik.values.newPasswordCreate}
                      value={formik.values.newPassword1}
                      onChange={formik.handleChange}
                      placeholder="Nouveau mot de passe"
                      mb={4}
                      type="password"
                    />
                    <Input
                      name="newPassword2"
                      disabled={!formik.values.newPasswordCreate}
                      value={formik.values.newPassword2}
                      onChange={formik.handleChange}
                      placeholder="Confirmer le mot de passe"
                      mb={4}
                      type="password"
                    />
                    <Text fontSize="sm" mb={4}>
                      Il est recommandé de renseigner une valeur rentrant dans la politique de
                      définition du mot de passe (8 caractères, au moins une majuscule, une
                      minuscule et un chiffre)
                    </Text>
                    <Checkbox
                      name="newPasswordSendByEmail"
                      isDisabled={!formik.values.newPasswordCreate}
                      value={formik.values.newPassword2}
                      onChange={formik.handleChange}
                    >
                      Envoyer le mot de passe par e-mail
                    </Checkbox>
                  </Box>
                  <Box flex={1}>
                    <Checkbox
                      name="newPasswordGenerate"
                      isChecked={formik.values.newPasswordGenerate}
                      onChange={formik.handleChange}
                      alignItems="flex-start"
                      lineHeight="20px"
                    >
                      Générer un mot de passe aléatoire
                    </Checkbox>
                  </Box>
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Panel>
        <StickyFooter>
          <HStack spacing={6}>
            <Button colorScheme="gray" icon={BinIcon} onClick={onDelete}>
              {deleteButton}
            </Button>
            <Button
              colorScheme="teal"
              icon={DisketteIcon}
              isDisabled={!formik.isValid}
              isLoading={formik.isSubmitting}
              type="submit"
            >
              {saveButton}
            </Button>
          </HStack>
        </StickyFooter>
      </form>
    </FormikProvider>
  );
};

import { createIcon } from '@chakra-ui/react';

export const LockIcon = createIcon({
  displayName: 'LockIcon',
  viewBox: '0 0 14 19',
  path: (
    <path
      fill="currentColor"
      d="M13.188 6.125h-2.25V4.437A3.942 3.942 0 007 .5a3.942 3.942 0 00-3.938 3.938v1.687H.813a.563.563 0 00-.562.563v11.25c0 .31.252.562.563.562h12.374c.311 0 .563-.252.563-.563V6.688a.563.563 0 00-.563-.562zm-5.626 7.233v1.767H6.438v-1.767a2.248 2.248 0 01-1.687-2.17c0-1.241 1.01-2.25 2.25-2.25s2.25 1.009 2.25 2.25c0 1.045-.72 1.918-1.688 2.17zm2.25-7.233H4.189V4.437A2.816 2.816 0 017 1.626a2.816 2.816 0 012.813 2.813v1.687z"
    />
  ),
});

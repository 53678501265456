export const getLogo = () => {
  return (
    process.env.REACT_APP_PUBLIC_BUCKET_ACCESS_URL +
    'marques/' +
    window.location.hostname.replace('www.', '') +
    '/logo.png'
  );
};

export const getFavicon = () => {
  return (
    process.env.REACT_APP_PUBLIC_BUCKET_ACCESS_URL +
    'marques/' +
    window.location.hostname.replace('www.', '') +
    '/favicon.png'
  );
};

/*!
 * Dynamically changing favicons with JavaScript
 * Works in all A-grade browsers except Safari and Internet Explorer
 * Demo: http://mathiasbynens.be/demo/dynamic-favicons
 */

// HTML5™, baby! http://mathiasbynens.be/notes/document-head

export function changeFavicon() {
  const link = document.createElement('link'),
    oldLink = document.getElementById('dynamic-favicon');
  link.id = 'dynamic-favicon';
  link.rel = 'shortcut icon';
  link.href = getFavicon();
  if (oldLink) {
    document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
}

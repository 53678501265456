import { BoxProps, Flex, Stack, Text } from '@chakra-ui/react';

import { Button } from 'components/Button';
import { ArrowLeftIcon } from 'components/Icons';
import { Tag } from 'components/Tag';
import { useHistory } from 'react-router';
import { Projet } from 'types';
import { TypeProjet } from 'types';
import { TYPE_PROJET } from 'utils/labels';

export type Props = {
  file: Projet;
  isDossier: boolean;
} & BoxProps;

export const FileDetailsHeader = ({ file, isDossier, ...props }: Props) => {
  const history = useHistory();
  return (
    <Stack
      as="header"
      alignItems={{ base: 'flex-start', lg: 'center' }}
      direction={{ base: 'column', lg: 'row' }}
      spacing={{ base: 4, lg: 6 }}
      {...props}
    >
      <Flex alignItems="center">
        <Button mr={{ base: 6, lg: 8 }} onClick={history.goBack} role="group" variant="unstyled">
          <ArrowLeftIcon boxSize="18px" color="gray.200" _groupHover={{ color: 'gray.500' }} />
        </Button>
        <Tag>
          {isDossier && <>Dossier n°{file.id} </>}
          {!isDossier && <>Contact n°{file.id} </>}
        </Tag>
      </Flex>
      <Text as="h2" fontSize={{ base: 'xl', lg: '5xl' }} fontWeight="medium">
        Achat d’un logement {TYPE_PROJET[file.type as TypeProjet]}
        {file.nom_localisation && file.code_postal_localisation && (
          <>
            , {file.code_postal_localisation} {file.nom_localisation}
          </>
        )}
      </Text>
    </Stack>
  );
};

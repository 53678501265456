import { Box, Image, Text, VStack } from '@chakra-ui/react';
const css = `
.BannerEnvIndicator {
  z-index: 9999;
  height: 35px;
  width: 180px;
  background-color: #3FB6B7;
  position: absolute;
  top: 45px;
  left: -5px;
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  white-space: nowrap;
  -webkit-transform: rotate(-35deg);
  -moz-transform: rotate(-35deg);
  -ms-transform: rotate(-35deg);
  -o-transform: rotate(-35deg);
  transform: rotate(-35deg);
}

.BannerEnvIndicator:before {
  box-sizing: border-box;
  border-style: solid;
  border-color: transparent;
  border-width: 37px;
  border-left-width: 00px;
  border-right-width: 00px;
  content: '';
  display: block;
  left: -30px;
  position: absolute;
  width: 141%;
  border-top-width: 0px;
  border-bottom-color: #3FB6B7;
  top: -2px;
  z-index: -1;
}

`;

export const BannerEnvIndicator = () => {
  if (process.env.REACT_APP_ACTIVATE_BANNER) {
    return (
      <>
        {process.env.REACT_APP_ACTIVATE_BANNER === 'true' && (
          <>
            <style>{css}</style>
            <div className="BannerEnvIndicator">{process.env.REACT_APP_BANNER_CONTENT}</div>
          </>
        )}{' '}
      </>
    );
  } else {
    return <></>;
  }
};

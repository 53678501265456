import { BoxProps, Image, Text, VStack } from '@chakra-ui/react';

import { Panel } from 'components/Panel';
import { Link } from 'react-router-dom';

export type Props = {
  image: string;
  description: string;
  description_light: string;
  url?: string;

  asLink?: boolean;
  to?: string;
} & BoxProps;

export const SimulatorPanelCustom = ({
  url,
  image,
  description,
  description_light,
  asLink,
  to,
  ...props
}: Props) => {
  return (
    <Panel
      as={asLink ? Link : 'a'}
      to={to}
      _hover={{ backgroundColor: 'gray.50' }}
      _active={{ backgroundColor: 'gray.100' }}
      alignItems="flex-end"
      display="flex"
      height="100px"
      href={url}
      pb={7}
      pr={5}
      position="relative"
      target={asLink ? '' : '_blank'}
      width={{
        base: '100%',
        md: '260px',
      }}
      float={'left'}
      margin={{ base: '5px' }}
      {...props}
    >
      <Image
        fallbackSrc={'/placeholder-image.png'}
        fit={'scale-down'}
        alt="Simulateur de crédit"
        top={3}
        height={90}
        left={4}
        position="absolute"
        src={image}
        width={90}
      />
      <VStack
        position="absolute"
        top={6}
        right={4}
        alignItems="flex-end"
        maxWidth="60%"
        ml="auto"
        spacing={2}
        textAlign="right"
      >
        <Text fontSize="xs" fontWeight="medium">
          {description_light}
        </Text>
        <Text as="h3" fontWeight="bold">
          {description}
        </Text>
      </VStack>
    </Panel>
  );
};

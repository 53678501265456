import { useState, useContext } from 'react';
import { InstantSearchAfficheurResultat } from 'components/InstantSearchAfficheurResultat';
import { InstantSearchAfficheurTitreNombreRes } from 'components/InstantSearchAfficheurTitreNombreRes';
import { InstantSearchSelecteurFiltresMobile } from 'components/InstantSearchSelecteurFiltresMobile';
import { InstantSearchAfficheurPagination } from 'components/InstantSearchAfficheurPagination';
import { HomeSidebar } from 'components/HomeSidebar';
import { KPIs } from 'components/KPIs';
import { Layout } from 'components/Layout';
import { ActionsModal } from 'components/ActionsModal';
import { NewContactModal } from 'components/NewContactModal';
import { SendLinkModal } from 'components/SendLinkModal';
import { Courtier, Utilisateur, Marque } from 'types';
import { MeContext } from 'auth/AuthContextProvider';

type ModalState = 'ACTIONS' | 'CREATE_CONTACT' | 'SEND_LINK' | 'FILTERS' | 'PERIMETRE' | undefined;

export type Props = {
  courtier: Courtier;
  utilisateur: Utilisateur;
  marque: Marque;
  withKPI?: boolean;
};

export const HomePage = ({ courtier, utilisateur, withKPI, marque }: Props) => {
  const [modalState, setModalState] = useState<ModalState>();
  return (
    <>
      <Layout
        courtier={courtier}
        utilisateur={utilisateur}
        sidebar={<HomeSidebar withKPI={withKPI} withFilter={true} utilisateur={utilisateur} />}
        leftSidebar={true}
      >
        {withKPI && <KPIs mb={{ base: 8, lg: 11 }} mt={{ lg: -10 }} />}
        <InstantSearchAfficheurTitreNombreRes action={() => setModalState('ACTIONS')} />
        <InstantSearchSelecteurFiltresMobile />
        <InstantSearchAfficheurResultat />
        <InstantSearchAfficheurPagination />
      </Layout>
      {/** More Actions Modal */}
      <ActionsModal
        isOpen={modalState === 'ACTIONS'}
        onOpenCreateContactModal={() => setModalState('CREATE_CONTACT')}
        onOpenSendLinkModal={() => setModalState('SEND_LINK')}
        onOpenNewDossierTunnel={() =>
          window.open(
            marque.url_tunnel +
              '?origine=bo-partenaire' +
              (utilisateur?.apporteur_code ? '&apcode=' + utilisateur?.apporteur_code : ''),
          )
        }
        onClose={() => setModalState(undefined)}
      />
      {/** Create Contact Modal */}
      <NewContactModal
        isOpen={modalState === 'CREATE_CONTACT'}
        onBack={() => setModalState('ACTIONS')}
        onClose={() => setModalState(undefined)}
        onDone={() => setModalState(undefined)}
      />
      {/** Send Link Modal */}
      <SendLinkModal
        isOpen={modalState === 'SEND_LINK'}
        onBack={() => setModalState('ACTIONS')}
        onClose={() => setModalState(undefined)}
        onDone={() => setModalState(undefined)}
      />
    </>
  );
};

export const HomePageRoute = () => {
  const me = useContext(MeContext);
  return <HomePage courtier={me.courtier!} utilisateur={me.utilisateur!} marque={me.marque!} />;
};

import { mappingLabelFacets } from 'utils/labels';

import ReactDOMServer from 'react-dom/server';
import { Me } from 'types';
/**
 * Generate a DataURI string from SVG attributes (useful for background-image's url() function)
 * @param viewBox The SVG 'viewBox' attribute
 * @param path The SVG path JSX Element
 * @param fill The SVG path 'fill' attribute (optional, defaults to 'white')
 * @param stroke The SVG path 'stroke' attribute (optional, defaults to 'none')
 * @returns {string}
 */
export const generateDataURIFromSVG = (
  viewBox: string,
  path: JSX.Element,
  fill = 'white',
  stroke = 'none',
): string => {
  const stringPath = ReactDOMServer.renderToStaticMarkup(
    <path {...path.props} fill={fill} stroke={stroke} />,
  );

  return `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="${viewBox}">${stringPath}</svg>`;
};

export const getParameterByNameOnUrl = (name: string, url?: string) => {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
/**
 * The date format to use across the app (ex: 25/06/2021)
 */
export const DATE_FORMAT = 'dd/MM/yyyy';
export const DATETIME_FORMAT = 'dd/MM/yyyy hh:mm';

/**
 * return true if the is considered as the user connected
 */
export const idIsMe = (id: number | undefined, me: Me) => {
  if (id !== undefined && me.utilisateur && me.utilisateur.id === id) return true;
  return false;
};

/**
 * return true if the is considered as the user connected
 */
export const roleCodePeuxVoirPerimetre = (roleCode: string) => {
  if (['ADMINISTRATEUR', 'RIMMO', 'DIMMO', 'SIMMO'].includes(roleCode)) return true;
  return false;
};

/**
 * getLabelFromFacet
 */
export const getLabelFromFacet = (key: string) => {
  if (mappingLabelFacets.get(key)) {
    return mappingLabelFacets.get(key);
  }
  return key;
};

/**
 * Regex permetant de controler si il y a des espaces
 */
export const spaceRegExp = /^[\S]*$/;
/**
 * Regex permetant de controler un numéro de telephone
 */
export const phoneRegExp = /^(0|(00|\+)(41|33|594|689|590|596|687|262|508|686))[67][0-9]{8}$/;
/**
 * vérifie si le text contient le motif [LIEN_URL]
 */
export const presenceLienUrlRegExp = /^.*\[LIEN_URL\].*$/m;

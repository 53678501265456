export const localization = {
  buttonLabel: 'Choisir une date',
  placeholder: 'DD/MM/YYYY',
  selectedDateMessage: 'La date sélectionnée est',
  prevMonthLabel: 'Mois précédent',
  nextMonthLabel: 'Mois suivant',
  monthSelectLabel: 'Mois',
  yearSelectLabel: 'Année',
  closeLabel: 'Fermer la fenêtre',
  calendarHeading: 'Choisissez une date',
  dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  monthNames: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Aout',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ],
  monthNamesShort: [
    'Jan',
    'Fév',
    'Mar',
    'Avr',
    'Mai',
    'Jui',
    'Juil',
    'Aou',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  locale: 'fr-FR',
};

import {
  ComponentWithAs,
  IconProps,
  Tag as ChakraTag,
  TagLabel,
  TagLeftIcon,
  TagProps,
  TagRightIcon,
} from '@chakra-ui/react';

type ColorScheme =
  | 'teal'
  | 'gray'
  | 'orange'
  | 'orange-light'
  | 'red'
  | 'red-light'
  | 'green'
  | 'green-light';
export type Props = {
  children?: React.ReactNode;
  colorScheme?: ColorScheme;
  leftIcon?: ComponentWithAs<'svg', IconProps>;
  rightIcon?: ComponentWithAs<'svg', IconProps>;
} & Omit<TagProps, 'colorScheme'>;

export const Tag = ({
  children,
  colorScheme = 'gray',
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  ...props
}: Props) => {
  return (
    <ChakraTag {...getStyles(colorScheme)} colorScheme={colorScheme} {...props}>
      {LeftIcon && <TagLeftIcon as={LeftIcon} mr={3} width={4} />}
      <TagLabel>{children}</TagLabel>
      {RightIcon && <TagRightIcon as={RightIcon} ml={3} width={4} />}
    </ChakraTag>
  );
};

const getStyles = (colorScheme: ColorScheme): TagProps => {
  switch (colorScheme) {
    case 'teal':
      return {
        background: 'teal.500',
        color: 'white',
      };
    case 'gray':
      return {
        background: 'gray.100',
        color: 'teal.900',
      };
    case 'orange':
      return {
        background: 'orange.500',
        color: 'white',
      };
    case 'orange-light':
      return {
        background: 'orange.50',
        color: 'orange.500',
      };
    case 'red-light':
      return {
        background: 'red.50',
        color: 'red.500',
      };
    case 'red':
      return {
        background: 'red.500',
        color: 'white',
      };
    case 'green-light':
      return {
        background: 'green.50',
        color: 'green.500',
      };
    case 'green':
      return {
        background: 'green.500',
        color: 'white',
      };
    default:
      return {};
  }
};

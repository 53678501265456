import {
  Popover as ChakraPopover,
  PopoverArrow,
  PopoverArrowProps,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
} from '@chakra-ui/react';

import { Button } from 'components/Button';
import { ChevronDownIcon } from 'components/Icons';

export type Props = {
  arrowProps?: PopoverArrowProps;
  children: React.ReactNode;
  onClose?: (arg: unknown) => void;
  title: string;
} & PopoverProps;

export const Popover = ({ arrowProps, children, onClose, title, ...props }: Props) => {
  return (
    <ChakraPopover onClose={onClose} {...props}>
      <PopoverTrigger>
        <Button
          backgroundColor="transparent"
          borderRadius="md"
          color="teal.900"
          height={{ base: 10, lg: 'auto' }}
          justifyContent="space-between"
          rightIcon={<ChevronDownIcon color="gray.300" width={4} height="auto" />}
          variant="link"
          width="full"
        >
          {title}
        </Button>
      </PopoverTrigger>
      <PopoverContent w="full">
        <PopoverArrow {...arrowProps} />
        <section>{children}</section>
      </PopoverContent>
    </ChakraPopover>
  );
};

import { Field, FieldProps, ErrorMessage } from 'formik';
import { Box, Flex, FormControl, FormLabel, HStack, Text } from '@chakra-ui/react';
import React from 'react';

import { CalendarIcon } from 'components/Icons';
import { Input } from 'components/Input';
import { Checkbox, CheckboxGroup } from 'components/Checkbox';
import { Radio, RadioGroup } from 'components/Radio';
import { Select } from 'components/Select';
import { Textarea } from 'components/Textarea';

/**
 * Reused form components within their layout.
 */

type InputFieldProps = {
  name: string;
  label: string;
  isRequired?: boolean;
  placeholder?: string;
  type?: string;
  forceColumn?: boolean;
};
export const InputField = ({
  name,
  label,
  isRequired,
  placeholder = label,
  type,
  forceColumn = false,
}: InputFieldProps) => {
  return (
    <Field name={name}>
      {({ field }: FieldProps) => (
        <FormControl isRequired={isRequired}>
          <Flex
            alignItems={{ lg: 'center' }}
            direction={{ base: 'column', lg: forceColumn ? 'column' : 'row' }}
          >
            <FormLabel htmlFor={field.name} flex={{ lg: 4 }} mb={{ base: 3, lg: 0 }} mr={{ lg: 4 }}>
              {label}
            </FormLabel>
            <Box flex={{ lg: 6 }} width={forceColumn ? '100%' : ''}>
              <Input
                {...field}
                type={type}
                icon={type === 'date' ? CalendarIcon : undefined}
                id={field.name}
                placeholder={placeholder}
              />
            </Box>
          </Flex>
          <Text mr={5} color="red" fontSize={'sm'}>
            <ErrorMessage name={name} />
          </Text>
        </FormControl>
      )}
    </Field>
  );
};

type TextareaFieldProps = {
  name: string;
  label: string;
  isRequired?: boolean;
  isResizable?: boolean;
};
export const TextareaField = ({
  name,
  label,
  isRequired,
  isResizable = false,
}: TextareaFieldProps) => {
  return (
    <Field name={name}>
      {({ field }: FieldProps) => (
        <FormControl isRequired={isRequired}>
          <Flex alignItems={{ lg: 'center' }} direction={{ base: 'column', lg: 'row' }}>
            <FormLabel htmlFor={field.name} flex={{ lg: 4 }} mb={{ base: 3, lg: 0 }} mr={{ lg: 4 }}>
              {label}
            </FormLabel>
            <Box flex={{ lg: 6 }}>
              <Textarea {...field} id={field.name} resize={isResizable ? 'vertical' : 'none'} />
            </Box>
          </Flex>
          <Text mr={5} color="red" fontSize={'sm'}>
            <ErrorMessage name={name} />
          </Text>
        </FormControl>
      )}
    </Field>
  );
};

type RadioFieldProps = {
  name: string;
  label: string;
  options: { label: string; value: string }[];
};
export const RadioField = ({ name, label, options }: RadioFieldProps) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <FormControl>
          <Flex alignItems={{ lg: 'center' }} direction={{ base: 'column', lg: 'row' }}>
            <FormLabel htmlFor={field.name} flex={{ lg: 4 }} mb={{ base: 3, lg: 0 }} mr={{ lg: 4 }}>
              {label}
            </FormLabel>
            <Box flex={{ lg: 6 }}>
              <RadioGroup {...field} onChange={(val) => form.setFieldValue(name, val)}>
                <HStack minHeight={{ lg: 10.5 }} spacing={{ base: 8, lg: 11 }}>
                  {options.map(({ label, value }) => (
                    <Radio key={value} value={value}>
                      {label}
                    </Radio>
                  ))}
                </HStack>
              </RadioGroup>
            </Box>
          </Flex>
          <Text mr={5} color="red" fontSize={'sm'}>
            <ErrorMessage name={name} />
          </Text>
        </FormControl>
      )}
    </Field>
  );
};

type CheckboxFieldProps = {
  name: string;
  label: string;
  options: { label: string; value: string }[];
};
export const CheckboxField = ({ name, label, options }: CheckboxFieldProps) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <FormControl>
          <Flex alignItems={{ lg: 'center' }} direction={{ base: 'column', lg: 'row' }}>
            <FormLabel htmlFor={field.name} flex={{ lg: 4 }} mb={{ base: 3, lg: 0 }} mr={{ lg: 4 }}>
              {label}
            </FormLabel>
            <Box flex={{ lg: 6 }}>
              <CheckboxGroup {...field} onChange={(val) => form.setFieldValue(name, val)}>
                <HStack minHeight={{ lg: 10.5 }} spacing={{ base: 8, lg: 11 }}>
                  {options.map(({ label, value }) => (
                    <Checkbox key={value} value={value}>
                      {label}
                    </Checkbox>
                  ))}
                </HStack>
              </CheckboxGroup>
            </Box>
          </Flex>
          <Text mr={5} color="red" fontSize={'sm'}>
            <ErrorMessage name={name} />
          </Text>
        </FormControl>
      )}
    </Field>
  );
};

type SelectFieldProps = {
  name: string;
  label: string;
  options: { label: string; value: string }[];
  customSelector?: boolean;
  customOnChange?: (e: React.ChangeEvent<HTMLSelectElement> | undefined) => void;
  customValue?: string;
};
export const SelectField = ({
  name,
  label,
  options,
  customSelector,
  customOnChange,
  customValue,
}: SelectFieldProps) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <FormControl>
          <Flex alignItems={{ lg: 'center' }} direction={{ base: 'column', lg: 'row' }}>
            <FormLabel htmlFor={field.name} flex={{ lg: 4 }} mb={{ base: 3, lg: 0 }} mr={{ lg: 4 }}>
              {label}
            </FormLabel>
            <Box flex={{ lg: 6 }}>
              {customSelector ? (
                <Select {...field} value={customValue} onChange={customOnChange}>
                  {options.map(({ label, value }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </Select>
              ) : (
                <Select {...field}>
                  {options.map(({ label, value }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </Select>
              )}
            </Box>
          </Flex>
          <Text mr={5} color="red" fontSize={'sm'}>
            <ErrorMessage name={name} />
          </Text>
        </FormControl>
      )}
    </Field>
  );
};

type SelectFieldNumberProps = {
  name: string;
  label: string;
  options: { label: string; value: number }[];
};
export const SelectFieldNumber = ({ name, label, options }: SelectFieldNumberProps) => {
  return (
    <Field name={name}>
      {({ field }: FieldProps) => (
        <FormControl>
          <Flex alignItems={{ lg: 'center' }} direction={{ base: 'column', lg: 'row' }}>
            <FormLabel htmlFor={field.name} flex={{ lg: 4 }} mb={{ base: 3, lg: 0 }} mr={{ lg: 4 }}>
              {label}
            </FormLabel>
            <Box flex={{ lg: 6 }}>
              <Select {...field}>
                {options.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
            </Box>
          </Flex>
          <Text mr={5} color="red" fontSize={'sm'}>
            <ErrorMessage name={name} />
          </Text>
        </FormControl>
      )}
    </Field>
  );
};

import { BoxProps, HStack, VStack, Text } from '@chakra-ui/react';
import { InstantSearchSelecteurFiltresMobile } from 'components/InstantSearchSelecteurFiltresMobile';
import { Button } from 'components/Button';
import { PlusCircleIcon } from 'components/Icons';
import { Tag } from 'components/Tag';

export type Props = {
  filesTotal?: number;
  onOpenMoreActionsModal?: () => void;
} & BoxProps;

export const FilesListHeader = ({ filesTotal, onOpenMoreActionsModal, ...props }: Props) => {
  const displaysClientsNumber = filesTotal && filesTotal > 0;

  return (
    <>
      <HStack as="header" justifyContent="space-between" spacing={{ base: 6, lg: 0 }} {...props}>
        <HStack alignItems="center" spacing={displaysClientsNumber ? { base: 3, lg: 4 } : 0}>
          <Text as="h2" fontSize={{ base: 'xl', lg: '6xl' }} fontWeight="bold">
            Liste des projets clients
          </Text>
          {displaysClientsNumber && (
            <Tag colorScheme="orange">{filesTotal?.toLocaleString('fr-FR')}</Tag>
          )}
        </HStack>
        {/* Bouton desktop*/}
        <HStack alignItems="center">
          <Button
            display={{ base: 'none', lg: 'inline-flex' }}
            leftIcon={<PlusCircleIcon boxSize="16px" />}
            onClick={onOpenMoreActionsModal}
          >
            Nouveau projet
          </Button>
        </HStack>
      </HStack>
      <VStack alignItems="center">
        {/* Bouton mobile*/}
        <Button
          display={{ base: 'inline-flex', lg: 'none' }}
          leftIcon={<PlusCircleIcon boxSize="16px" />}
          onClick={onOpenMoreActionsModal}
        >
          Nouveau projet
        </Button>
      </VStack>
    </>
  );
};

import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Projet } from 'types';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  useToast,
  Text,
  VStack,
} from '@chakra-ui/react';
import { AuthContext } from 'auth/AuthContextProvider';
import { Button } from 'components/Button';
import { ArrowLeftIcon } from 'components/Icons';
import { InputField, TextareaField, RadioField, SelectField } from 'utils/form';
import {
  BORROWERS_COUNT_OPTIONS,
  COUNTRY_OPTIONS,
  PROJECT_TYPE_OPTIONS,
  PROJECT_USAGE_OPTIONS,
  TITLE_OPTIONS,
} from 'utils/options';
import { LabelToast } from 'utils/labels';
import { spaceRegExp, phoneRegExp } from 'utils/helpers';

const validationSchema = yup.object().shape({
  title: yup.string(),
  lastName: yup.string().required('Nom requis'),
  firstName: yup.string().required('Prénom requis'),
  email: yup
    .string()
    .matches(spaceRegExp, "Le mail ne doit pas contenir d'espaces")
    .email('Format du mail invalide')
    .required('Mail requis'),
  phone: yup
    .string()
    .matches(spaceRegExp, "Le numéro ne doit pas contenir d'espaces")
    .matches(
      phoneRegExp,
      'Numéro de téléphone invalide, il doit commencer par 06 ou 07. (seuls les numéros français, DOMTOM et Suisse sont pris en charge) ',
    )
    .required('Numéro de télphone requis'),
  city: yup.string(),
  codePostal: yup.string(),
  country: yup.string(),
  type: yup.string(),
  usage: yup.string(),
  montant: yup.number().required('Montant requis'),
  apport: yup.number().required('Apport requis'),
  nombreEmprunteur: yup.string(),
  signatureDate: yup.string(),
  comment: yup.string(),
});

const INITIAL_VALUES = {
  title: 'MADAME',
  lastName: '',
  firstName: '',
  email: '',
  phone: '',
  city: '',
  codePostal: '',
  country: 'FRA',
  type: 'LOGEMENT_ANCIEN',
  usage: 'RES_PRINCIPALE',
  montant: 0,
  apport: 0,
  nombreEmprunteur: '1',
  signatureDate: '',
  comment: '',
};

export type Values = typeof INITIAL_VALUES;

export type Props = {
  onBack?: () => void;
  onDone: () => void;
} & Omit<ModalProps, 'children'>;

export const NewContactModal = ({ onBack, onDone, ...props }: Props) => {
  const { token } = useContext(AuthContext);
  const history = useHistory();
  const toast = useToast();

  // Form declaration
  const formik = useFormik({
    validationSchema,
    initialValues: INITIAL_VALUES,
    onSubmit: (values: Values, actions) => {
      const creationContact = {
        civilite: values.title,
        nom: values.lastName,
        prenom: values.firstName,
        mail: values.email,
        telephone: values.phone,
        ville: values.city,
        code_postal: values.codePostal,
        pays: values.country,
        type: values.type,
        usage: values.usage,
        montant_projet: values.montant != null ? values.montant! * 100 : 0,
        apport: values.apport != null ? values.apport! * 100 : 0,
        nombre_emprunteurs: values.nombreEmprunteur,
        date_signature_compromis: values.signatureDate,
        comment: values.comment,
        accord_contact_emprunteur: 1,
      };

      fetch('/API/contacts', {
        method: 'POST',
        body: JSON.stringify(creationContact),
        headers: new Headers({
          Authorization: token,
          'Content-Type': 'application/json',
        }),
      })
        .then((res) => {
          if (res.status !== 200) {
            toast({
              title: 'Erreur (code : ' + res.status + ')',
              description: LabelToast.FAIL_FICHE_CONTACT + res.statusText,
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
            actions.setSubmitting(false);
          } else {
            return res.json();
          }
        })
        .then((resJson) => {
          if (resJson != null && resJson.id) {
            envoiSms(resJson as Projet);
            actions.setSubmitting(false);
            actions.resetForm();
            toast({
              title: LabelToast.SUCCESS_FICHE_CONTACT,
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
            onDone();
            history.push('/contacts/' + (resJson as Projet).id);
          }
        })
        .catch(function (error) {
          console.log(error);
          actions.setSubmitting(false);
        });
    },
  });

  const envoiSms = (resJson: Projet) => {
    const creationSMS = {
      destinataire: resJson.telephone,
      message:
        'Dans le cadre de votre étude de faisabilité financière, autorisez-vous votre Conseiller Commercial ' +
        resJson.apporteur?.nom +
        ' ' +
        resJson.apporteur?.prenom +
        ' à transférer vos coordonnées à monemprunt.com afin d’être recontacté.e ? Si oui, cliquez ici : ' +
        process.env.REACT_APP_PUBLIC_VALIDATION_CONTACT_URL +
        'id=' +
        resJson.id +
        '&id_apporteur=' +
        resJson.apporteur?.id,
      nom: resJson.nom + ' ' + resJson.prenom,
    };

    fetch('/API/lien-sms', {
      method: 'POST',
      body: JSON.stringify(creationSMS),
      headers: new Headers({
        Authorization: token,
        'Content-Type': 'application/json',
      }),
    }).catch(function (error) {
      console.log(error);
    });
  };

  return (
    <FormikProvider value={formik}>
      <Modal {...props} scrollBehavior="inside" size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack alignItems="center" spacing={6}>
              <Button disabled={formik.isSubmitting} onClick={onBack} variant="unstyled">
                <ArrowLeftIcon boxSize="18px" color="gray.200" />
              </Button>
              <Text as="h3" pt="3px">
                Création d'une fiche contact
              </Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton disabled={formik.isSubmitting} />
          <ModalBody>
            <form onSubmit={formik.handleSubmit}>
              <Stack direction={{ base: 'column', lg: 'row' }} py={8} spacing={{ base: 8, lg: 16 }}>
                <VStack as="fieldset" flex={1} spacing={4}>
                  <Text as="legend" alignSelf="flex-start" fontSize="4xl" fontWeight="light" mb={3}>
                    Identité de l'emprunteur
                  </Text>
                  <RadioField name="title" label="Civilité" options={TITLE_OPTIONS} />
                  <InputField name="lastName" label="Nom" placeholder="Ex: Martin" isRequired />
                  <InputField name="firstName" label="Prénom" placeholder="Ex: Michel" isRequired />
                  <InputField
                    name="email"
                    label="E-mail"
                    placeholder="Ex: michel.martin@gmail.com"
                    isRequired
                  />
                  <InputField
                    name="phone"
                    label="Tél. portable"
                    placeholder="Ex: 0610090801"
                    type="tel"
                    isRequired
                  />
                  <InputField name="city" label="Ville" placeholder="Ex: Rouen" />
                  <InputField name="codePostal" label="Code postal" placeholder="Ex: 76000" />
                  <SelectField name="country" label="Pays" options={COUNTRY_OPTIONS} />
                </VStack>
                <VStack as="fieldset" flex={1} spacing={4}>
                  <Text as="legend" alignSelf="flex-start" fontSize="4xl" fontWeight="light" mb={3}>
                    Recherche de financement
                  </Text>
                  <SelectField name="type" label="Type de projet" options={PROJECT_TYPE_OPTIONS} />
                  <SelectField name="usage" label="Usage du bien" options={PROJECT_USAGE_OPTIONS} />
                  <InputField
                    name="montant"
                    label="Montant du projet"
                    placeholder="Ex: 300 000€"
                    isRequired
                  />
                  <InputField name="apport" label="Apport" placeholder="Ex: 50 000€" />
                  <RadioField
                    name="nombreEmprunteur"
                    label="Nb d'emprunteurs"
                    options={BORROWERS_COUNT_OPTIONS}
                  />
                  <InputField
                    name="signatureDate"
                    label="Date et signature du compromis"
                    type="date"
                  />
                  <TextareaField name="comment" label="Commentaires" />
                </VStack>
              </Stack>
            </form>
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Text mr={5} color="red" fontSize={'sm'}>
              Les champs comportant une étoile (*) sont obligatoires
            </Text>

            <Button
              isDisabled={!formik.isValid || !formik.dirty}
              isLoading={formik.isSubmitting}
              onClick={formik.submitForm}
            >
              Envoyer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormikProvider>
  );
};

import { createIcon } from '@chakra-ui/react';

export const DisketteIcon = createIcon({
  displayName: 'DisketteIcon',
  viewBox: '0 0 16 17',
  path: (
    <path
      clipRule="evenodd"
      fill="currentColor"
      fillRule="evenodd"
      d="M11.926.833H1.704C.758.833 0 1.6 0 2.537v11.926c0 .937.758 1.704 1.704 1.704H13.63c.937 0 1.703-.767 1.703-1.704V4.241L11.926.833zm-4.26 13.63a2.552 2.552 0 01-2.555-2.556 2.552 2.552 0 012.556-2.555 2.552 2.552 0 012.555 2.555 2.552 2.552 0 01-2.555 2.556zm2.556-8.519H1.704V2.537h8.518v3.407z"
    />
  ),
});

import { Box, ComponentWithAs, Flex, IconProps, Stack, Text } from '@chakra-ui/react';

import { Panel } from 'components/Panel';

export type Props = {
  children: string;
  icon: ComponentWithAs<'svg', IconProps>;
  value: number | string;
};

export const KPIPanel = ({ children, icon: Icon, value }: Props) => {
  return (
    <Panel
      as={Stack}
      alignItems="center"
      direction={{ base: 'column', lg: 'row' }}
      display="flex"
      flex={1}
      px={{ base: 4, lg: 6 }}
      py={{ base: 4, lg: 7 }}
      spacing={{ base: 4, lg: 6 }}
      textAlign={{ base: 'center', lg: 'left' }}
    >
      <Flex
        alignItems="center"
        backgroundColor="teal.200"
        borderRadius="full"
        justifyContent="center"
        height={{ base: 10, lg: 12 }}
        width={{ base: 10, lg: 12 }}
      >
        <Icon boxSize={{ base: '16px', lg: '24px' }} color="teal.500" />
      </Flex>
      <Box as="hgroup" flex={1}>
        <Text
          as="h5"
          color="teal.500"
          fontSize={{ base: '4xl', lg: '7xl' }}
          fontWeight="light"
          lineHeight="shorter"
        >
          {value}
        </Text>
        <Text as="h6" display={{ base: 'none', lg: 'inline' }} fontSize="xl">
          {children}
        </Text>
      </Box>
    </Panel>
  );
};

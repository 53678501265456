import React, { ReactChild, ReactChildren, useEffect, useState, useContext } from 'react';
import firebase from 'firebase';
import { getParameterByNameOnUrl } from 'utils/helpers';
import 'firebase/auth';
import { Courtier, Utilisateur, Marque, Me } from 'types';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, connectRefinementList } from 'react-instantsearch-dom';

import { ErrorAuthentModal } from 'components/ErrorAuthentModal';

// Configure Firebase.
// utilisateur de test :  julien.seite+sso@devmachine.fr / 6dGBuT4R03cBjyAUDsJ
// utilisateur de test : electro.kentx+test@gmail.com  /  azerty123456

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
};
const app = firebase.initializeApp(firebaseConfig);
firebase.auth().languageCode = 'fr';
firebase.auth().tenantId = process.env.REACT_APP_FIREBASE_AUTH_TENANT!;

const auth = app.auth();

type AuthContextType = {
  token: string;
  setNewToken: (newToken: string) => void;
};

/*
  Gestion de l'Auth Context provider pour fournir a toute l'application le token quand connecté.
*/
const AuthContext = React.createContext<AuthContextType>({
  token: '',
  setNewToken: function (newToken: string) {
    //do nothing
  },
});

interface AuthProps {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}

const AuthContextProvider = (props: AuthProps) => {
  const [token, setToken] = useState<string>('');

  const urlCustomToken: string = getParameterByNameOnUrl('urlCustomToken')!;
  const authtype: string = getParameterByNameOnUrl('authtype')!;
  const codeSso: string = getParameterByNameOnUrl('code')!;
  const tokenSso: string = getParameterByNameOnUrl('token')!;

  function setNewToken(newToken: string) {
    setToken(newToken);
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser != null) {
        firebaseUser
          .getIdToken(true)
          .then((latestToken) => {
            setToken('Bearer ' + latestToken);
            setTimeout(() => {
              setToken('');
            }, 55 * 60 * 1000);
          })
          .catch((err) => console.log(err));
        console.log('connexion Ok via Firebase');
      } else {
        console.log('connexion requise');
        setToken('');
      }
    });

    /* Suppression feature SSO ORYX
    if (authtype === 'oryx' && codeSso && tokenSso) {
      CustomAuthOryx(codeSso, tokenSso);
    }
    */
    if (urlCustomToken) {
      auth.signInWithCustomToken(urlCustomToken);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ token, setNewToken }}>{props.children}</AuthContext.Provider>
  );
};

/*
 Requète pour recupérer un custom token par d'autre système d'auth.
 */
/* Suppression feature SSO ORYX
const CustomAuthOryx = (code: string, token: string) => {
  fetch('/API/customAuth/oryx/' + code + '/' + token, {
    method: 'GET',
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.token && res.token.length > 0) {
        auth.signInWithCustomToken(res.token);
      }
    });
};
*/
/*
  Gestion de ME Context provider pour fournir a toute l'application l'user connecté'.
*/

const MeContext = React.createContext<Me>({});
/*
  interraction avec l'API pour récupérer l'utilisateur local grâce au token d'authentification
*/

interface MeProps {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}

const MeContextProvider = (props: MeProps) => {
  const [me, setMe] = useState<Me>({});
  const { token, setNewToken } = useContext(AuthContext);
  const [isModalErrorAuthentOpen, setIsModalErrorAuthentOpen] = React.useState(false);

  function closeErrorAuthentModal() {
    setNewToken('');
    setIsModalErrorAuthentOpen(false);
  }

  function openErrorAuthentModal() {
    setIsModalErrorAuthentOpen(true);
  }

  useEffect(() => {
    const meNew: Me = {};
    // permet de skip le premier call et remettre a zero le current user quand le token est vide
    if (token === '') {
      setMe(meNew);
      return;
    }

    fetch('/API/me', {
      method: 'GET',
      headers: new Headers({
        Authorization: token,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.infos_personnelles) {
          meNew.utilisateur = {
            id: res.infos_personnelles.id,
            nom: res.infos_personnelles.nom,
            prenom: res.infos_personnelles.prenom,
            role: res.infos_personnelles.role,
            mail: res.infos_personnelles.mail,
            telephone: res.infos_personnelles.telephone,
            apporteur_code: res.infos_personnelles.apporteur_code,
            search_key: res.infos_personnelles.search_key,
          } as Utilisateur;

          if (res.infos_personnelles?.organisation) {
            meNew.utilisateur.organisation = {
              nom_direction: res.infos_personnelles.organisation.nom_direction,
              nom_entite: res.infos_personnelles.organisation.nom_entite,
              nom_immo: res.infos_personnelles.organisation.nom_immo,
              autoriser_statistique: res.infos_personnelles.organisation.autoriser_statistique,
              autoriser_iobsp: res.infos_personnelles.organisation.autoriser_iobsp,
              autoriser_fiche_contact: res.infos_personnelles.organisation.autoriser_fiche_contact,
              cle: res.infos_personnelles.organisation.cle,
              gen_mentions_legales: res.infos_personnelles.organisation.gen_mentions_legales,
              entite_juridique_id: res.infos_personnelles.organisation.entite_juridique_id,
            };
          }
        }
        if (res.expert) {
          meNew.courtier = {
            id: res.expert.id,
            nom: res.expert.nom,
            prenom: res.expert.prenom,
            mail: res.expert.mail,
            calendly: res.expert.calendly,
            telephone: res.expert.telephone,
            telephone_portable: res.expert.telephone_portable,
            url_image:
              'https://storage.googleapis.com/mec-public/courtier/' + res.expert.id + '.jpg',
          } as Courtier;
        }
        if (res.marque) {
          meNew.marque = {
            url_back_office: res.marque.url_back_office,
            url_tunnel: res.marque.url_tunnel,
          } as Marque;
        }
        setMe(meNew);
      })
      .catch(function (error) {
        setMe(meNew);
        console.log(
          "Il y a eu un problème de récupération d'utilisateur courant: " + error.message,
        );
        openErrorAuthentModal();
      });
  }, [token]);

  const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_API_KEY!,
    me.utilisateur?.search_key ? me.utilisateur.search_key : '',
  );
  const indexName = process.env.REACT_APP_ALGOLIA_INDEX!;

  const VirtualRefinementList = connectRefinementList(() => null);

  return (
    <>
      <ErrorAuthentModal
        isOpen={isModalErrorAuthentOpen}
        onClose={closeErrorAuthentModal}
        mentions="Un problème est survenu lors de votre authentification, veuillez recommencer."
      />
      <MeContext.Provider value={me}>
        <InstantSearch searchClient={searchClient} indexName={indexName}>
          <VirtualRefinementList attribute="kind" />
          <VirtualRefinementList attribute="type" />
          <VirtualRefinementList attribute="usage" />
          <VirtualRefinementList attribute="maturite" />
          <VirtualRefinementList attribute="etat" />
          <VirtualRefinementList attribute="libelle_region" />
          <VirtualRefinementList attribute="libelle_agence" />
          <VirtualRefinementList attribute="nom_apporteur" />
          {props.children}
        </InstantSearch>
      </MeContext.Provider>
    </>
  );
};

export { auth, AuthContext, AuthContextProvider, MeContext, MeContextProvider };

import { Box, Flex, Stack, useTheme } from '@chakra-ui/react';
import { Header } from 'components/Header';
import { Courtier, Utilisateur } from 'types';

export type Props = {
  courtier: Courtier;
  utilisateur: Utilisateur;
  children: React.ReactNode;
  hasStickyFooter?: boolean;
  sidebar?: React.ReactNode;
  leftSidebar?: boolean;
};

export const Layout = ({
  children,
  courtier,
  utilisateur,
  hasStickyFooter,
  sidebar,
  leftSidebar = false,
}: Props) => {
  const { sizes, space } = useTheme();

  return (
    <Flex flexDirection="column" minHeight="100vh">
      <Header courtier={courtier} utilisateur={utilisateur} />
      <Stack
        direction={{ base: 'column', xl: 'row' }}
        flex={1}
        pb={{ base: 11, lg: 8 }}
        pt={{
          base: `calc(${space[3]} + ${sizes.headerMobile})`,
          lg: `calc(${space[6]} + ${sizes.headerDesktop})`,
        }}
        px={{ base: 6, lg: 9, xl: 10 }}
        mt={0}
        spacing={{ base: 8, lg: 9 }}
      >
        {sidebar && leftSidebar && (
          <>
            <Box display={{ base: 'none', xl: 'block' }} as="aside" maxWidth={{ xl: 'sidebar' }}>
              {sidebar}
            </Box>
            <Box
              as="main"
              flex={{ xl: 1 }}
              pb={{
                base: hasStickyFooter ? sizes.footerMobile : 0,
                lg: hasStickyFooter ? sizes.footerDesktop : 0,
              }}
            >
              {children}
            </Box>
            {/** display force a étre a droite si ecran trop petit*/}
            <Box display={{ base: 'block', xl: 'none' }} as="aside" maxWidth={{ xl: 'sidebar' }}>
              {sidebar}
            </Box>
          </>
        )}

        {sidebar && !leftSidebar && (
          <>
            <Box
              as="main"
              flex={{ xl: 1 }}
              pb={{
                base: hasStickyFooter ? sizes.footerMobile : 0,
                lg: hasStickyFooter ? sizes.footerDesktop : 0,
              }}
            >
              {children}
            </Box>
            <Box as="aside" maxWidth={{ xl: 'sidebar' }}>
              {sidebar}
            </Box>
          </>
        )}
      </Stack>
    </Flex>
  );
};

import { createIcon } from '@chakra-ui/react';

export const PhoneIcon = createIcon({
  displayName: 'PhoneIcon',
  viewBox: '0 0 13 23',
  path: (
    <path
      fill="currentColor"
      d="M10.5.5h-8A2.5 2.5 0 000 3v17a2.5 2.5 0 002.5 2.5h8A2.5 2.5 0 0013 20V3A2.5 2.5 0 0010.5.5zm-4 21c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5S8 19.17 8 20s-.67 1.5-1.5 1.5zm4.5-4H2v-14h9v14z"
    />
  ),
});

import { createIcon } from '@chakra-ui/react';

export const InfoIcon = createIcon({
  displayName: 'InfoIcon',
  viewBox: '0 0 10 11',
  path: (
    <path
      fill="currentColor"
      d="M5 .5c-2.75 0-5 2.25-5 5s2.25 5 5 5 5-2.25 5-5-2.25-5-5-5zM5.625 8h-1.25V4.875h1.25V8zM5 4.25c-.375 0-.625-.25-.625-.625S4.625 3 5 3s.625.25.625.625-.25.625-.625.625z"
    />
  ),
});

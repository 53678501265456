export const datePickerProps = {
  dateAdapter: {
    parse(dateString: string) {
      const [dayStr, monthStr, yearStr] = dateString.split('/');
      const validInput =
        dayStr &&
        dayStr.length === 2 &&
        monthStr &&
        monthStr.length === 2 &&
        yearStr &&
        yearStr.length === 4;
      if (!validInput) {
        return null;
      }

      const [day, month, year] = dateString.split('/').map((s) => parseInt(s, 10));
      return new Date(year, month - 1, day);
    },
    format(date: Date) {
      return new Intl.DateTimeFormat('fr-FR').format(date);
    },
  },
};

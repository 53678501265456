import { createIcon } from '@chakra-ui/react';

import { generateDataURIFromSVG } from 'utils/helpers';

const viewBox = '0 0 18 13';
const icon = {
  displayName: 'ArrowLeftIcon',
  viewBox,
  path: (
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M16.5 6.5h-15m0 0l5.25 5.25M1.5 6.5l5.25-5.25"
    />
  ),
};

export const ArrowLeftIcon = createIcon(icon);
export const arrowLeftIconDataURI = generateDataURIFromSVG(viewBox, icon.path, 'none', 'white');

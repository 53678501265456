import { Panel } from 'components/Panel';
import { useContext } from 'react';
import { AuthContext } from 'auth/AuthContextProvider';
import { useQuery } from 'react-query';
import { Text, Center, Spinner } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

import { FilterDefinition, GenerateFilterQuery } from '../StatFilter';

export type Props = {
  label: string;
  color?: string;
  targetQuery: string;
  filterDefinition?: FilterDefinition;
};

export const StatPanelValue = ({
  label,
  color = '#31A0A1',
  targetQuery,
  filterDefinition,
}: Props) => {
  const { token } = useContext(AuthContext);

  const Query = '/API/stats/value/' + targetQuery + GenerateFilterQuery(filterDefinition);
  const { isLoading, error, data } = useQuery(
    'get' + Query,
    () =>
      fetch(Query, {
        method: 'GET',
        headers: new Headers({
          Authorization: token,
        }),
      }).then((res) => res.json()),
    { refetchOnWindowFocus: false },
  );

  let componentsToSHow = <></>;
  if (isLoading) {
    componentsToSHow = (
      <>
        <Center mt={'35px'} mb={'10px'}>
          <Spinner thickness="5px" speed="1.60s" emptyColor="gray.200" color="#31A0A1" size="lg" />
        </Center>
        <Center>
          <Text fontSize={'md'} fontWeight="semibold" mb={6}>
            Chargement des données ...
          </Text>
        </Center>
      </>
    );
  }

  if (error) {
    componentsToSHow = (
      <>
        <Center mt={'35px'} mb={'10px'}>
          <CloseIcon size="lg" color={'red'} />
        </Center>
        <Center color={'red'}>
          <Text fontSize={'md'} fontWeight="semibold" textAlign={'center'} px={'25px'}>
            Erreur lors du chargement des données
          </Text>
        </Center>
      </>
    );
  }

  if (data) {
    componentsToSHow = (
      <>
        <Center mt={'40px'} color={color}>
          <Text fontSize={'50px'} fontWeight="semibold">
            {data.value}
          </Text>
        </Center>
      </>
    );
  }

  return (
    <Panel
      height="200px"
      position="relative"
      width="220px"
      float={'left'}
      margin={{ base: '10px' }}
      py={'10px'}
    >
      <Center as="b" fontSize={'15px'}>
        {label}
      </Center>
      {componentsToSHow}
    </Panel>
  );
};

import { Box, BoxProps } from '@chakra-ui/react';

export type Props = BoxProps;

export const StickyFooter = (props: Props) => {
  return (
    <Box
      as="footer"
      alignItems="center"
      backgroundColor="white"
      borderTopRadius="lg"
      bottom={0}
      boxShadow="sm"
      display="flex"
      justifyContent="flex-end"
      left={0}
      position="fixed"
      px={{ base: 6, lg: 9, xl: 10 }}
      py={6}
      right={0}
      zIndex="sticky"
      {...props}
    />
  );
};

import { CarouselPanelWithData } from 'components/CarouselPanel';
import { SimulatorPanelCustom } from 'components/SimulatorPanelCustom';
import { SimulatorPanelCustomModal } from 'components/SimulatorPanelCustomModal';
import { WidgetPanel } from 'components/WidgetPanel';
import { MyProfileButton } from 'components/MyProfileButton';
import { InstantSearchSelecteurFiltresDesktop } from 'components/InstantSearchSelecteurFiltresDesktop';
import { Utilisateur } from 'types';
import { Link } from 'react-router-dom';

export type Props = {
  withKPI?: boolean;
  withFilter?: boolean;
  utilisateur: Utilisateur;
};

export const HomeSidebar = ({ withKPI, withFilter, utilisateur }: Props) => {
  return (
    <>
      {utilisateur.role !== 'CIMMO' && utilisateur.organisation?.autoriser_statistique == true && (
        <SimulatorPanelCustom
          mb={{ base: 0, xl: 6 }}
          mt={6}
          asLink={true}
          to={'/statistique'}
          image={'/img/calculatrice/stats.png'}
          description={'Statistique'}
          description_light={'Dashboard'}
        />
      )}
      {/**
      {utilisateur?.organisation?.entite_juridique_id != undefined &&
        utilisateur?.organisation?.entite_juridique_id > 0 &&
        utilisateur?.organisation?.gen_mentions_legales && (
          <SimulatorPanelCustomModal
            mb={{ base: 0, xl: 6 }}
            mt={6}
            utilisateur={utilisateur}
            image={'/img/calculatrice/mentions-legales.svg'}
            description={'Mentions légales'}
            description_light={'Calculatrice'}
          />
        )}*/}

      <SimulatorPanelCustom
        mb={{ base: 0, xl: 6 }}
        mt={6}
        asLink={true}
        to={'/calculatrice-capacite'}
        image={'/img/calculatrice/simulateur-capacite.svg'}
        description={"Capacité d'achat"}
        description_light={'Calculatrice'}
      />
      <SimulatorPanelCustom
        mb={{ base: 0, xl: 6 }}
        mt={6}
        asLink={true}
        to={'/calculatrice-mensualite'}
        image={'/img/calculatrice/simulateur-mensualites.svg'}
        description={'Mensualités'}
        description_light={'Calculatrice'}
      />
      <SimulatorPanelCustom
        mb={{ base: 0, xl: 6 }}
        mt={6}
        asLink={true}
        to={'/calculatrice-ptz'}
        image={'/img/calculatrice/simulateur-ptz.svg'}
        description={'Éligibilité PTZ'}
        description_light={'Calculatrice'}
      />
      {/**
      <SimulatorPanelCustom
        mb={{ base: 0, xl: 6 }}
        mt={6}
        url={'https://www.monemprunt.com/old/calcul-capacite-d-achat'}
        image={'/img/calculatrice/simulateur-capacite.svg'}
        description={"Capacité d'achat"}
        description_light={'Calculatrice'}
      />
      <SimulatorPanelCustom
        mb={{ base: 0, xl: 6 }}
        mt={6}
        url={'https://www.monemprunt.com/old/calcul-de-mensualite'}
        image={'/img/calculatrice/simulateur-mensualites.svg'}
        description={'Mensualités'}
        description_light={'Calculatrice'}
      />
      <SimulatorPanelCustom
        mb={{ base: 0, xl: 6 }}
        mt={6}
        url={'https://www.monemprunt.com/old/calcul-pret-a-taux-zero'}
        image={'/img/calculatrice/simulateur-ptz.svg'}
        description={'Éligibilité PTZ'}
        description_light={'Calculatrice'}
      />*/}

      {withFilter && <InstantSearchSelecteurFiltresDesktop />}
    </>
  );
};

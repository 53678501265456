import { createIcon } from '@chakra-ui/react';

export const LinkIcon = createIcon({
  displayName: 'LinkIcon',
  viewBox: '0 0 22 22',
  path: (
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M10 5l2.575-2.575a5.011 5.011 0 017 0 5.01 5.01 0 010 7L17 12m-5 5l-2.575 2.575a5.01 5.01 0 01-7 0 5.011 5.011 0 010-7L5 10m2 5l8-8"
    />
  ),
});

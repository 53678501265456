import { Redirect, useParams } from 'react-router-dom';

import { ClientDetails } from 'components/ClientDetails';
import { ClientDetailsHeader } from 'components/ClientDetailsHeader';
import { Layout } from 'components/Layout';
import { Client, Courtier, Utilisateur } from 'types';
import { clientRandy, courtierDan, dossier1, utilisateurJames } from 'utils/storybook';

import { useContext } from 'react';
import { MeContext } from 'auth/AuthContextProvider';

export type Props = {
  client: Client;
  courtier: Courtier;
  utilisateur: Utilisateur;
};

export const ClientPage = ({ client, courtier, utilisateur }: Props) => {
  // TODO: Fetch the files associated to the client ID here?
  const files = [dossier1];
  return (
    <Layout courtier={courtier} utilisateur={utilisateur} hasStickyFooter>
      <ClientDetailsHeader client={client} mb={6} />
      <ClientDetails client={client} files={files} />
    </Layout>
  );
};

export const ClientPageRoute = () => {
  const { id } = useParams<{ id: string }>();
  // TODO: Query the clients API and find the right one
  console.log('Client id:', id);
  const client = clientRandy;
  const me = useContext(MeContext);
  return client ? (
    <ClientPage client={client} courtier={me.courtier!} utilisateur={me.utilisateur!} />
  ) : (
    <Redirect to="/" />
  );
};

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Center,
  Text,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import { Button, ColorScheme } from 'components/Button';

export type Props = {
  titre: string;
  description: string;
  textButtonValider: string;
  onValidate: () => void;
  colorButtonValider: string;
} & Omit<ModalProps, 'children'>;

export const ConfirmationModal = ({
  titre,
  description,
  textButtonValider,
  onValidate,
  colorButtonValider = 'teal',
  ...props
}: Props) => {
  return (
    <Modal {...props} scrollBehavior="inside" size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center>
            <Text my={2} color="teal.900" fontSize="xl" fontWeight="extrabold">
              {titre}
            </Text>
          </Center>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign="center" pb={10}>
          <Box>{description}</Box>

          <Flex mt={7}>
            <Box>
              <Button float={'left'} onClick={props.onClose}>
                Fermer
              </Button>
            </Box>
            <Spacer />
            <Box>
              <Button colorScheme={colorButtonValider as ColorScheme} onClick={onValidate}>
                {textButtonValider}
              </Button>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

import { createIcon } from '@chakra-ui/react';

export const CalendarIcon = createIcon({
  displayName: 'CalendarIcon',
  viewBox: '0 0 16 17',
  path: (
    <>
      <path
        clipRule="evenodd"
        fill="currentColor"
        fillRule="evenodd"
        d="M.5 6a.5.5 0 01.5-.5h14a.5.5 0 010 1H1A.5.5 0 01.5 6z"
      />
      <path
        clipRule="evenodd"
        fill="currentColor"
        fillRule="evenodd"
        d="M.5 4A1.5 1.5 0 012 2.5h12A1.5 1.5 0 0115.5 4v11a1.5 1.5 0 01-1.5 1.5H2A1.5 1.5 0 01.5 15V4zM2 3.5a.5.5 0 00-.5.5v11a.5.5 0 00.5.5h12a.5.5 0 00.5-.5V4a.5.5 0 00-.5-.5H2z"
      />
      <path
        clipRule="evenodd"
        fill="currentColor"
        fillRule="evenodd"
        d="M4 .5a.5.5 0 01.5.5v2a.5.5 0 01-1 0V1A.5.5 0 014 .5zM12 .5a.5.5 0 01.5.5v2a.5.5 0 01-1 0V1a.5.5 0 01.5-.5zM8 .5a.5.5 0 01.5.5v2a.5.5 0 01-1 0V1A.5.5 0 018 .5z"
      />
    </>
  ),
});

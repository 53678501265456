import { createIcon } from '@chakra-ui/react';

export const VerticalDotsIcon = createIcon({
  displayName: 'VerticalDotsIcon',
  viewBox: '0 0 4 22',
  path: (
    <path
      fill="currentColor"
      d="M4 11a2 2 0 10-4 0 2 2 0 004 0zM4 20a2 2 0 10-4 0 2 2 0 004 0zM4 2a2 2 0 10-4 0 2 2 0 004 0z"
    />
  ),
});

import { Select as ChakraSelect, SelectProps } from '@chakra-ui/react';

import { ChevronDownIcon } from 'components/Icons';

export type Props = {
  children: React.ReactNode;
} & SelectProps;

export const Select = ({ children, ...props }: Props) => {
  return (
    <ChakraSelect icon={<ChevronDownIcon />} {...props}>
      {children}
    </ChakraSelect>
  );
};

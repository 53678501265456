import { createIcon } from '@chakra-ui/react';

export const CaretUpIcon = createIcon({
  displayName: 'CaretUpIcon',
  viewBox: '0 0 14 7',
  path: (
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13 6.49994L7 0.499939L1 6.49994"
    />
  ),
});

import { createIcon } from '@chakra-ui/react';

export const CaretDownIcon = createIcon({
  displayName: 'CaretDownIcon',
  viewBox: '0 0 14 7',
  path: (
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M1 0.5L7 6.5L13 0.5"
    />
  ),
});

import { useState } from 'react';
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
  Stack,
} from '@chakra-ui/react';
import { ProjetAlgolia } from 'types';
import { format } from 'date-fns';
import { DATE_FORMAT } from 'utils/helpers';

export type Props = {
  file: ProjetAlgolia;
};

export const EtapeViewerMini = ({ file, ...props }: Props) => {
  let pourcentage = -1;
  if (file.avancement !== undefined && file.avancement_total !== undefined) {
    pourcentage = (100 / file.avancement_total) * file.avancement;
  }
  return (
    <>
      {pourcentage >= 0 && (
        <>
          <Text mt={3}>
            Progression {file.avancement}/{file.avancement_total}:
          </Text>
          <Progress
            w={'100%'}
            colorScheme="green"
            size="sm"
            borderRadius={'5px'}
            value={pourcentage}
          />
        </>
      )}

      {file.code_derniere_etape !== null && file.code_derniere_etape !== undefined && (
        <Text color={'#14CF97'} fontSize="xs" mt={3}>
          Réalisé : {file.code_derniere_etape}
        </Text>
      )}
      {file.code_prochaine_etape !== null && file.code_derniere_etape !== undefined && (
        <Text color={'#FF9A01'} fontSize="xs" mt={3}>
          A suivre : {file.code_prochaine_etape}
        </Text>
      )}
    </>
  );
};

import { Avatar, Box, BoxProps, Flex, Image, Text } from '@chakra-ui/react';

import { Button } from 'components/Button';
import { MessageIcon } from 'components/Icons';
import { Panel } from 'components/Panel';
import { Courtier } from 'types';

export type Props = {
  courtiers: Courtier[];
} & BoxProps;

export const FileCourtierPanel = ({ courtiers, ...props }: Props) => {
  return (
    <Panel
      as="section"
      px={{ base: 6, xxl: 11 }}
      py={{ base: 6, xxl: 8 }}
      position="relative"
      {...props}
    >
      <Text as="h4" fontSize={{ base: 'xl', xxl: 'xl' }} fontWeight="semibold">
        Courtier en charge du dossier
      </Text>
      {/** Create an array in case one day there is multiple brokers associated to one file */}
      {courtiers.map((courtier) => (
        <Box
          key={courtier.id}
          py={{ base: 6, lg: 10 }}
          fontSize="sm"
          fontWeight="medium"
          _last={{ pb: { base: 0, lg: 10 } }}
          _notLast={{ borderBottomWidth: 1, borderBottomColor: 'gray.100' }}
        >
          {/** Mobile */}
          <Flex
            alignItems="center"
            display={{ base: 'flex', lg: 'none' }}
            justifyContent="space-between"
            mb={5}
          >
            <Flex alignItems="center">
              <Avatar
                name={courtier.nom}
                src={courtier.url_image}
                icon={<Image src="/profil.png" />}
                mr={4}
                size="lg"
              />
              <Box>
                <Text as="h5" fontSize="xl">
                  {courtier.nom} {courtier.prenom}
                </Text>
              </Box>
            </Flex>
            <Button
              as="a"
              colorScheme="orange-light"
              href={`mailto:${courtier.mail}`}
              icon={MessageIcon}
              px={6}
              target="_blank"
            />
          </Flex>
          <Flex
            alignItems="center"
            display={{ base: 'flex', lg: 'none' }}
            justifyContent="space-between"
          >
            {courtier.mail && <Text lineHeight={1}>{courtier.mail}</Text>}
            {courtier.telephone && <Text lineHeight={1}>Tel: {courtier.telephone}</Text>}
            {courtier.telephone_portable && (
              <Text lineHeight={1}>Tel.Portable: {courtier.telephone_portable}</Text>
            )}
          </Flex>
          {/** Desktop */}
          <Flex display={{ base: 'none', lg: 'flex' }} direction="column">
            <Avatar
              name={courtier.nom}
              src={courtier.url_image}
              icon={<Image src="/profil.png" />}
              mb={4}
              size="2xl"
            />
            <Text as="h5" fontSize="2xl" lineHeight={1}>
              {courtier.nom} {courtier.prenom}
            </Text>
            {courtier.mail && (
              <Text lineHeight={1} mt={4}>
                {courtier.mail}
              </Text>
            )}
            {courtier.telephone && (
              <Text lineHeight={1} mt={4}>
                Tel: {courtier.telephone}
              </Text>
            )}
            {courtier.telephone_portable && (
              <Text lineHeight={1} mt={4}>
                Tel.Portable: {courtier.telephone_portable}
              </Text>
            )}

            <Button
              as="a"
              alignSelf="flex-start"
              colorScheme="orange-light"
              href={`mailto:${courtier.mail}`}
              icon={MessageIcon}
              mt={6}
              px={6}
              target="_blank"
            >
              Contacter
            </Button>
          </Flex>
        </Box>
      ))}
      <Image
        alt="Illustration de contact"
        bottom={-1}
        display={{ base: 'none', lg: 'block' }}
        pointerEvents="none"
        position="absolute"
        right={0}
        src="/contact-broker.png"
        width="190px"
      />
    </Panel>
  );
};

import { useContext } from 'react';

import { MeContext } from 'auth/AuthContextProvider';
import { HomeSidebar } from 'components/HomeSidebar';
import { Layout } from 'components/Layout';

import { Courtier, Utilisateur, Marque } from 'types';
import { StatsViewer } from 'components/StatsViewer';

export type Props = {
  courtier: Courtier;
  utilisateur: Utilisateur;
  marque: Marque;
  withKPI?: boolean;
};

export const StatPage = ({ courtier, utilisateur, withKPI, marque }: Props) => {
  return (
    <Layout
      courtier={courtier}
      utilisateur={utilisateur}
      sidebar={<HomeSidebar withKPI={withKPI} withFilter={false} utilisateur={utilisateur} />}
      leftSidebar={true}
    >
      <StatsViewer utilisateur={utilisateur} />
    </Layout>
  );
};

export const StatPageRoute = () => {
  const me = useContext(MeContext);
  return <StatPage courtier={me.courtier!} utilisateur={me.utilisateur!} marque={me.marque!} />;
};

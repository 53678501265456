import { useCallback, useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { CourtierModal } from 'components/CourtierModal';
import { MyProfilModal } from 'components/MyProfilModal';
import algoliasearch from 'algoliasearch/lite';
import { useBreakpointValue } from '@chakra-ui/react';
import { getLogo } from 'utils/marque';

import {
  Box,
  Flex,
  Image,
  Link,
  Stack,
  VisuallyHidden,
  useDisclosure,
  useBoolean,
  Table,
  Tr,
  Th,
  TableCaption,
  Thead,
  Tbody,
  Td,
} from '@chakra-ui/react';

import { Button } from 'components/Button';
import { CrossIcon, MagnifyingGlassIcon } from 'components/Icons';
import { IconButton } from 'components/IconButton';
import { MonCourtierButton } from 'components/MonCourtierButton';
import { MyProfileButton } from 'components/MyProfileButton';
import { SearchInput } from 'components/SearchInput';
import { Courtier, Utilisateur, ProjetAlgolia } from 'types';

export type Props = {
  courtier: Courtier;
  utilisateur: Utilisateur;
};

export const Header = ({ courtier, utilisateur }: Props) => {
  const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_API_KEY!,
    utilisateur.search_key ? utilisateur.search_key : '',
  );
  const indexDossier = searchClient.initIndex(process.env.REACT_APP_ALGOLIA_INDEX!);
  const responsiveResultBox = useBreakpointValue({ base: true, lg: false });

  const [isSearching, setIsSearching] = useBoolean(false);
  const { onClose } = useDisclosure();

  const [search, setSearch] = useState<string>('');
  const input = useRef<HTMLInputElement>(null);
  const [courtierModalOpen, setCourtierModalOpen] = useState<boolean>(false);
  const [myProfilModalOpen, setMyProfilModalOpen] = useState<boolean>(false);
  const [dossiersList, setDossiersList] = useState<ProjetAlgolia[]>([]);
  const [nbDossiers, setNbDossiers] = useState<number>(0);
  const [showPopOver, setShowPopOver] = useState<boolean>(false);

  // Click onto "Mon Courtier".
  const onMonCourtierClick = useCallback(() => {
    setCourtierModalOpen(true);
  }, []);

  // Click onto "Mon profil" .
  const onMyProfilClick = useCallback(() => {
    setMyProfilModalOpen(true);
  }, []);

  // On opening the search "bar", focus on the input.
  useEffect(() => {
    if (isSearching) input.current?.focus();
  }, [isSearching]);

  useEffect(() => {
    if (search !== '') {
      indexDossier
        .search(search, { hitsPerPage: 10, filters: '(is_hidden:false)' })
        .then(({ hits, nbHits }) => {
          setNbDossiers(nbHits);
          setDossiersList(hits);
          //setShowPopOver(true);
        });
    }
  }, [search]);

  const history = useHistory();
  const GoTo = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history],
  );
  return (
    <>
      <Flex
        as="header"
        backgroundColor="white"
        boxShadow="xs"
        justifyContent="space-between"
        px={{ base: 6, lg: 10 }}
        py={{ base: 4, lg: 6 }}
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex="banner"
      >
        {/** Search input on mobile */}
        {isSearching && (
          <Flex alignItems="center" display={{ base: 'flex', lg: 'none' }} w="full">
            <Box flex={1}>
              <SearchInput
                onChange={(e) => setSearch(e.currentTarget.value)}
                value={search}
                onFocus={() => setShowPopOver(true)}
              />
            </Box>
            <Button
              onClick={() => {
                setIsSearching.off();
                setShowPopOver(false);
              }}
              borderRadius="sm"
              display="block"
              ml={5}
              mr={-3}
              px={3}
              py={3}
              variant="ghost"
            >
              <CrossIcon color="gray.300" boxSize={5} />
            </Button>
          </Flex>
        )}
        <Stack
          as="section"
          direction="row"
          display={{ base: isSearching ? 'none' : 'flex', lg: 'flex' }}
          flex={1}
          ml={-4}
          mr={{ base: 4, lg: 8 }}
          spacing={{ base: 4, lg: 8, xl: 12 }}
        >
          <Link as={RouterLink} to="/" alignSelf="center" display="block">
            <Image maxW={{ base: 16, lg: 18 }} maxH={{ base: 10, lg: 10.5 }} src={getLogo()} />
            <VisuallyHidden as="h1">Back-Office Partenaire</VisuallyHidden>
          </Link>
          {/** Button to display the search input on mobile */}
          <IconButton
            aria-label="Nom, prénom, e-mail, téléphone ..."
            _active={{ backgroundColor: 'gray.100' }}
            _hover={{ backgroundColor: 'gray.100' }}
            backgroundColor="gray.50"
            colorScheme="gray"
            display={{ base: 'block', lg: 'none' }}
            height="full"
            icon={<MagnifyingGlassIcon boxSize="16px" />}
            width={10.5}
            onClick={() => {
              onClose();
              setIsSearching.on();
              setShowPopOver(true);
            }}
          />
          {/** Search input on desktop */}
          <Box display={{ base: 'none', lg: 'block' }} flex={1}>
            <SearchInput
              maxW="370px"
              onChange={(e) => setSearch(e.currentTarget.value)}
              value={search}
              onFocus={() => setShowPopOver(true)}
            />
          </Box>
        </Stack>
        <Stack
          alignItems="center"
          as="section"
          direction="row"
          display={{ base: isSearching ? 'none' : 'flex', lg: 'flex' }}
          spacing={{ base: 0, lg: 8, xl: 13 }}
        >
          {courtier && courtier.id && courtier.id !== -1 && (
            <MonCourtierButton
              courtier={courtier}
              display={{ base: 'block', lg: 'block' }}
              onClick={onMonCourtierClick}
            />
          )}

          <MyProfileButton onClick={onMyProfilClick} utilisateur={utilisateur} />
        </Stack>
      </Flex>
      <CourtierModal
        courtier={courtier}
        isOpen={courtierModalOpen}
        onClose={() => setCourtierModalOpen(false)}
      />
      <MyProfilModal
        utilisateur={utilisateur}
        isOpen={myProfilModalOpen}
        onClose={() => setMyProfilModalOpen(false)}
      />

      <Box
        pos="fixed"
        zIndex={19}
        hidden={!showPopOver}
        w={{ base: '100%' }}
        h={{ base: '100%' }}
        mt={{ base: 11, lg: 13 }}
        bgColor={'black'}
        opacity={0.2}
        onClick={() => setShowPopOver(false)}
      ></Box>
      <Box
        pos="fixed"
        zIndex={20}
        hidden={!showPopOver}
        w={{ base: '90%', lg: '80%' }}
        mx={{ base: '5%', lg: '10%' }}
        mt={{ base: 13, lg: 15 }}
        boxShadow="dark-lg"
        px={2}
        py={2}
        bgColor={'white'}
        borderRadius="md"
      >
        <Table variant="simple" fontSize="md">
          <TableCaption>Résultats de recherche ({nbDossiers})</TableCaption>
          <Thead>
            <Tr>
              <Th>Nom</Th>
              <Th>Prenom</Th>
              <Th>Mail</Th>
              <Th>Tél</Th>
              <Th hidden={responsiveResultBox}>Capacité</Th>
              <Th hidden={responsiveResultBox}>Montant Projet</Th>
              <Th hidden={responsiveResultBox}>Numero de dossier</Th>
            </Tr>
          </Thead>

          <Tbody id="boxPopOver">
            {dossiersList.map((dossier, index) => (
              <Tr
                id="boxPopOver"
                boxShadow="xs"
                _hover={{ backgroundColor: 'gray.50' }}
                key={index}
                onClick={(e) => GoTo('/' + dossier.kind + '/' + dossier.id)}
              >
                <Td id="boxPopOver">{dossier.nom} </Td>
                <Td id="boxPopOver">{dossier.prenom} </Td>
                <Td id="boxPopOver">{dossier.mail}</Td>
                <Td id="boxPopOver">{dossier.telephone}</Td>
                <Td id="boxPopOver" hidden={responsiveResultBox}>
                  {dossier.capacite_achat_max ? dossier.capacite_achat_max + ' €' : ''}
                </Td>
                <Td id="boxPopOver" hidden={responsiveResultBox}>
                  {dossier.montant ? dossier.montant + ' €' : ''}
                </Td>
                <Td id="boxPopOver" hidden={responsiveResultBox}>
                  {dossier.numero_dossier ? dossier.numero_dossier : ''}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

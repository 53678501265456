import { Avatar, Box, Image, BoxProps } from '@chakra-ui/react';

import { Button } from 'components/Button';
import { MessageIcon } from 'components/Icons';
import { Courtier } from 'types';

export type Props = {
  courtier: Courtier;
  onClick: () => void;
} & BoxProps;

export const MonCourtierButton = ({
  children = 'Mon courtier',
  courtier,
  onClick,
  ...props
}: Props) => {
  return (
    <Box position="relative" maxW="max-content" {...props}>
      <Button
        display={{ base: 'none', lg: 'block' }}
        colorScheme="orange-light"
        fontSize="xl"
        fontWeight="semibold"
        icon={MessageIcon}
        onClick={onClick}
        pl={6}
        /* 10px + 45px of avatar's width */
        pr={'55px'}
      >
        {children}
      </Button>
      <Button
        display={{ base: 'block', lg: 'none' }}
        colorScheme="orange-light"
        fontSize="xl"
        fontWeight="semibold"
        icon={MessageIcon}
        onClick={onClick}
        pl={0}
      ></Button>
      <Avatar
        name={courtier.nom}
        src={courtier.url_image}
        icon={<Image src="/profil.png" />}
        position="absolute"
        pointerEvents="none"
        right={0}
        top={0}
      />
    </Box>
  );
};

import { useCallback, useState } from 'react';
import { auth } from 'auth/AuthContextProvider';
import firebase from 'firebase';

import { Utilisateur } from 'types';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Center,
  Text,
  VStack,
  Divider,
} from '@chakra-ui/react';
import { Button } from 'components/Button';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { InputField } from 'utils/form';

const validationSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required('Nouveau mot de passe requis')
    .min(6, 'Doit comporter au moins 6 caractères'),
  newPasswordVerif: yup
    .string()
    .required('Vérification nouveau mot de passe requis')
    .min(6, 'Doit comporter au moins 6 caractères'),
});
export type PasswordChange = {
  newPassword: string;
  newPasswordVerif: string;
};

export type Props = {
  utilisateur: Utilisateur;
} & Omit<ModalProps, 'children'>;

export const MyProfilModal = ({ utilisateur, ...props }: Props) => {
  const [error, setErrorMessage] = useState<string | null>(null);
  const [success, setSuccessMessage] = useState<string | null>(null);

  const onDisconnect = useCallback(() => {
    localStorage.clear();
    auth.signOut();
  }, []);

  const formikPasswordChange = useFormik({
    validationSchema,
    initialValues: {
      newPassword: '',
      newPasswordVerif: '',
    },
    onSubmit: (values: PasswordChange, actions) => {
      setErrorMessage(null);
      setSuccessMessage(null);

      const user = firebase.auth().currentUser;
      if (values.newPassword !== values.newPasswordVerif) {
        actions.setSubmitting(false);
        setErrorMessage('Les deux mots de passe sont différents');
      } else if (user) {
        user
          .updatePassword(values.newPassword)
          .then(() => {
            console.log('sucessfully change');
            actions.setSubmitting(false);
            actions.resetForm();
            setSuccessMessage('Nouveau mot de passe correctement enregistré');
          })
          .catch((error) => {
            console.log('error on change password ' + error.message);
            actions.setSubmitting(false);
            actions.resetForm();
            if (error.code === 'auth/requires-recent-login') {
              setErrorMessage(
                'Échec : cette opération est sensible, vous devez vous déconnecter et vous reconnecter afin de réaliser cette opération avec une authentification récente.',
              );
            } else {
              setErrorMessage('Erreur rencontrée: ' + error.message);
            }
          });
      } else {
        actions.setSubmitting(false);
        actions.resetForm();
        setErrorMessage(
          'Une erreur a été rencontré, veuillez vous déconnecter et vous reconnecter pour réessayer.',
        );
      }
    },
  });
  return (
    <FormikProvider value={formikPasswordChange}>
      <Modal {...props} scrollBehavior="inside" size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader />
          <ModalCloseButton disabled={formikPasswordChange.isSubmitting} />

          <ModalBody>
            <Center>
              <Text color="teal.900" fontSize="xl" fontWeight="extrabold">
                Compte de {utilisateur.prenom} {utilisateur.nom}
              </Text>
            </Center>

            <Divider orientation="horizontal" />
            <VStack py={8} spacing={6}>
              <InputField
                name="newPassword"
                label="Nouveau mot de passe"
                placeholder="*************"
                type="password"
                isRequired
              />
              <InputField
                name="newPasswordVerif"
                label="Vérification mot de passe"
                placeholder="*************"
                type="password"
                isRequired
              />
            </VStack>
            {error && (
              <Center>
                <Text mt={5} color="red.500" fontSize="sm" fontWeight="semibold">
                  {error}
                </Text>
              </Center>
            )}
            {success && (
              <Center>
                <Text mt={5} color="green.500" fontSize="sm" fontWeight="semibold">
                  {success}
                </Text>
              </Center>
            )}
            <Center>
              <Button
                mt={5}
                isDisabled={!formikPasswordChange.isValid || !formikPasswordChange.dirty}
                isLoading={formikPasswordChange.isSubmitting}
                onClick={formikPasswordChange.submitForm}
              >
                Changer le mot de passe
              </Button>
            </Center>

            <Divider orientation="horizontal" mt={5} />

            <Center>
              <Button mt={5} mb={5} onClick={onDisconnect}>
                Se déconnecter
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </FormikProvider>
  );
};

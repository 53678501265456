import { useToast, useDisclosure } from '@chakra-ui/react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { AuthContext } from 'auth/AuthContextProvider';

import { ConfirmationModal } from 'components/ConfirmationModal';
import { ConfirmationModalWithInput } from 'components/ConfirmationModalWithInput';
import { TagAction } from 'components/TagAction';
import { Projet, ProjetAlgolia } from 'types';
export type Props = {
  file?: Projet;
  fileAlgolia?: ProjetAlgolia;
};

export const TagActionList = ({ file, fileAlgolia }: Props) => {
  const history = useHistory();
  const toast = useToast();

  const { token } = useContext(AuthContext);
  const stateTransfertAction = useDisclosure();
  const stateSupprimerAction = useDisclosure();
  const stateRelanceAction = useDisclosure();

  let idDossier = -1;
  let action_transfert_dossier = false;
  let action_supprimer_dossier = false;
  let action_relancer_contact = false;
  let texte_avertissement = '';
  let type = '';
  if (file) {
    idDossier = file.id as number;
    if (file.numero_dossier == undefined || file.numero_dossier == null) {
      action_relancer_contact = file.est_atraiter !== undefined ? !file.est_atraiter : false;
    } else {
      action_transfert_dossier = file.est_atraiter !== undefined ? !file.est_atraiter : false;
      action_supprimer_dossier = file.est_atraiter !== undefined ? !file.est_atraiter : false;
      texte_avertissement +=
        file.apport !== undefined &&
        file.capacite_emprunt_max !== undefined &&
        file.montant !== undefined
          ? file.apport + file.capacite_emprunt_max < file.montant
            ? 'Attention le projet présente une anomalie,\\nil manque un financement de ' +
              `${Math.round(file.montant - file.apport - file.capacite_emprunt_max).toLocaleString(
                'fr-FR',
              )} €` +
              ' pour ce projet'
            : ''
          : '';
    }
    type = file.type as string;
  } else if (fileAlgolia) {
    idDossier = fileAlgolia.id as number;
    if (fileAlgolia.kind === 'dossiers') {
      action_transfert_dossier =
        fileAlgolia.a_traiter !== undefined ? !fileAlgolia.a_traiter : false;
      action_supprimer_dossier =
        fileAlgolia.a_traiter !== undefined ? !fileAlgolia.a_traiter : false;
      texte_avertissement +=
        fileAlgolia.fin_apport !== undefined &&
        fileAlgolia.capacite_achat_max !== undefined &&
        fileAlgolia.montant !== undefined
          ? fileAlgolia.fin_apport / 100 + fileAlgolia.capacite_achat_max < fileAlgolia.montant
            ? 'Attention le projet présente une anomalie,\\nil manque un financement de ' +
              `${Math.round(
                fileAlgolia.montant - fileAlgolia.fin_apport / 100 - fileAlgolia.capacite_achat_max,
              ).toLocaleString('fr-FR')} €` +
              ' pour ce projet'
            : ''
          : '';
      type = fileAlgolia.type as string;
    } else {
      action_relancer_contact =
        fileAlgolia.a_traiter !== undefined ? !fileAlgolia.a_traiter : false;
    }
  } else {
    return <></>;
  }

  const transfert_dossier: (value: number) => void = (value) => {
    //TODO codé pop up + validation pour appel action
    console.log('Demande transfert du dossier ' + idDossier + '   ' + value);
    fetch('/API/dossiers/process/' + idDossier, {
      method: 'POST',
      body: JSON.stringify({ montant_projet: value }),
      headers: new Headers({
        Authorization: token,
        'Content-Type': 'application/json',
      }),
    }).then((res) => res.json());

    stateTransfertAction.onClose();
  };

  const relancer_client: () => void = () => {
    let telephone;
    let apporteur;
    let id_contact;
    let apporteur_id;
    let contact;
    if (file) {
      telephone = file.telephone;
      apporteur = file.apporteur?.nom + ' ' + file.apporteur?.prenom;
      id_contact = file.id;
      apporteur_id = file.apporteur?.id;
      contact = file.nom + ' ' + file.prenom;
    } else {
      telephone = fileAlgolia?.telephone;
      apporteur = fileAlgolia?.nom_apporteur + ' ' + fileAlgolia?.prenom_apporteur;
      id_contact = fileAlgolia?.id;
      apporteur_id = fileAlgolia?.id_apporteur;
      contact = fileAlgolia?.nom + ' ' + fileAlgolia?.prenom;
    }

    const creationSMS = {
      destinataire: telephone,
      message:
        'Dans le cadre de votre étude de faisabilité financière, autorisez-vous votre Conseiller Commercial ' +
        apporteur +
        ' à transférer vos coordonnées à monemprunt.com afin d’être recontacté.e ? Si oui, cliquez ici : ' +
        process.env.REACT_APP_PUBLIC_VALIDATION_CONTACT_URL +
        'id=' +
        id_contact +
        '&id_apporteur=' +
        apporteur_id,
      nom: contact,
    };

    fetch('/API/relance-sms', {
      method: 'POST',
      body: JSON.stringify(creationSMS),
      headers: new Headers({
        Authorization: token,
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.code === 'KO') {
          toast({
            title: "Erreur lors de l'envoi",
            description: res.detail,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: 'Message envoyé.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    stateRelanceAction.onClose();
  };

  const supprimer_dossier: () => void = () => {
    //TODO codé pop up + validation pour appel action
    console.log('Demande Suppression du dossier ' + idDossier);
    fetch('/API/dossiers/hide/' + idDossier, {
      method: 'POST',
      headers: new Headers({
        Authorization: token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          console.log(res.statusText);
        }
      })
      .then((json) => {
        if (json.success) {
          history.push('/');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    stateSupprimerAction.onClose();
  };

  return (
    <>
      {action_transfert_dossier && (
        <>
          <TagAction action={'TRANSFERT_DOSSIER'} onClick={stateTransfertAction.onOpen} />
          <ConfirmationModalWithInput
            titre="Transfert du dossier au courtier"
            description="Montant total du projet \n (doit être supérieur à 50 000€)"
            avertissement={texte_avertissement}
            isOpen={stateTransfertAction.isOpen}
            onClose={stateTransfertAction.onClose}
            textButtonValider={'Transférer'}
            minimumValue={50000}
            colorButtonValider={'teal'}
            onValidate={transfert_dossier}
            idDossier={idDossier}
            type={type}
          />
        </>
      )}
      {action_supprimer_dossier && (
        <>
          <TagAction action={'SUPPRIMER_DOSSIER'} onClick={stateSupprimerAction.onOpen} />{' '}
          <ConfirmationModal
            titre="Suppression du dossier"
            description="Cette action va supprimer le dossier, vous ne pourrez plus ni y accèder ni consulter les informations."
            isOpen={stateSupprimerAction.isOpen}
            onClose={stateSupprimerAction.onClose}
            textButtonValider={'Supprimer'}
            colorButtonValider={'red-light'}
            onValidate={supprimer_dossier}
          />
        </>
      )}
      {action_relancer_contact && (
        <>
          <TagAction action={'RELANCE_CLIENT'} onClick={stateRelanceAction.onOpen} />{' '}
          <ConfirmationModal
            titre="Relance d'un prospect'"
            description="Cette action va renvoyer un sms de confirmation d'un fiche contact au prospect."
            isOpen={stateRelanceAction.isOpen}
            onClose={stateRelanceAction.onClose}
            textButtonValider={'Envoyer SMS'}
            colorButtonValider={'orange-light'}
            onValidate={relancer_client}
          />
        </>
      )}
    </>
  );
};

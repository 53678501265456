import { connectStats } from 'react-instantsearch-dom';
import { FilesListHeader } from 'components/FilesListHeader';

export type Props = {
  action: () => void;
};
export const InstantSearchAfficheurTitreNombreRes = ({ action }: Props) => {
  // Gestion du titre avec nombre de resulats affichable
  const afficheurTitreEtNbResultat = ({ nbHits }: any) => {
    return (
      <FilesListHeader
        filesTotal={nbHits}
        mb={{ base: 5, lg: 5 }}
        onOpenMoreActionsModal={action}
      />
    );
  };
  const AfficheurTitreEtNbResultatComponent = connectStats(afficheurTitreEtNbResultat);

  return (
    <>
      <AfficheurTitreEtNbResultatComponent />
    </>
  );
};

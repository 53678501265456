import { useContext, ReactChild, ReactChildren } from 'react';
import { AuthContext, MeContext } from 'auth/AuthContextProvider';
import { Route } from 'react-router-dom';
import { LoginPageRoute } from 'pages/Login';
import { OverlayLoading } from 'components/OverlayLoading';

type AppProps = {
  path: string;
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
};

export default function ProtectedRoute(props: AppProps) {
  const { token } = useContext(AuthContext);
  const me = useContext(MeContext);
  if (token && me.utilisateur) {
    return (
      <Route exact path={props.path}>
        {props.children}
      </Route>
    );
  } else if (token) {
    return <OverlayLoading />;
  } else {
    return <LoginPageRoute />;
  }
}

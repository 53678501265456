import { useContext, useState } from 'react';
import { AuthContext } from 'auth/AuthContextProvider';
import { useQuery } from 'react-query';
import { Box, Text, Center, Spinner } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { Utilisateur } from 'types';

import { SelectField } from 'utils/form';

import { FormikProvider, useFormik } from 'formik';

export type Props = {
  onChangeEnfant: (e: string) => void;
  onChangeParent: (e: string) => void;
  utilisateur: Utilisateur;
};

const INITIAL_VALUES = {
  direction: '-1',
  agence: '-1',
};

export type Values = {
  direction: string;
  agence: string;
};

export const PerimetreFiltter = ({ onChangeEnfant, onChangeParent, utilisateur }: Props) => {
  const [entiteParentIdCible, setEntiteParentIdCible] = useState<string>('-1');
  const [entiteEnfantIdCible, setEntiteEnfantIdCible] = useState<string>('-1');

  const handleChangeParent = (value: string) => {
    setEntiteParentIdCible(value);
    setEntiteEnfantIdCible('-1');
    onChangeParent(value);
    onChangeEnfant('-1');
  };
  const handleChangeEnfant = (value: string) => {
    setEntiteEnfantIdCible(value);
    onChangeEnfant(value);
  };

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (values: Values, actions) => {
      console.log(values);
    },
  });

  const { token } = useContext(AuthContext);
  const { isLoading, error, data } = useQuery(
    'hierarchie',

    () =>
      fetch('/API/stats/hierarchie', {
        method: 'GET',
        headers: new Headers({
          Authorization: token,
        }),
      }).then((res) => res.json()),
    { refetchOnWindowFocus: false },
  );

  let componentsToSHow = <></>;
  if (isLoading) {
    componentsToSHow = (
      <>
        <Center mt={'15px'} mb={'5px'}>
          <Spinner thickness="3px" speed="1.60s" emptyColor="gray.200" color="#31A0A1" size="md" />
        </Center>
        <Center>
          <Text fontSize={'md'} fontWeight="semibold" mb={'15px'}>
            Chargement ...
          </Text>
        </Center>
      </>
    );
  }

  if (error) {
    componentsToSHow = (
      <>
        <Center mt={'15px'} mb={'5px'}>
          <CloseIcon size="lg" color={'red'} />
        </Center>
        <Center>
          <Text fontSize={'md'} fontWeight="semibold" mb={'15px'} color={'red'}>
            Erreur chargement périmètre
          </Text>
        </Center>
      </>
    );
  }

  const directionList: { label: string; value: string }[] = [{ label: 'Toutes', value: '-1' }];
  const agenceList: { label: string; value: string }[] = [{ label: 'Toutes', value: '-1' }];

  if (data && data.list_entite) {
    if (data.list_entite.length > 0) {
      data.list_entite.forEach((item: any) => {
        if (item.parente == null) {
          directionList.push({ label: item.libelle, value: item.id });
        } else if (entiteParentIdCible !== '-1' || utilisateur.role === 'DIMMO') {
          if (item.parente == entiteParentIdCible || utilisateur.role === 'DIMMO')
            agenceList.push({ label: item.libelle, value: item.id });
        }
      });
      componentsToSHow = (
        <FormikProvider value={formik}>
          {utilisateur.role === 'SIMMO' && (
            <SelectField
              name="direction"
              label="Direction"
              customSelector={true}
              customValue={entiteParentIdCible}
              customOnChange={(e) => handleChangeParent(e!.target.value)}
              options={directionList}
            />
          )}

          {(entiteParentIdCible !== '-1' || utilisateur.role === 'DIMMO') && (
            <SelectField
              name="agence"
              label="Agence"
              customSelector={true}
              customValue={entiteEnfantIdCible}
              customOnChange={(e) => handleChangeEnfant(e!.target.value)}
              options={agenceList}
            />
          )}
        </FormikProvider>
      );
    } else {
      componentsToSHow = (
        <Center>
          <Text fontSize={'md'} fontWeight="semibold" mt={'10px'} mb={'10px'}>
            Aucun périmètre affichable
          </Text>
        </Center>
      );
    }
  } else {
    componentsToSHow = (
      <Center>
        <Text fontSize={'md'} fontWeight="semibold" mt={'10px'} mb={'10px'}>
          Aucun périmètre affichable
        </Text>
      </Center>
    );
  }
  return (
    <>
      <Box
        p={5}
        w={{ base: '100%', md: '30%' }}
        shadow="md"
        borderWidth="1px"
        borderRadius={'20px'}
        color={'#31A0A1'}
      >
        <Text color={'teal'}>Choix du périmètre</Text>
        {componentsToSHow}
      </Box>
    </>
  );
};

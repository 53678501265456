import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { BoxProps, Flex, Stack, Text } from '@chakra-ui/react';

import { Button } from 'components/Button';
import { ArrowLeftIcon, UserIcon } from 'components/Icons';
import { Client } from 'types';

export type Props = {
  client: Client;
} & BoxProps;

export const ClientDetailsHeader = ({ client, ...props }: Props) => {
  const history = useHistory();

  const onSubstiteClick = useCallback(() => {
    console.log('Clicked on substitute! Que faire ici?');
  }, []);

  return (
    <Flex
      as="header"
      alignItems={{ lg: 'center' }}
      direction={{ base: 'column', lg: 'row' }}
      justifyContent="space-between"
      {...props}
    >
      <Stack direction="row" mb={{ base: 6, lg: 0 }} spacing={{ base: 4, lg: 6 }}>
        <Button onClick={history.goBack} role="group" variant="unstyled">
          <ArrowLeftIcon boxSize="18px" color="gray.200" _groupHover={{ color: 'gray.500' }} />
        </Button>
        <Text as="h2" fontSize={{ base: 'xl', lg: '5xl' }} fontWeight="medium">
          {client.nom?.toUpperCase()} {client.prenom}
        </Text>
      </Stack>
      <Button leftIcon={<UserIcon />} colorScheme="gray" onClick={onSubstiteClick}>
        Substituer
      </Button>
    </Flex>
  );
};

import {
  Checkbox as ChakraCheckbox,
  CheckboxGroup as ChakraCheckboxGroup,
  CheckboxProps,
  CheckboxGroupProps,
  Icon,
} from '@chakra-ui/react';

import { CheckmarkIcon } from 'components/Icons';

// Custom Checkbox Icon (https://chakra-ui.com/docs/form/checkbox#icon)
const CheckboxIcon = (props: { isIndeterminate?: boolean; isChecked?: boolean }) => {
  if (!props.isChecked) return null;
  return <Icon as={CheckmarkIcon} boxSize={4} />;
};

/**
 * Utilisé principalement comme exemple / Storybook avec une seule option.
 * En pratique, utiliser `CheckboxGroup` ci-dessous pour gérer l'état de plusieurs options.
 */
export type Props = CheckboxProps;
export const Checkbox = (props: Props) => {
  return <ChakraCheckbox colorScheme="teal" icon={<CheckboxIcon />} {...props} />;
};

export type GroupProps = CheckboxGroupProps;
export const CheckboxGroup = (props: GroupProps) => {
  return <ChakraCheckboxGroup colorScheme="teal" {...props} />;
};

import { forwardRef, InputProps } from '@chakra-ui/react';

import { MagnifyingGlassIcon } from 'components/Icons';
import { Input } from 'components/Input';

export type Props = InputProps;

export const SearchInput = forwardRef<Props, 'input'>((props, ref) => {
  return (
    <Input
      _hover={{ boxShadow: 'outline' }}
      _focus={{ boxShadow: 'outline' }}
      _placeholder={{ color: 'gray.200' }}
      backgroundColor="gray.50"
      borderRadius="md"
      borderWidth={0}
      fontSize="3xl"
      fontWeight="medium"
      icon={MagnifyingGlassIcon}
      iconColor="teal.900"
      iconPosition="left"
      placeholder="Nom, prénom, e-mail, téléphone ..."
      ref={ref}
      type="text"
      {...props}
    />
  );
});

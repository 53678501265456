import { createIcon } from '@chakra-ui/react';

export const UsersIcon = createIcon({
  displayName: 'UsersIcon',
  viewBox: '0 0 20 18',
  path: (
    <>
      <path
        clipRule="evenodd"
        fill="currentColor"
        fillRule="evenodd"
        d="M11.167 1.08a4.333 4.333 0 016.5 3.753v1.669A4.318 4.318 0 0116 9.902v.086l3.014 1.207a1.836 1.836 0 011.153 1.702V16.5a1 1 0 01-1 1h-3.334a1 1 0 010-2h2.334v-2.49l-3.014-1.207A1.834 1.834 0 0114 10.105v-.734a1 1 0 01.502-.867 2.318 2.318 0 001.165-2.005V4.833a2.335 2.335 0 00-3.5-2.021 1 1 0 01-1-1.732z"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M11.976 12.957l-3.119-1.25a.833.833 0 01-.524-.771v-.376C11.058 10.196 11.667 9 11.667 9S10 7.333 10 5.667a3.333 3.333 0 00-6.667 0C3.333 7.333 1.667 9 1.667 9S2.275 10.196 5 10.56v.376a.833.833 0 01-.524.773l-3.119 1.25a.834.834 0 00-.524.772V16.5H12.5v-2.77a.834.834 0 00-.524-.773z"
      />
    </>
  ),
});

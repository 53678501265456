import { Avatar, BoxProps, Flex, Stack, Text, Image } from '@chakra-ui/react';
import { Button } from 'components/Button';
import { Utilisateur } from 'types';

export type Props = {
  onClick?: () => void;
  utilisateur: Utilisateur;
} & BoxProps;

export const MyProfileButton = ({ onClick, utilisateur, ...props }: Props) => {
  return (
    <Stack direction="row" spacing={{ base: 4, lg: 4 }} {...props}>
      <Flex
        direction="column"
        display={{ base: 'none', lg: 'flex' }}
        justifyContent="center"
        textAlign="right"
      >
        <Text fontSize="lg" fontWeight="semibold" lineHeight="shorter">
          {utilisateur.nom + ' ' + utilisateur.prenom}
        </Text>
        {utilisateur?.organisation?.nom_immo && (
          <Text color="gray.300" fontSize="sm" textTransform="uppercase">
            {utilisateur.organisation.nom_immo}
          </Text>
        )}
      </Flex>
      <Button
        _active={{ backgroundColor: 'gray.50' }}
        _hover={{ backgroundColor: 'gray.50' }}
        backgroundColor="transparent"
        borderColor="gray.200"
        borderRadius="xl"
        borderWidth={1}
        colorScheme="gray"
        height={10.5}
        onClick={onClick}
        px={{ base: 5, lg: 6 }}
        variant="ghost"
      >
        <Flex as="span" alignItems="center">
          <Avatar
            src={utilisateur.url_image}
            icon={<Image src="/profil.png" />}
            width={8}
            height={8}
          />
        </Flex>
      </Button>
    </Stack>
  );
};

import { BoxProps, Stack, Text } from '@chakra-ui/react';

import { StarIcon } from 'components/Icons';
import { useState } from 'react';

export type Props = {
  children: number;
} & BoxProps;

export const Rating = ({ children, ...props }: Props) => {
  if (isNaN(children)) {
    return null;
  }

  function getColorFromScore(score: number) {
    if (score <= 2) {
      return 'red.500';
    } else if (score == 3) {
      return 'orange.500';
    } else {
      return 'green.500';
    }
  }

  let i = 0;
  const stars = [];
  for (i; i < children; i++) {
    stars.push(
      <StarIcon
        key={i}
        color={getColorFromScore(Math.max(0, Math.min(children, 5)))}
        boxSize={{ base: '12px', lg: '16px' }}
      />,
    );
  }
  for (i; i < 5; i++) {
    stars.push(<StarIcon key={i} color="white" boxSize={{ base: '12px', lg: '16px' }} />);
  }

  return (
    <Stack alignItems="center" direction="row" spacing={3} {...props}>
      {stars}
    </Stack>
  );
};

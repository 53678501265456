import { connectPagination } from 'react-instantsearch-dom';
import { Pagination as PaginationCustom } from 'components/Pagination';

export const InstantSearchAfficheurPagination = () => {
  // Gestion de la pagination
  const afficheurPagination = ({ currentRefinement, nbPages, refine }: any) => (
    <>
      <PaginationCustom
        currentPage={currentRefinement}
        totalPages={nbPages}
        onGotoPage={(e) => {
          refine(e);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
      />
    </>
  );
  const AfficheurPaginationComponent = connectPagination(afficheurPagination);

  return (
    <>
      <AfficheurPaginationComponent />
    </>
  );
};

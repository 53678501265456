import { createIcon } from '@chakra-ui/react';

export const PlusCircleIcon = createIcon({
  displayName: 'PlusCircleIcon',
  viewBox: '0 0 17 17',
  path: (
    <path
      fill="currentColor"
      d="M8.5 0A8.503 8.503 0 000 8.5C0 13.192 3.808 17 8.5 17S17 13.192 17 8.5 13.192 0 8.5 0zm4.25 9.35h-3.4v3.4h-1.7v-3.4h-3.4v-1.7h3.4v-3.4h1.7v3.4h3.4v1.7z"
    />
  ),
});

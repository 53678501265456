import { createIcon } from '@chakra-ui/react';

export const MagnifyingGlassIcon = createIcon({
  displayName: 'MagnifyingGlassIcon',
  viewBox: '0 0 19 19',
  path: (
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M17.667 17.667l-3.334-3.334m0-6.666A6.667 6.667 0 111 7.667a6.667 6.667 0 0113.333 0z"
    />
  ),
});

import { Text, Center, Spinner, Box } from '@chakra-ui/react';
import { WarningTwoIcon, CloseIcon } from '@chakra-ui/icons';
import React from 'react';

export const StatLoading = (
  <Box mt={'80px'} w={'100%'}>
    <Center w={'100%'} mb={'15px'}>
      <Spinner thickness="5px" speed="1.60s" emptyColor="gray.200" color="#31A0A1" size="xl" />
    </Center>
    <Center>
      <Text as="h3" fontSize={'xl'} fontWeight="semibold">
        Chargement des données ...
      </Text>
    </Center>
  </Box>
);
export const StatError = (
  <Box mt={'80px'} w={'100%'}>
    <Center w={'100%'}>
      <CloseIcon color="red" boxSize={9} mb={'15px'} />
    </Center>
    <Center>
      <Text as="h3" fontSize={'xl'} fontWeight="semibold" color="red">
        Erreur lors du chargement des données
      </Text>
    </Center>
  </Box>
);
export const StatNoData = (
  <Box mt={'80px'} w={'100%'}>
    <Center w={'100%'}>
      <WarningTwoIcon color="#FCB630" boxSize={9} mb={'15px'} />
    </Center>
    <Center>
      <Text as="h3" fontSize={'xl'} fontWeight="semibold">
        Aucune donnée à afficher
      </Text>
    </Center>
  </Box>
);

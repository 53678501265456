import { createIcon } from '@chakra-ui/react';

export const EnvelopeIcon = createIcon({
  displayName: 'EnvelopeIcon',
  viewBox: '0 0 24 20',
  path: (
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M1 11v6a2 2 0 002 2h18a2 2 0 002-2v-6m0-5V3a2 2 0 00-2-2H3a2 2 0 00-2 2v3l11 6 11-6z"
    />
  ),
});

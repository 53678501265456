import { Box, Center, Image, Flex, Spacer } from '@chakra-ui/react';
import { Button } from 'components/Button';
import { Link as RouterLink } from 'react-router-dom';

export type Props = {
  children: React.ReactNode;
};

export const CustomLayoutBouygue = ({ children }: Props) => {
  return (
    <Box p={'25px'}>
      <Flex>
        <Image
          src="https://storage.googleapis.com/mec-public/logo-partenaire/bouygues.png"
          w={'150px'}
        />
        <Spacer />
        <Box>
          <Button as={RouterLink} to={`/bouygues-custom/calculatrice-ptz`}>
            PTZ
          </Button>
        </Box>
        <Spacer />
        <Box>
          <Button as={RouterLink} to={`/bouygues-custom/calculatrice-mensualite`}>
            Mensualité
          </Button>
        </Box>
        <Spacer />
        <Box>
          <Button as={RouterLink} to={`/bouygues-custom/calculatrice-capacite`}>
            Capacité
          </Button>
        </Box>
        <Spacer />
      </Flex>

      <Center>
        <Box maxW={'1100px'}>{children}</Box>
      </Center>
    </Box>
  );
};
